@import '../Common';

.MeetingEditor {
}
.MeetingEditor .ThreadEditorRowTitle {
  width: 40px;
}
.MeetingEditor .ThreadEditorCard {
  border-radius: 0px;
}
.MeetingEditor .ThreadEditorActionBtn.ThreadEditorActionSend {
  border-radius: 20px;
}

.MeetingEditorField {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  margin-bottom: 8px;
  &.MeetingEditorFieldSplit {
    max-width: 185px;
    display: inline-block;
    vertical-align: top;

    &.MeetingEditorFieldSplitFirst {
      margin-right: 2%;
    }
  }
}
.MeetingEditorTimePicker {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
}
.MeetingEditorFieldSchedule {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include ApercuRegular;
  font-size: 15px;
  line-height: 20px;
  padding: 7px 10px;
  color: black;
  background: transparent;
  border-radius: 3px;
  outline: none;
  border: 1px solid #eaeaea;
}
.MeetingEditorTimePickerExplain {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  @include RoobertMedium;
  font-size: 12px;
  line-height: 25px;
  color: $green;
  text-align: center;
}

.MeetingEditorFieldTitle {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  @include RoobertSemiBold;
  font-size: 13px;
  line-height: 25px;
  color: black;
}
.MeetingEditorFieldInput {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include ApercuRegular;
  font-size: 15px;
  line-height: 20px;
  padding: 7px 10px;
  color: black;
  background: transparent;
  border-radius: 3px;
  outline: none;
  border: 1px solid #eaeaea;
}
.MeetingEditorFieldTextarea {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  outline: none;
  border: 1px solid #eaeaea;
  @include ApercuRegular;
  font-size: 15px;
  border-radius: 3px;
  line-height: 20px;
  padding: 7px 10px;
  color: black;
  text-align: left;
  background: transparent;
  max-height: 20vh;
}

@media only screen and (min-width: 375px) {
}
@media only screen and (min-width: 500px) {
}
@media only screen and (min-width: 640px) {
}
@media only screen and (min-width: 850px) {
  .MeetingEditorField {
  }
}
@media only screen and (min-width: 1000px) {
  .MeetingEditor .ThreadEditorRow {
    padding-left: 50px;
  }
  .MeetingEditor .ThreadEditorCardWrapper {
    padding-left: 50px;
  }
}
@media only screen and (min-width: 1150px) {
}
