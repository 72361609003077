@import '../Common';

.MultiSelect {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  color: black;
  text-align: left;
}
.MultiSelectTags {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  margin-top: 5px;
}
.MultiSelectTag {
  display: inline-block;
  overflow: hidden;
  position: relative;
  vertical-align: top;
  font-size: 16px;
  @include ApercuRegular;
  line-height: 20px;
  border-radius: 20px;
  background: transparent;
  border: 2px solid black;
  padding: 5px 30px 5px 10px;
  margin-right: 5px;
  margin-bottom: 5px;
  background-color: transparent;
  background-image: url('../../images/leads-skip-icon.png');
  background-repeat: no-repeat;
  background-position: center right 10px;
  background-size: 10px 10px;
  cursor: pointer;

  &:hover {
    background-color: #eee;
  }
}
.MultiSelectContainer {
  display: block;
  overflow: hidden;
  position: relative;
  border: 2px solid black;
  border-radius: 5px;
  width: 100%;
  background: white;
  margin-bottom: 5px;

  &.MultiSelectContainerDisabled {
    opacity: 0.5;
    pointer-events: none;
    display: none;
  }
}
.MultiSelectInputBox {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  padding-right: 40px;
  background-color: transparent;
  background-image: url('../../images/search-icon-black.png');
  background-repeat: no-repeat;
  background-position: center right 10px;
  background-size: 20px 20px;
}
.MultiSelectInput {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  @include ApercuRegular;
  font-size: 15px;
  padding: 0 10px;
  color: black;
  background: transparent;
  outline: none;
  border: 0;
  height: 40px;
}
.MultiSelectResults {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  border-top: 1px solid #d8d8d8;
}
.MultiSelectResultsList {
  display: block;
  overflow-x: hidden;
  position: relative;
  width: 100%;
  max-height: 225px;
}
.MultiSelectResult {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  background-color: transparent;
  font-size: 16px;
  @include ApercuRegular;
  line-height: 20px;
  padding: 5px 10px;
  cursor: pointer;

  &:hover {
    background-color: #eee;
  }
}

@media only screen and (min-width: 375px) {
}
@media only screen and (min-width: 500px) {
}
@media only screen and (min-width: 640px) {
}
@media only screen and (min-width: 750px) {
}
@media only screen and (min-width: 1000px) {
}
@media only screen and (min-width: 1150px) {
}
