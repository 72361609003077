@import './Common';

@font-face {
  font-family: 'fk_displayregular';
  src:
    url('../fonts/FKDisplay-Regular.woff2') format('woff2'),
    url('../fonts/FKDisplay-Regular.woff') format('woff'),
    url('../fonts/FKDisplay-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'roobert-bold';
  src: url('../fonts/Roobert-Bold.eot');
  src:
    url('../fonts/Roobert-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Roobert-Bold.woff2') format('woff2'),
    url('../fonts/Roobert-Bold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'roobert-medium';
  src: url('../fonts/Roobert-Medium.eot');
  src:
    url('../fonts/Roobert-Medium.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Roobert-Medium.woff2') format('woff2'),
    url('../fonts/Roobert-Medium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'roobert-regular';
  src: url('../fonts/Roobert-Regular.eot');
  src:
    url('../fonts/Roobert-Regular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Roobert-Regular.woff2') format('woff2'),
    url('../fonts/Roobert-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'roobert-semibold';
  src: url('../fonts/Roobert-SemiBold.eot');
  src:
    url('../fonts/Roobert-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Roobert-SemiBold.woff2') format('woff2'),
    url('../fonts/Roobert-SemiBold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'apercu-regular-pro';
  src: url('../fonts/apercu-regular-pro.eot');
  src:
    url('../fonts/apercu-regular-pro.eot?#iefix') format('embedded-opentype'),
    url('../fonts/apercu-regular-pro.woff2') format('woff2'),
    url('../fonts/apercu-regular-pro.woff') format('woff'),
    url('../fonts/apercu-regular-pro.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'apercu-light-pro';
  src: url('../fonts/apercu-light-pro.eot');
  src:
    url('../fonts/apercu-light-pro.eot?#iefix') format('embedded-opentype'),
    url('../fonts/apercu-light-pro.woff2') format('woff2'),
    url('../fonts/apercu-light-pro.woff') format('woff'),
    url('../fonts/apercu-light-pro.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@keyframes spining {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

html,
body,
#root {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  background: white;
  width: 100%;
  height: 100%;
  display: block;
  overflow: hidden;
  position: relative;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

p {
  padding: 0;
  margin: 0;
}

input:not([type='radio']):not([type='checkbox']) {
  appearance: none;
}

textarea {
  resize: none;
  outline: none;
  -webkit-appearance: none;
}

.custom-tooltip {
  @include FkDisplayRegular;
  max-width: 420px;
}

// textarea::placeholder,
// input::placeholder {
//   color: #8092a7;
// }

.GlobalNotification {
  position: fixed;
  overflow: hidden;
  top: 0px;
  left: 0px;
  width: 100%;
  pointer-events: none;
  text-align: center;
  z-index: 3;
}

.GlobalNotificationBody {
  position: relative;
  display: inline-block;
  overflow: hidden;
  margin: 20px auto 20px;
  pointer-events: all;
  border-radius: 5px;
  background: $red-error;
  box-shadow: 0px 2px 16px rgba(26, 26, 26, 0.28);
  text-align: left;
  color: white;
  opacity: 1;
  transition: opacity 0.2s;
  padding: 15px 25px;
  cursor: pointer;
  max-width: 90%;

  &.GlobalNotificationStyleBAD {
    background: $red-error;
  }

  &.GlobalNotificationStyleGOOD {
    background: #59a577;
  }

  &.GlobalNotificationStyleSUGGESTION {
    background: white;
    border: 1px solid black;
    color: black;
    cursor: default;
  }

  &:hover {
    opacity: 0.9;
  }
}

.GlobalNotificationText {
  overflow: hidden;
  display: block;
  position: relative;
  @include RoobertBold;
  font-size: 16px;
  line-height: 25px;
  padding-right: 30px;
  max-width: 550px;
  min-width: 225px;

  span {
    text-decoration: underline;
    cursor: pointer;
  }
}
.GlobalNotificationClose {
  position: absolute;
  display: block;
  overflow: hidden;
  top: 20px;
  right: 20px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('../images/global-notification-close.png');
  background-size: 15px 15px;
  height: 15px;
  width: 15px;
}
.GlobalNotificationBody.GlobalNotificationStyleSUGGESTION {
  .GlobalNotificationClose {
    background-image: url('../images/global-notification-close-black.png');
    cursor: pointer;
  }
}
#root {
  padding: 5px;
}
.AppContainer {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  height: 100%;
  border-radius: 3px;
}
.AppLoadingFull {
  display: block;
  overflow: hidden;
  position: absolute;
  z-index: 4;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 15px;
  padding-top: 18vh;
  text-align: center;
  @include FkDisplayRegular;
  font-size: 40px;
  line-height: 50px;
  color: black;
  letter-spacing: 1px;
  animation: pulse 2000ms infinite linear;
  background: white;
}
@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}
.AppDashboardNetworkError {
  display: block;
  position: fixed;
  overflow: hidden;
  bottom: 20px;
  left: 20px;
  background: white;
  @include RoobertSemiBold;
  color: #901f1f;
  font-size: 16px;
  line-height: 25px;
  z-index: 11;
  padding: 10px 20px;
  max-width: 380px;
  width: 90%;
  box-shadow: 0px 2px 16px rgba(126, 126, 126, 0.28);
  border-radius: 3px;

  &.AppDashboardNetworkErrorBAD {
    padding-left: 50px;
    background-image: url('../images/warning-icon.png');
    background-repeat: no-repeat;
    background-position: left 10px center;
    background-size: 25px 25px;
  }

  &.AppDashboardNetworkErrorGOOD {
    background: #59a577;
    color: white;
  }
}
.Dashboard {
  overflow-x: hidden;
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  z-index: 2;
}
.DashboardFixed {
  z-index: 2;
  position: fixed;
  display: block;
  overflow: hidden;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  border-radius: 5px;
  background-color: transparent;
  pointer-events: none;
}
.DashboardFixedTopCircle {
  width: 120%;
  height: 60%;
  top: -20%;
  left: 10%;
  position: absolute;
  overflow: hidden;
  display: block;
  border-radius: 100%;
  opacity: 0.18;
  filter: blur(50px);
}
.DashboardFixedBottomCircle {
  width: 120%;
  height: 60%;
  bottom: -20%;
  left: -30%;
  position: absolute;
  overflow: hidden;
  display: block;
  border-radius: 100%;
  opacity: 0.18;
  filter: blur(50px);
}
.DashboardNav {
  position: relative;
  display: block;
  overflow: hidden;
  padding: 15px;
  width: 100%;
  z-index: 3;
}
.DashboardNavHeader {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  margin-bottom: 5px;
}
.DashboardNavHeaderLeft {
  position: relative;
  overflow: hidden;
  display: inline-block;
  vertical-align: top;
  min-height: 30px;
  padding-top: 5px;
}
.DashboardNavHeaderLogo {
  position: relative;
  display: block;
  overflow: hidden;
  height: 20px;

  &.DashboardNavHeaderLogoWhite {
    display: none;
  }
}
.DashboardNavHeaderRight {
  position: absolute;
  display: block;
  overflow: hidden;
  top: 0px;
  right: 0px;
  height: 30px;
  width: 30px;
  text-decoration: none;
}
.DashboardNavHeaderProfile {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;
}
.DashboardNavIntro {
  position: relative;
  display: none;
  overflow: hidden;
  text-align: left;
  color: black;
  height: 115px;
}
.DashboardNavIntroHeader {
  @include FkDisplayRegular;
  position: relative;
  display: block;
  overflow: hidden;
  font-size: 28px;
  line-height: 30px;
  margin-bottom: 10px;
}
.DashboardNavIntroBody {
  @include RoobertMedium;
  position: relative;
  display: block;
  overflow: hidden;
  font-size: 12px;
  line-height: 15px;
  padding-right: 20px;
}
.DashboardNavLinks {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  min-height: 25px;
  text-align: left;
}
.DashboardNavLinkPointer {
  visibility: hidden;
  height: 0;
  display: none;
  overflow: hidden;
  position: absolute;
  top: 15px;
  left: 0px;
  border: 1px solid black;
  opacity: 0.5;
  width: 6px;
  border-top: none;
  border-right: none;
}
.DashboardNavLink {
  position: relative;
  display: inline-block;
  overflow: hidden;
  vertical-align: middle;
  margin-right: 5px;
  padding-top: 5px;
  padding-right: 15px;
  text-decoration: none;
  cursor: pointer;

  &.DashboardNavLinkHome,
  &.DashboardNavLinkQueue,
  &.DashboardNavLinkPerks,
  &.DashboardNavLinkPerksExpert,
  &.DashboardNavLinkResourcesExpert,
  &.DashboardNavLinkPro {
    display: none;
  }

  &.DashboardNavLinkSupport {
    margin-right: 0;
  }
}
.DashboardNavLinkText {
  @include RoobertSemiBold;
  font-size: 14px;
  line-height: 20px;
  color: black;
  transition: opacity 0.2s;
  opacity: 0.29;

  &.DashboardNavLinkTextBig {
    display: none;
  }
}
.DashboardNavLink.active,
.DashboardNavLink:hover {
  .DashboardNavLinkText {
    opacity: 1;
  }
}
.DashboardNavLinkSuper {
  @include RoobertMedium;
  position: absolute;
  top: 0;
  right: 0;
  text-align: left;
  width: 13px;
  height: 15px;
  font-size: 10px;
  line-height: 15px;
  color: black;
}
.DashboardNavSubLink {
  position: relative;
  display: none;
  overflow: hidden;
  vertical-align: middle;
  margin-right: 5px;
  padding-top: 5px;
  padding-right: 15px;
  text-decoration: none;
}
.DashboardNavSubLinkText {
  @include RoobertSemiBold;
  font-size: 14px;
  line-height: 20px;
  color: black;
  transition: opacity 0.2s;
  opacity: 0.29;
}
.DashboardNavSubLink.active,
.DashboardNavSubLink:hover {
  .DashboardNavSubLinkText {
    opacity: 1;
  }
}
.AdminDashboardExtras {
  .DashboardNavSubLink {
    display: inline-block;
  }
}
.DashboardNavFooter {
  display: none;
  position: absolute;
  text-align: left;
  bottom: 0;
  left: 0;
  width: 100%;
}
.DashboardNavFooterLink {
  @include RoobertMedium;
  font-size: 16px;
  line-height: 20px;
  color: black;
  text-decoration: none;
  padding: 10px;
  margin-left: 20px;
  margin-bottom: 10px;
  display: inline-block;
  overflow: hidden;
  position: relative;
  transition: background 0.2s;
  border-radius: 2px;

  &:hover,
  &.active {
    background: rgba(200, 200, 200, 0.4);
  }
}
.DashboardMain {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  z-index: 4;
}
.DashboardScreen {
  overflow: hidden;
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  padding-top: 20px;

  &.DashboardScreenHuman {
    padding-left: 0;
  }

  &.ClientsScreen {
    padding-top: 50px;
  }
}
.DashboardScreenLogoLink {
  position: absolute;
  display: none;
  overflow: hidden;
  top: 15px;
  right: 25px;
}
.DashboardScreenLogo {
  position: relative;
  display: block;
  overflow: hidden;
  height: 18px;
}
.DashboardScreenContent {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
}
.HumanDashboardWithModal .DashboardScreenContent {
  height: 0;
}
.DashboardSection {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  margin-bottom: 40px;
}
.DashboardSectionHeader {
  padding: 0 15px;
  @include RoobertMedium;
  position: relative;
  display: block;
  overflow: hidden;
  font-size: 20px;
  line-height: 25px;
  text-align: left;
  color: black;
}
.DashboardSectionHeaderFilters {
  position: absolute;
  display: none;
  overflow: hidden;
  top: 0;
  left: 120px;
}
.DashboardSectionHeaderFilter {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  overflow: hidden;
  margin-left: 10px;
  height: 30px;
  padding: 0 10px;
  line-height: 28px;
  font-size: 14px;
  @include RoobertRegular;
  color: #000;
  opacity: 0.7;
  border: 1px solid black;
  border-radius: 15px;
  background: transparent;
  transition: all 0.2s;
  cursor: pointer;

  &.DashboardSectionHeaderFilterActive {
    background: #000;
    color: white;
    opacity: 1;
  }
}

.DashboardSectionHeaderAction {
  position: absolute;
  display: block;
  overflow: hidden;
  top: 0;
  right: 0;
  display: inline-block;
  vertical-align: top;
  @include RoobertSemiBold;
  font-size: 15px;
  line-height: 20px;
  color: black;
  border: 1px solid black;
  background: white;
  cursor: pointer;
  border-radius: 5px;
  padding: 5px 10px;
  transition:
    background 0.2s,
    color 0.2s;

  &:hover {
    background: black;
    color: white;
  }
}
.DashboardSectionHeaderSub {
  padding: 0 15px;
  @include RoobertMedium;
  position: relative;
  display: block;
  overflow: hidden;
  font-size: 15px;
  line-height: 20px;
  text-align: left;
  color: black;
  opacity: 0.5;
}
.DashboardSectionAligned {
  padding: 0 15px;
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
}
.DashboardSectionEmpty {
  padding: 0 15px;
  @include RoobertMedium;
  font-size: 13px;
  line-height: 15px;
  text-align: left;
  color: black;
  opacity: 0.7;
  margin-top: 5px;
  margin-bottom: 10px;
  max-width: 340px;
  width: 90%;
  position: relative;
  display: block;
  overflow: hidden;
}
.DashboardSectionOnlyButton {
  display: inline-block;
  position: relative;
  overflow: hidden;
  vertical-align: middle;
  line-height: 25px;
  font-size: 20px;
  @include RoobertSemiBold;
  padding: 15px 30px;
  transition: all 0.2s;
  background: #323232;
  color: white;
  border-radius: 3px;
  cursor: pointer;
  margin-left: 15px;

  &:hover {
    background-color: black;
  }

  &.DashboardSectionOnlyButtonLoading {
    pointer-events: none;
    background: none;
    background-color: #a2f4c2;
    color: transparent;
    user-select: none;

    &::after {
      animation: spining 500ms infinite linear;
      border: 3px solid black;
      border-radius: 20px;
      border-right-color: transparent;
      border-top-color: transparent;
      content: '';
      display: block;
      height: 19px;
      width: 19px;
      left: 0;
      right: 0;
      margin: auto;
      top: 0;
      bottom: 0;
      position: absolute;
      text-align: center;
    }
  }
}
.DashboardSectionScroller {
  padding: 5px 15px 10px 15px;
  position: relative;
  display: block;
  overflow-x: auto;
  white-space: nowrap;

  > :last-child {
    margin-right: 0;
  }
}
.DashboardSectionScrollerSwitch {
  padding: 5px 15px 10px 15px;
  position: relative;
  display: block;
  overflow-x: auto;
  white-space: nowrap;

  > :last-child {
    margin-right: 0;
  }
}
.DashboardSectionLoadMore {
  display: none;
  overflow: hidden;
  position: relative;
  text-align: center;
  width: 100px;
  padding: 5px 0;
  @include RoobertSemiBold;
  font-size: 14px;
  line-height: 20px;
  color: #9c9c9c;
  background: transparent;
  border: 1px solid #9c9c9c;
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.2s;
  margin-top: 10px;
  margin-bottom: 10px;

  &:hover {
    color: white;
    background: #02071c;
  }

  &.DashboardSectionLoadMoreBig {
    width: 150px;
    display: block;
  }
}
.DashboardSectionRows {
  padding: 5px 15px 10px 15px;
  position: relative;
  display: block;
}
.DashboardSectionKickOff {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  padding: 15px 15px 10px 15px;
}
.DashboardSectionKickOffBtn {
  display: inline-block;
  position: relative;
  overflow: hidden;
  vertical-align: middle;
  line-height: 20px;
  font-size: 18px;
  @include RoobertSemiBold;
  padding: 15px 30px;
  transition: all 0.2s;
  background: black;
  border: 1px solid black;
  color: white;
  border-radius: 3px;
  cursor: pointer;
  text-decoration: none;
  margin-right: 20px;
  margin-bottom: 5px;

  &.DashboardSectionKickOffBtnWhite {
    color: black;
    background: white;
  }

  &:hover {
    background-color: #363636;

    &.DashboardSectionKickOffBtnWhite {
      background-color: #eee;
    }
  }

  &.DashboardSectionKickOffBtnLoading {
    pointer-events: none;
    background: none;
    color: transparent;
    user-select: none;

    &::after {
      animation: spining 500ms infinite linear;
      border: 3px solid black;
      border-radius: 20px;
      border-right-color: transparent;
      border-top-color: transparent;
      content: '';
      display: block;
      height: 19px;
      width: 19px;
      left: 0;
      right: 0;
      margin: auto;
      top: 13px;
      bottom: 13px;
      position: absolute;
      text-align: center;
    }
  }
}
.DashboardSectionKickOffText {
  display: inline-block;
  position: relative;
  overflow: hidden;
  vertical-align: middle;
  @include ApercuLight;
  color: black;
  font-size: 14px;
  line-height: 20px;
  max-width: 200px;
}
.DashboardErrorCover {
  position: absolute;
  display: block;
  overflow: hidden;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
}
.DashboardErrorCoverOver {
  position: absolute;
  display: block;
  overflow: hidden;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  background-color: pink;
  opacity: 0.8;
  z-index: 2;

  &.DashboardErrorCoverOverClickable {
    cursor: pointer;
  }
}
@supports (
  (-webkit-backdrop-filter: blur(30px)) or (backdrop-filter: blur(30px))
) {
  .DashboardErrorCover:not(.DashboardErrorCoverSee) .DashboardErrorCoverOver {
    opacity: 1;
    background: transparent;
    backdrop-filter: blur(5px);
    --webkit-backdrop-filter: blur(5px);
    background-color: rgba(0, 0, 0, 0.08);
  }
}
.DashboardErrorCover.DashboardErrorCoverSee .DashboardErrorCoverOver {
  background-color: black;
  opacity: 0.37;
}
.DashboardErrorCoverPop {
  z-index: 3;
  position: absolute;
  display: block;
  overflow: hidden;
  top: 12vh;
  left: 10px;
  right: 10px;
  max-width: 500px;
  padding: 90px 25px;
  margin: auto;
  background: white;
  border: 1px solid black;
  background-image: url('../images/warning-icon.png');
  background-repeat: no-repeat;
  background-position: right 10px top 10px;
  background-size: 25px 25px;

  &.DashboardErrorCoverPopCloseable {
    background: white;
  }
}
.DashboardErrorCover.DashboardErrorCoverPending .DashboardErrorCoverPop {
  padding: 40px 25px;
  text-align: center;
}
.DashboardErrorCoverPopClose {
  display: block;
  overflow: hidden;
  position: absolute;
  top: 10px;
  right: 10px;
  width: 30px;
  height: 30px;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url('../images/icon-close-black.png');
  background-size: 18px 18px;
  cursor: pointer;
  border-radius: 2px;
  transition: all 0.2s;
  z-index: 5;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
}
.DashboardErrorCoverNav {
  left: 5px;
  position: absolute;
  display: block;
  overflow: hidden;
  height: 30px;
  top: 10px;
  background-image: url('../images/left-arrow-black.png');
  background-repeat: no-repeat;
  background-position: left 5px center;
  background-size: 12px 20px;
  transition: background-color 0.2s;
  border-radius: 3px;
  padding-left: 30px;
  padding-right: 5px;
  @include RoobertMedium;
  font-size: 18px;
  line-height: 30px;
  color: black;
  white-space: nowrap;
  text-decoration: none;
  text-overflow: ellipsis;
  max-width: 100%;
  cursor: pointer;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
}
.DashboardErrorCoverContent {
  position: relative;
  display: block;
  overflow: hidden;
  color: black;
  @include FkDisplayRegular;
  font-size: 23px;
  line-height: 30px;
  text-align: center;
}
.DashboardErrorCoverTopper {
  padding: 15px 20px 15px 20px;
  z-index: 3;
  position: absolute;
  display: block;
  overflow: hidden;
  top: 5vh;
  left: 10px;
  right: 10px;
  max-width: 800px;
  margin: auto;
  background: white;
  border: 1px solid black;
  text-align: center;
}
.DashboardErrorCoverTopperTitle {
  position: relative;
  display: block;
  overflow: hidden;
  color: black;
  @include RoobertSemiBold;
  font-size: 23px;
  line-height: 30px;
  text-align: left;
  margin-bottom: 20px;
}
.DashboardErrorCoverTopperText {
  position: relative;
  display: block;
  overflow: hidden;
  color: black;
  @include RoobertRegular;
  font-size: 16px;
  line-height: 25px;
  text-align: center;
  min-height: 50px;
}
.DashboardErrorCoverTopperBtn {
  margin: 20px auto 0;
  display: inline-block;
  vertical-align: top;
  position: relative;
  overflow: hidden;
  background: #000;
  border-radius: 3px;
  padding: 10px 20px;
  @include RoobertMedium;
  font-size: 16px;
  line-height: 20px;
  color: white;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: #444;
  }
}
.DashboardErrorCoverConfirm {
  padding: 35px 20px;
  z-index: 3;
  position: absolute;
  display: block;
  overflow: hidden;
  top: 5vh;
  left: 10px;
  right: 10px;
  max-width: 800px;
  margin: auto;
  background: white;
  border: 1px solid black;
  text-align: center;
}
.DashboardErrorCoverConfirmTitle {
  position: relative;
  display: block;
  overflow: hidden;
  color: black;
  @include RoobertSemiBold;
  font-size: 23px;
  line-height: 30px;
  text-align: center;
  margin-bottom: 20px;
}
.DashboardErrorCoverConfirmText {
  position: relative;
  display: block;
  overflow: hidden;
  color: black;
  @include RoobertRegular;
  font-size: 16px;
  line-height: 25px;
  text-align: center;
  min-height: 50px;
  max-width: 480px;
  margin: 0 auto;
}
.DashboardErrorCoverConfirmBtn {
  margin: 30px 10px 0;
  display: inline-block;
  vertical-align: top;
  position: relative;
  overflow: hidden;
  background: #000;
  border-radius: 3px;
  padding: 10px 20px;
  @include RoobertMedium;
  font-size: 16px;
  line-height: 20px;
  color: white;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.2s;
  border: 1px solid black;

  &:hover {
    background-color: #444;
  }

  &.DashboardErrorCoverConfirmBtnWhite {
    color: black;
    background: white;

    &:hover {
      background-color: #eee;
    }
  }
}
.DashboardErrorCoverPopTitle {
  position: relative;
  display: block;
  overflow: hidden;
  color: black;
  @include FkDisplayRegular;
  font-size: 23px;
  line-height: 30px;
  text-align: center;
  margin-bottom: 20px;
}
.DashboardErrorCoverPopText {
  position: relative;
  display: block;
  overflow: hidden;
  color: black;
  @include RoobertRegular;
  font-size: 16px;
  line-height: 25px;
  text-align: center;
  margin-bottom: 15px;
}
.DashboardErrorCoverPopBtn {
  margin: 5px;
  display: inline-block;
  vertical-align: top;
  position: relative;
  overflow: hidden;
  background: white;
  border: 1px solid black;
  border-radius: 3px;
  padding: 10px 20px;
  @include RoobertMedium;
  font-size: 16px;
  line-height: 20px;
  color: black;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    background-color: black;
    color: white;
  }
}
.DashboardProModalCover {
  position: fixed;
  display: block;
  overflow-x: hidden;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
}
.DashboardProModalCoverOver {
  position: fixed;
  display: block;
  overflow: hidden;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.8;
  z-index: 2;
  cursor: pointer;
}
@supports (
  (-webkit-backdrop-filter: blur(30px)) or (backdrop-filter: blur(30px))
) {
  .DashboardProModalCoverOver {
    opacity: 1;
    background: transparent;
    backdrop-filter: blur(5px);
    --webkit-backdrop-filter: blur(5px);
    background-color: rgba(0, 0, 0, 0.5);
  }
}
.DashboardProModal {
  z-index: 3;
  position: absolute;
  display: block;
  overflow: hidden;
  left: 10px;
  right: 10px;
  top: 6vh;
  max-width: 700px;
  padding: 25px;
  margin: auto;
  background: white;
  border: 1px solid black;
  border-radius: 5px;
}
.DashboardProModalClose {
  display: block;
  overflow: hidden;
  position: absolute;
  top: 10px;
  right: 10px;
  width: 30px;
  height: 30px;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url('../images/icon-close-black.png');
  background-size: 18px 18px;
  cursor: pointer;
  border-radius: 2px;
  transition: all 0.2s;
  z-index: 5;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
}
.DashboardProModalTitle {
  display: block;
  width: 100%;
  overflow: hidden;
  position: relative;
  @include RoobertSemiBold;
  font-size: 20px;
  line-height: 25px;
  color: black;
}
.DashboardProModalCompare {
  display: block;
  width: 100%;
  overflow: hidden;
  position: relative;
  margin-top: 30px;
}
.DashboardProModalCompareBox {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  margin-bottom: 10px;
  overflow: hidden;
  position: relative;
  border: 1px solid #f3f3f3;
  background-color: #f3f3f3;
  padding: 20px;
  border-radius: 5px;

  &.DashboardProModalCompareBoxLeft {
    border: 1px solid black;
    background-color: white;
  }
}
.DashboardProModalCompareBoxTitle {
  display: block;
  width: 100%;
  overflow: hidden;
  position: relative;
  @include FkDisplayRegular;
  font-size: 20px;
  line-height: 25px;
  color: black;
  margin-bottom: 30px;
}
.DashboardProModalCompareBoxBullets {
  display: block;
  width: 100%;
  overflow: hidden;
  position: relative;
  opacity: 0.64;
}
.DashboardProModalCompareBox.DashboardProModalCompareBoxLeft
  .DashboardProModalCompareBoxBullets {
  opacity: 1;
}
.DashboardProModalCompareBoxBulletItem {
  display: block;
  width: 100%;
  overflow: hidden;
  position: relative;
  @include ApercuRegular;
  font-size: 16px;
  line-height: 25px;
  color: black;
  padding: 10px 0 10px 25px;
  border-top: 1px solid #f0f0f0;
  background-repeat: no-repeat;
  background-position: left center;
  background-image: url('../images/check-icon.png');
  background-size: 15px 15px;

  &:first-child {
    border-top: 0;
  }

  &.DashboardProModalCompareBoxBulletItemNone {
    border-top: 0;
    background: none;
  }
}
.DashboardProModalPay {
  display: block;
  width: 100%;
  overflow: hidden;
  position: relative;
  margin-top: 30px;

  .ProjectDetailPayment {
    margin-top: 0;
    margin-bottom: 0;
  }
}
.DashboardProModalPayFooter {
  display: block;
  width: 100%;
  overflow: hidden;
  position: relative;
}
.DashboardProModalPayBtn {
  overflow: hidden;
  position: relative;
  display: inline-block;
  vertical-align: top;
  background-color: black;
  @include RoobertSemiBold;
  font-size: 17px;
  line-height: 20px;
  color: white;
  cursor: pointer;
  border-radius: 5px;
  padding: 15px 45px;
  margin-bottom: 20px;
  margin-right: 25px;
  transition:
    background-color 0.2s,
    color 0.2s;

  &:hover {
    background-color: #a2f4c2;
    color: black;
  }
  &.DashboardProModalPayBtnInvalid {
    pointer-events: none;
    background-color: #888;
  }
  &.DashboardProModalPayBtnLoading {
    pointer-events: none;
    background: none;
    background-color: #a2f4c2;
    color: transparent;
    user-select: none;

    &::after {
      animation: spining 500ms infinite linear;
      border: 3px solid black;
      border-radius: 20px;
      border-right-color: transparent;
      border-top-color: transparent;
      content: '';
      display: block;
      height: 19px;
      width: 19px;
      left: 0;
      right: 0;
      margin: auto;
      top: 13px;
      bottom: 13px;
      position: absolute;
      text-align: center;
    }
  }
}
.DashboardProModalPayText {
  overflow: hidden;
  position: relative;
  display: inline-block;
  vertical-align: top;
  @include RoobertMedium;
  padding: 5px 0;
  font-size: 13px;
  line-height: 20px;
  color: #939393;
  width: 190px;

  span {
    @include RoobertSemiBold;
    color: black;
  }
}
.DashboardModal {
  position: fixed;
  display: block;
  overflow: hidden;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  border-radius: 5px;
  z-index: 5;
  background: transparent;
  color: black;

  &.fade-enter {
    opacity: 0;
    transform: scale(0.9);
  }
  &.fade-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition:
      opacity 300ms,
      transform 300ms;
  }
  &.fade-exit {
    opacity: 1;
  }
  &.fade-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition:
      opacity 300ms,
      transform 300ms;
  }
}

.DashboardModalTopCircle {
  width: 120%;
  height: 60%;
  top: -20%;
  left: 10%;
  position: absolute;
  overflow: hidden;
  display: block;
  border-radius: 100%;
  opacity: 0.18;
  filter: blur(50px);
}
.DashboardModalBottomCircle {
  width: 120%;
  height: 60%;
  bottom: -20%;
  left: -30%;
  position: absolute;
  overflow: hidden;
  display: block;
  border-radius: 100%;
  opacity: 0.18;
  filter: blur(50px);
}

.Dashboard.ExpertDashboardHome {
  .DashboardFixed,
  .DashboardModal {
    background-color: #171717;
  }
  .DashboardFixedTopCircle,
  .DashboardFixedBottomCircle,
  .DashboardModalTopCircle,
  .DashboardModalBottomCircle {
    display: none;
  }
  .DashboardNavLinkPointer {
    visibility: visible;
    height: 36px;
  }
}
.Dashboard.ExpertDashboardLeads {
  .DashboardFixed,
  .DashboardModal {
    background-color: #f5f5f5;
  }
  .DashboardFixedTopCircle,
  .DashboardFixedBottomCircle,
  .DashboardModalTopCircle,
  .DashboardModalBottomCircle {
    display: none;
  }
  .DashboardNavLinkPointer {
    visibility: visible;
    height: 72px;
  }
}
.Dashboard.ExpertDashboardClients {
  .DashboardFixed,
  .DashboardModal {
    background-color: #f2f0eb;
  }
  .DashboardFixedTopCircle,
  .DashboardFixedBottomCircle,
  .DashboardModalTopCircle,
  .DashboardModalBottomCircle {
    background-color: none;
  }
  .DashboardNavLinkPointer {
    visibility: visible;
    height: 106px;
  }
}
.Dashboard.ExpertDashboardInbound {
  .DashboardFixed,
  .DashboardModal {
    background-color: #f2f0eb;
  }
  .DashboardFixedTopCircle,
  .DashboardFixedBottomCircle,
  .DashboardModalTopCircle,
  .DashboardModalBottomCircle {
    background-color: none;
  }
  .DashboardNavLinkPointer {
    visibility: visible;
    height: 142px;
  }
}
.Dashboard.ExpertDashboardDangerZone {
  .DashboardFixed,
  .DashboardModal {
    background-color: #f1eded;
  }
  .DashboardFixedTopCircle,
  .DashboardFixedBottomCircle,
  .DashboardModalTopCircle,
  .DashboardModalBottomCircle {
    background-color: none;
  }
  .DashboardNavLinkPointer {
    visibility: visible;
    height: 178px;
  }
}
.Dashboard.ExpertDashboardSupport {
  .DashboardFixed,
  .DashboardModal {
    background-color: #eaebea;
  }
  .DashboardFixedTopCircle,
  .DashboardFixedBottomCircle,
  .DashboardModalTopCircle,
  .DashboardModalBottomCircle {
    background-color: none;
  }
  .DashboardNavLinkPointer {
    visibility: visible;
    height: 214px;
  }
}
.Dashboard.ExpertDashboardCollab {
  .DashboardFixed,
  .DashboardModal {
    background-color: #f3f3f3;
  }
  .DashboardFixedTopCircle,
  .DashboardFixedBottomCircle,
  .DashboardModalTopCircle,
  .DashboardModalBottomCircle {
    background-color: white;
  }
  .DashboardNavLinkPointer {
    visibility: visible;
    height: 250px;
  }
}
.Dashboard.ExpertDashboardProfile {
  .DashboardFixed,
  .DashboardModal {
    background-color: #f2f0eb;
  }
  .DashboardFixedTopCircle,
  .DashboardFixedBottomCircle,
  .DashboardModalTopCircle,
  .DashboardModalBottomCircle {
    background-color: none;
  }
  .DashboardNavLinkPointer {
    visibility: hidden;
  }
}
.Dashboard.HumanDashboard {
  background-color: white;
}
.Dashboard.HumanDashboard.HumanDashboardHome {
  background-color: #171717;
}
.Dashboard.HumanDashboardHome {
  .DashboardNavLinkPointer {
    visibility: visible;
    height: 36px;
  }
}
.Dashboard.HumanDashboardProjects {
  .DashboardFixed,
  .DashboardModal {
    background-color: white;
  }
  .DashboardNavLinkPointer {
    visibility: visible;
    height: 72px;
  }
}
.Dashboard.HumanDashboardRequests {
  .DashboardFixed,
  .DashboardModal {
    background-color: white;
  }
  .DashboardNavLinkPointer {
    visibility: visible;
    height: 72px;
  }
}
.Dashboard.HumanDashboardNew {
  .DashboardFixed,
  .DashboardModal {
    background-color: white;
  }
  .DashboardNavLinkPointer {
    visibility: visible;
    height: 72px;
  }
}
.Dashboard.HumanDashboardExperts {
  .DashboardFixed,
  .DashboardModal {
    background-color: white;
  }
  .DashboardNavLinkPointer {
    visibility: visible;
    height: 106px;
  }
}
.Dashboard.HumanDashboardMatch {
  .DashboardFixed,
  .DashboardModal {
    background-color: #f2ead7;
  }
  .DashboardNavLinkPointer {
    visibility: visible;
    height: 106px;
  }
}
.Dashboard.HumanDashboardSupport {
  .DashboardNavLinkPointer {
    visibility: visible;
    height: 142px;
  }
}
.Dashboard.AdminDashboardQueue {
  .DashboardFixed,
  .DashboardModal {
    background-color: #f5f5f5;
  }
  .DashboardFixedTopCircle,
  .DashboardFixedBottomCircle,
  .DashboardModalTopCircle,
  .DashboardModalBottomCircle {
    background-color: #c1b79d;
  }
  .DashboardNavLinkPointer {
    visibility: visible;
    height: 36px;
  }
}
.Dashboard.AdminDashboardBrands {
  .DashboardFixed,
  .DashboardModal {
    background-color: #f5f5f5;
  }
  .DashboardFixedTopCircle,
  .DashboardFixedBottomCircle,
  .DashboardModalTopCircle,
  .DashboardModalBottomCircle {
    background-color: #e6d2a1;
  }
  .DashboardNavLinkPointer {
    visibility: visible;
    height: 72px;
  }
}
.Dashboard.AdminDashboardHumans {
  .DashboardFixed,
  .DashboardModal {
    background-color: #f5f5f5;
  }
  .DashboardFixedTopCircle,
  .DashboardFixedBottomCircle,
  .DashboardModalTopCircle,
  .DashboardModalBottomCircle {
    background-color: #83c3a9;
  }
  .DashboardNavLinkPointer {
    visibility: visible;
    height: 72px;
  }
}
.Dashboard.AdminDashboardExperts {
  .DashboardFixed,
  .DashboardModal {
    background-color: #f5f5f5;
  }
  .DashboardFixedTopCircle,
  .DashboardFixedBottomCircle,
  .DashboardModalTopCircle,
  .DashboardModalBottomCircle {
    background-color: white;
  }
  .DashboardNavLinkPointer {
    visibility: visible;
    height: 106px;
  }
}
.Dashboard.AdminDashboardDangerZone {
  .DashboardFixed,
  .DashboardModal {
    background-color: #f5f5f5;
  }
  .DashboardFixedTopCircle,
  .DashboardFixedBottomCircle,
  .DashboardModalTopCircle,
  .DashboardModalBottomCircle {
    background-color: #fcd266;
  }
  .DashboardNavLinkPointer {
    visibility: visible;
    height: 142px;
  }
}
.Dashboard.AdminDashboardMatch {
  .DashboardFixed,
  .DashboardModal {
    background-color: #f5f5f5;
  }
  .DashboardFixedTopCircle,
  .DashboardFixedBottomCircle,
  .DashboardModalTopCircle,
  .DashboardModalBottomCircle {
    background-color: #fcd266;
  }
  .DashboardNavLinkPointer {
    visibility: visible;
    height: 142px;
  }
}
.Dashboard.AdminDashboardInbound {
  .DashboardFixed,
  .DashboardModal {
    background-color: #f5f5f5;
  }
  .DashboardFixedTopCircle,
  .DashboardFixedBottomCircle,
  .DashboardModalTopCircle,
  .DashboardModalBottomCircle {
    background-color: #f0da9f;
  }
  .DashboardNavLinkPointer {
    visibility: visible;
    height: 176px;
  }
}
.Dashboard.AdminDashboardExtras {
  .DashboardFixed,
  .DashboardModal {
    background-color: #f5f5f5;
  }
  .DashboardFixedTopCircle,
  .DashboardFixedBottomCircle,
  .DashboardModalTopCircle,
  .DashboardModalBottomCircle {
    background-color: #bad0ff;
  }
  .DashboardNavLinkPointer {
    visibility: visible;
    height: 212px;
  }
}
.Dashboard.PartnerDashboard {
  .DashboardModal {
    background-color: #f2ead7;
  }
  .DashboardModalTopCircle,
  .DashboardModalBottomCircle {
    background-color: #e6d2a1;
  }
}
.CardStatusLineACCEPTED {
  background: #2d5858;
}
.CardStatusLineAPPROVED {
  background: #2d5858;
}
.CardStatusLineCANCELED {
  background: #dd5d5c;
}
.CardStatusLineCREATED {
  background: #1044d0;
}
.CardStatusLineCOMPLETED {
  background: #2d5858;
}
.CardStatusLineREVISIONS_REQUESTED {
  background: #dd5d5c;
}
.CardStatusLineSUBSCRIPTION_PAYMENT_MADE {
  background: #2d5858;
}
.CardStatusLineUPDATED {
  background: #1044d0;
}
.CardStatusLineIN_PROGRESS {
  background: #1044d0;
}
.CardStatusLineNEEDS-ACTION {
  background: #dd5d5c;
}
.CardStatusLineDECLINED {
  background: #dd5d5c;
}

@media only screen and (min-width: 400px) {
  .DashboardNavLink {
    padding-right: 17px;
  }
  .DashboardNavLinkText {
    font-size: 16px;
  }
  .DashboardNavLinkSuper {
    width: 15px;
    font-size: 12px;
  }
}
@media only screen and (min-width: 440px) {
  .DashboardNavLink.DashboardNavLinkPerks {
    display: inline-block;
  }
}
@media only screen and (min-width: 500px) {
  .DashboardNavLinkText {
    font-size: 18px;

    &.DashboardNavLinkTextSmall {
      display: none;
    }
    &.DashboardNavLinkTextBig {
      display: inline;
    }
  }
  .DashboardSectionScrollerSwitch {
    overflow: hidden;
    white-space: unset;
  }
  .DashboardSectionLoadMore {
    display: block;
  }
}
@media only screen and (min-width: 575px) {
  .DashboardNavLink.DashboardNavLinkHome {
    display: inline-block;
  }
  .GlobalNotificationBody {
    &.GlobalNotificationStyleSUGGESTION {
      padding: 15px 35px;
    }
  }
}
@media only screen and (min-width: 600px) {
  .DashboardSectionHeaderFilters {
    display: block;
  }
  .DashboardFixedTopCircle,
  .DashboardModalTopCircle {
    width: 80%;
    height: 80%;
    top: -40%;
    left: 50%;
  }
  .DashboardFixedBottomCircle,
  .DashboardModalBottomCircle {
    width: 60%;
    height: 60%;
    bottom: -20%;
    left: -20%;
  }
  .DashboardNav {
    padding: 25px;
  }
  .DashboardNavLink.DashboardNavLinkQueue {
    display: inline-block;
  }
  .DashboardSectionHeader {
    padding: 0 25px;
    font-size: 22px;
    line-height: 30px;
  }
  .DashboardSectionHeaderSub {
    padding: 0 25px;
    font-size: 16px;
  }
  .DashboardSectionAligned {
    padding: 0 25px;
  }
  .DashboardSectionEmpty {
    padding: 0 25px;
    font-size: 16px;
    line-height: 20px;
    max-width: 420px;
  }
  .DashboardSectionScroller {
    padding: 5px 25px 10px 25px;
  }
  .DashboardSectionScrollerSwitch {
    padding: 5px 25px 10px 25px;
  }
  .DashboardSectionLoadMore.DashboardSectionLoadMoreMarginLeft {
    margin-left: 25px;
  }
  .DashboardSectionRows {
    padding: 5px 25px 10px 25px;
  }
  .DashboardSectionKickOff {
    padding: 15px 25px 10px 25px;
  }
  .DashboardProModalCompareBox {
    width: 49%;

    &.DashboardProModalCompareBoxLeft {
      margin-right: 2%;
    }
  }
}
@media only screen and (min-width: 680px) {
  .DashboardProModalCompareBoxBulletItem {
    min-height: 45px;
  }
}
@media only screen and (min-width: $base-sidebar-breakpoint) {
  .Dashboard {
    overflow: hidden;
  }
  .DashboardNav {
    position: absolute;
    width: 250px;
    top: 0px;
    left: 0px;
    height: 100%;
    padding: 15vh 30px 55px;
  }
  .DashboardNavIntro {
    display: block;
  }
  .DashboardNavHeader {
    display: none;
  }
  .DashboardNavLinks {
    min-height: unset;
    padding-top: 35px;
  }
  .DashboardNavLinkPointer {
    display: block;
  }
  .DashboardNavLink {
    display: block;
    margin-bottom: 5px;
    vertical-align: unset;
    width: 100%;
    margin-right: 0;
    padding-left: 12px;

    &.DashboardNavLinkHome,
    &.DashboardNavLinkQueue,
    &.DashboardNavLinkPerks,
    &.DashboardNavLinkPerksExpert,
    &.DashboardNavLinkResourcesExpert,
    &.DashboardNavLinkPro {
      display: block;
    }
  }
  .DashboardNavLinkText {
    @include FkDisplayRegular;
    font-size: 20px;
    line-height: 25px;
  }
  .DashboardNavLinkSuper {
    position: relative;
    top: unset;
    right: unset;
    width: unset;
    height: unset;
    font-size: 14px;
    vertical-align: top;
    margin-left: 10px;
    line-height: 25px;
    height: 25px;
    color: black;
    width: 25px;
    background: #f2aeae;
    display: inline-block;
    border-radius: 40px;
    text-align: center;
  }
  .DashboardNavSubLink {
    margin-bottom: 0px;
    vertical-align: unset;
    width: 100%;
    margin-right: 0;
    padding-left: 22px;
  }
  .DashboardNavSubLinkText {
    @include FkDisplayRegular;
    // font-size: 20px;
    // line-height: 25px;
  }
  .AdminDashboardExtras {
    .DashboardNavSubLink {
      display: block;
    }
  }
  .DashboardNavFooter {
    display: block;
  }
  .DashboardErrorCoverTopper {
    padding: 15px 200px 15px 20px;
  }
  .DashboardErrorCoverTopperText {
    text-align: left;
  }
  .DashboardErrorCoverTopperBtn {
    position: absolute;
    top: 20px;
    right: 20px;
    margin: 0;
    display: block;
    vertical-align: unset;
  }
  .DashboardMain {
    position: absolute;
    top: 0px;
    left: 250px;
    right: 0px;
    bottom: 0px;
    width: unset;
  }

  .DashboardScreen {
    overflow: unset;
    overflow-x: hidden;
    padding: 11vh 0 0;
    &.DashboardScreenHuman {
      padding-left: 7%;
    }
    &.ClientsScreen {
      padding: 11vh 0 0;
    }
  }
  .DashboardScreenLogoLink {
    display: block;
  }
  .DashboardSection {
    // margin-bottom: 50px;
  }
  .DashboardSectionHeader {
    padding: 0 15px;
    font-size: 22px;
    line-height: 30px;
  }
  .DashboardSectionHeaderSub {
    padding: 0 15px;
  }
  .DashboardSectionAligned {
    padding: 0 15px;
  }
  .DashboardSectionEmpty {
    padding: 0 15px;
  }
  .DashboardSectionScroller {
    padding: 5px 15px 10px 15px;
  }
  .DashboardSectionScrollerSwitch {
    overflow-x: auto;
    white-space: nowrap;
    padding: 5px 15px 10px 15px;
  }
  .DashboardSectionLoadMore {
    display: none;
    &.DashboardSectionLoadMoreBig {
      display: block;
    }
    &.DashboardSectionLoadMoreMarginLeft {
      margin-left: 15px;
    }
  }
  .DashboardSectionRows {
    padding: 5px 15px 10px 15px;
  }
  .DashboardSectionKickOff {
    padding: 15px 15px 10px 15px;
  }
}
@media only screen and (min-width: 840px) {
  .DashboardSectionScrollerSwitch {
    overflow: hidden;
    white-space: unset;
  }
  .DashboardSectionLoadMore {
    display: block;
  }
}
