@import '../Common';

.ThreadDetailView {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  height: 100%;
}
.ThreadDetailMain {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  height: 100%;
  z-index: 2;
  padding-top: 50px;

  &.ThreadDetailMainIncreaseZ {
    z-index: 5;
  }
}
.ThreadDetailMainBodyCover {
  z-index: 3;
  position: absolute;
  display: none;
  overflow: hidden;
  width: 100%;
  height: 100%;
  background: black;
  top: 0;
  left: 0;
  opacity: 0.5;
  cursor: pointer;
}
.ThreadDetailSidebar {
  width: 100%;
  right: -100%;
  position: absolute;
  display: block;
  overflow: hidden;
  height: 100%;
  top: 0px;
  z-index: 4;
  padding-top: 50px;
  background-color: #f2f0eb;
  transition: right 0.2s;
}
.HumanRequestDetailModal .ThreadDetailSidebar {
  background-color: white;
}
.HumanProjectDetailModal .ThreadDetailSidebar {
  background-color: white;
}
.HumanExpertDetailModal .ThreadDetailSidebar {
  background-color: white;
}
.HumanMatchDetailModal .ThreadDetailSidebar {
  // background-color: white;
}
.SupportChannelDetailModal .ThreadDetailSidebar {
  background-color: #e4e6e1;
}
.AdminExpertDetailModal .ThreadDetailSidebar {
  background-color: #e4e6e1;
}
.AdminMatchDetailModal .ThreadDetailSidebar {
  background-color: #fbeecb;
}
.ThreadDetailView.ThreadDetailViewWithSidebar {
  .ThreadDetailMainBodyCover {
    display: block;
  }
  .ThreadDetailSidebar {
    right: 0;
  }
}
.ThreadDetailView.ThreadDetailViewWithModal {
  .ThreadDetailMain {
    z-index: 5;
  }
}
.ThreadDetailMainHeader {
  padding: 10px 10px 10px 40px;
  position: absolute;
  display: block;
  overflow: hidden;
  top: 0px;
  left: 0px;
  height: 50px;
  width: 100%;
  z-index: 3;
}
.ThreadDetailMainHeaderBack {
  margin-left: -35px;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  overflow: hidden;
  height: 30px;
  background-image: url('../../images/left-arrow-black.png');
  background-repeat: no-repeat;
  background-position: left 5px center;
  background-size: 12px 20px;
  transition: background-color 0.2s;
  border-radius: 3px;
  padding-left: 30px;
  padding-right: 5px;
  @include RoobertMedium;
  font-size: 22px;
  line-height: 30px;
  color: black;
  white-space: nowrap;
  text-decoration: none;
  text-overflow: ellipsis;
  max-width: 100%;
  cursor: pointer;

  &:hover {
    background-color: rgba(255, 255, 255, 0.4);
  }
}
.ThreadDetailMainHeaderPin {
  position: relative;
  display: none;
  vertical-align: middle;
  overflow: hidden;
  margin-left: 15px;
  height: 25px;
  padding: 0 10px;
  padding-left: 30px;
  @include RoobertMedium;
  font-size: 16px;
  line-height: 25px;
  color: #000;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.58);
  background-repeat: no-repeat;
  background-position: left 5px center;
  background-image: url('../../images/star-icon-empty.png');
  background-size: 15px 15px;
  transition: background-color 0.2s;
  cursor: pointer;

  &:hover,
  &.ThreadDetailMainHeaderPinYes {
    background-image: url('../../images/star-icon-full.png');
    background-color: rgba(255, 255, 255, 0.98);
  }
}
.ThreadDetailMainHeaderToggle {
  position: absolute;
  display: block;
  overflow: hidden;
  width: 30px;
  height: 30px;
  background-image: url('../../images/sidebar-toggle-black.png');
  top: 10px;
  right: 10px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 24px 20px;
  transition: background-color 0.2s;
  border-radius: 3px;
  cursor: pointer;

  &:hover {
    background-color: rgba(255, 255, 255, 0.4);
  }
}
.ThreadDetailMainBody {
  position: relative;
  display: block;
  overflow-x: hidden;
  width: 100%;
  height: 100%;
  z-index: 2;

  .ThreadEvents {
    padding: 40px 15px;

    &.ThreadEventsHasAction {
      padding-bottom: 0px;
    }
  }
}
.ThreadCurrentAction {
  padding-right: 5px;
  padding-bottom: 55px;
  padding-left: 15px;
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
}
.ThreadActionEmail {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  max-width: 700px;
  float: right;

  &.ThreadActionEmailSending {
    visibility: hidden;
    opacity: 0;
  }
}
.ThreadActionText {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  max-width: 500px;
  float: right;

  &.ThreadActionTextSending {
    visibility: hidden;
    opacity: 0;
  }
}
.ThreadActionMessageHq {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  max-width: 600px;
  float: right;

  &.ThreadActionMessageHqSending {
    visibility: hidden;
    opacity: 0;
  }
}
.ThreadActionPhone {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  max-width: 400px;
  float: right;

  &.ThreadActionPhoneSending {
    visibility: hidden;
    opacity: 0;
  }
}
.ThreadActionOrphanClient {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  max-width: 400px;
  float: right;
}
.ThreadActionQuote {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  max-width: 700px;
  float: right;
}
.ThreadActionQuoteTipWrapper {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  padding-right: 10px;
}
.ThreadActionQuoteTip {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  border-radius: 5px;
  background: #f0f0f0;
  border: 1px solid #000;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  padding: 10px 20px;
  margin-bottom: 20px;
}
.ThreadActionQuoteTipTitle {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  font-size: 21px;
  @include RoobertSemiBold;
  line-height: 30px;
  color: black;
  margin-bottom: 5px;
}
.ThreadActionQuoteTipLearn {
  display: inline-block;
  vertical-align: top;
  overflow: hidden;
  position: absolute;
  top: 20px;
  right: 20px;
  @include RoobertMedium;
  font-size: 15px;
  line-height: 20px;
  text-decoration: none;
  opacity: 0.5;
  color: black;
  background-repeat: no-repeat;
  background-position: right 5px center;
  background-image: url('../../images/view-website-icon.png');
  background-size: 10px 10px;
  background-color: transparent;
  padding-right: 25px;
  transition: opacity 0.2s;

  &:hover {
    opacity: 1;
  }
}
.ThreadActionQuoteTipSection {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  margin: 15px 0;
}

.ThreadActionQuoteTipSectionHeader {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include RoobertSemiBold;
  text-decoration: underline;
  font-size: 18px;
  line-height: 30px;
  color: #000;
}

.ThreadActionQuoteTipBody {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  font-size: 17px;
  @include RoobertMedium;
  line-height: 30px;
  color: black;
  opacity: 0.95;
}
.ThreadActionMeeting {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  max-width: 600px;
  float: right;

  &.ThreadActionMeetingSending {
    visibility: hidden;
    opacity: 0;
  }
}
.ThreadDetailActions {
  right: 10px;
  bottom: 10px;
  display: block;
  overflow: hidden;
  position: absolute;
  z-index: 4;
  padding: 5px;
  width: 96%;
  pointer-events: none;
}
.ThreadDetailActionRow {
  position: relative;
  display: block;
  width: 100%;
  pointer-events: none;
  clear: both;
}
.ThreadDetailActionItem {
  @include RoobertSemiBold;
  display: none;
  overflow: hidden;
  position: relative;
  vertical-align: middle;
  border-radius: 20px;
  background: transparent;
  transition: all 0.2s;
  cursor: pointer;
  background: white;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.26);
  font-size: 15px;
  line-height: 30px;
  padding: 5px 20px;
  color: black;
  margin-left: 7px;
  margin-top: 7px;
  transition: all 0.2s;
  pointer-events: all;
  float: right;

  &:last-child {
    margin-left: 0;
  }

  span {
    display: none;
  }

  &:hover {
    background: black;
    color: white;
  }
}
.ThreadDetailActions.ThreadDetailActionsOpen {
  .ThreadDetailActionItem {
    display: inline-block;
  }
}
.ThreadDetailActionTrigger {
  background: white;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.26);
  display: inline-block;
  overflow: hidden;
  position: relative;
  vertical-align: middle;
  float: right;
  border-radius: 20px;
  transition: all 0.2s;
  cursor: pointer;
  margin-left: 7px;
  margin-top: 7px;
  pointer-events: all;
}
.ThreadDetailActionBtn {
  background: transparent;
  display: inline-block;
  vertical-align: middle;
  overflow: hidden;
  position: relative;
  @include RoobertSemiBold;
  font-size: 26px;
  text-align: center;
  color: black;
  width: 30px;
  height: 30px;
  border-radius: 20px;
  line-height: 28px;
  transition: all 0.2s;
  margin: 5px;
}
.ThreadDetailActionText {
  display: none;
  vertical-align: middle;
  overflow: hidden;
  position: relative;
  @include RoobertSemiBold;
  font-size: 15px;
  line-height: 28px;
  color: black;
  transition: all 0.2s;
  padding: 0 15px 0 7px;
  margin-top: -2px;
}
.ThreadDetailActionTrigger:hover {
  background: black;
  .ThreadDetailActionBtn {
    color: white;
  }
}
.ThreadDetailActions.ThreadDetailActionsOpen .ThreadDetailActionTrigger {
  background: black;
  .ThreadDetailActionBtn {
    color: white;
    transform: rotate(45deg);
  }
}
.ThreadDetailActions.ThreadDetailActionsCurrent .ThreadDetailActionTrigger {
  background: black;
  .ThreadDetailActionBtn {
    color: white;
    transform: rotate(45deg);
  }
}
.ThreadDetailSidebarHeader {
  padding: 10px 10px 10px 35px;
  position: absolute;
  display: block;
  overflow: hidden;
  top: 0px;
  left: 0px;
  height: 50px;
  width: 100%;
  z-index: 3;
}
.ThreadDetailSidebarHeaderBack {
  position: absolute;
  display: block;
  overflow: hidden;
  width: 30px;
  height: 30px;
  background-image: url('../../images/left-arrow-black.png');
  top: 10px;
  left: 5px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 12px 20px;
  transition: background-color 0.2s;
  border-radius: 3px;
  cursor: pointer;

  &:hover {
    background-color: rgba(255, 255, 255, 0.4);
  }
}
.ThreadDetailSidebarHeaderPrimary {
  position: relative;
  display: inline-block;
  overflow: hidden;
  vertical-align: middle;
  @include RoobertSemiBold;
  font-size: 18px;
  line-height: 20px;
  padding: 5px;
  padding-left: 0;
  margin-right: 10px;
  color: black;
}
.ThreadDetailSidebarHeaderSecondarySwitch {
  position: relative;
  display: inline-block;
  overflow: hidden;
  vertical-align: middle;
  @include RoobertSemiBold;
  font-size: 14px; // move back to 12 once notes are back
  line-height: 20px;
  color: black;
  opacity: 0.54;
  cursor: pointer;
  transition: opacity 0.2s;
  padding: 5px;
  margin-right: 10px;

  &:hover {
    opacity: 1;
  }
}
.ThreadDetailSidebarHeaderSearch {
  position: absolute;
  display: block;
  overflow: hidden;
  z-index: 3;
  height: 50px;
  top: 0px;
  right: 0px;
  padding: 10px;
  padding-left: 35px;
  width: 75px;
  pointer-events: none;
  transition: width 0.2s;
}
.ThreadDetailSidebarHeaderSearchBar {
  display: block;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  border-radius: 30px;
  padding: 5px 10px;
  pointer-events: all;
  transition: all 0.2s;

  &:hover {
    background-color: white;
  }
}
.ThreadDetailSidebarHeaderSearchBarInput {
  display: none;
  position: relative;
  overflow: hidden;
  width: 100%;
  @include RoobertMedium;
  font-size: 16px;
  color: black;
  height: 100%;
  border: none;
  outline: none;
  background: transparent;
}
.ThreadDetailSidebarHeaderSearchToggle {
  position: absolute;
  display: block;
  overflow: hidden;
  width: 30px;
  height: 30px;
  background-image: url('../../images/search-icon-black.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 15px 15px;
  border-radius: 3px;
  cursor: pointer;
  top: 0px;
  right: 0px;
}
.ThreadDetailSidebarHeaderSearch.ThreadDetailSidebarHeaderSearchActive {
  width: 100%;

  .ThreadDetailSidebarHeaderSearchBar {
    background-color: white;
    padding-right: 30px;
  }

  .ThreadDetailSidebarHeaderSearchBarInput {
    display: block;
  }
  .ThreadDetailSidebarHeaderSearchToggle {
    background-size: 12px 12px;
    background-image: url('../../images/search-close-black.png');
  }
}
.ThreadDetailSidebarBody {
  position: relative;
  display: block;
  overflow-x: hidden;
  width: 100%;
  height: 100%;
  z-index: 2;
}
.ThreadDetailSidebarBodyPanels {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
}
.ThreadDetailSidebarBodyPanel {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  padding-bottom: 75px;
  padding-top: 10px;

  &.ThreadDetailSidebarBodyPanelFiles {
    padding-left: 10px;
    padding-right: 10px;
  }

  &.ThreadDetailSidebarBodyPanelAbout {
    padding-left: 10px;
    padding-right: 10px;
  }

  &.ThreadDetailSidebarBodyPanelNewRequest {
    padding-top: 100px;
  }
}
.ThreadDetailSidebarBodySearch {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  padding-bottom: 75px;
  padding-top: 10px;
}
.ThreadDetailSidebarSearchResults {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
}
.ThreadDetailSidebarSearchResult {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  padding: 10px;
}
.ThreadDetailSidebarSearchResultCard {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  background: white;
  border-radius: 5px;
  margin-bottom: 5px;
  min-height: 100px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  padding: 15px 10px;
  cursor: pointer;
  border: 2px solid white;
  transition: all 0.2s;

  &:hover {
    border-color: $green;
  }
}
.ThreadDetailSidebarSearchResult.ThreadDetailSidebarSearchResultSelected
  .ThreadDetailSidebarSearchResultCard {
  border-color: $green;
}
.ThreadDetailSidebarSearchResultCardRecipients {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  padding-right: 50px;
  min-height: 30px;

  .UserBubble {
    display: inline-block;
    vertical-align: top;
    margin-right: 5px;
    margin-bottom: 5px;
    background-color: #e0e0e0;
    color: black;

    &.UserBubblePrimary {
      background-color: black;
      color: white;
    }
  }
}
.ThreadDetailSidebarSearchResultCardFromExpert {
  position: absolute;
  display: block;
  overflow: hidden;
  top: 15px;
  right: 10px;

  .ExpertUserBubble {
  }
}
.ThreadDetailSidebarSearchResultCardSubject {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include RoobertSemiBold;
  font-size: 18px;
  line-height: 25px;
  color: black;
  opacity: 0.9;
  margin-top: 5px;
}
.ThreadDetailSidebarSearchResultCardText {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  margin-top: 5px;
  @include ApercuLight;
  font-size: 16px;
  line-height: 20px;
  color: black;
  opacity: 0.9;

  span {
    background: #ffc0cb;
    border-radius: 3px;
  }
}
.ThreadDetailSidebarSearchResultBottom {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  padding: 0px 5px;
  padding-right: 180px;
}
.ThreadDetailSidebarSearchResultBottomLeft {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  font-size: 13px;
  @include RoobertMedium;
  line-height: 20px;
  color: black;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ThreadDetailSidebarSearchResultBottomRight {
  position: absolute;
  top: 0px;
  right: 5px;
  display: block;
  overflow: hidden;
  font-size: 13px;
  @include RoobertMedium;
  line-height: 20px;
  color: black;
}
.ThreadDetailMainFocusModal {
  position: fixed;
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;
  display: block;
  overflow: hidden;
  background: black;
  background: rgba(0, 0, 0, 0.5);
  z-index: 5;
  cursor: pointer;
}
.ThreadDetailMainFocusModalCover {
  position: absolute;
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;
}
.ThreadDetailMainFocusModalContainer {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  position: absolute;
  display: block;
  overflow: hidden;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.11);
  max-width: 600px;
  margin: auto;
  z-index: 3;
  cursor: auto;

  &.ThreadDetailMainFocusModalContainerSearch {
    max-width: 800px;
  }
}
.ThreadDetailMainFocusModalLoading {
  position: relative;
  display: block;
  overflow: hidden;
  height: 32px;
  width: 32px;
  animation: spining 500ms infinite linear;
  border: 3px solid black;
  border-radius: 50px;
  border-right-color: transparent;
  border-top-color: transparent;
  content: '';
  left: 0;
  right: 0;
  margin: auto;
  top: 10vh;
  position: absolute;
  text-align: center;
  pointer-events: none;
  user-select: none;
}
.ThreadDetailMainFocusModalContainerBody {
  position: relative;
  display: block;
  overflow-x: hidden;
  width: 100%;
  height: 100%;
  padding: 15px;
  padding-top: 50px;
}
.ThreadDetailMainFocusModalContainerTitle {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include RoobertSemiBold;
  font-size: 24px;
  line-height: 30px;
  color: black;
  margin-bottom: 10px;
  padding-right: 0px;
}
.ThreadDetailMainFocusModalContainerDetailedTitle {
  position: relative;
  display: block;
  overflow: hidden;
  float: left;
  width: 85%;
  @include RoobertSemiBold;
  font-size: 24px;
  line-height: 30px;
  color: black;
  margin-bottom: 10px;

  .TitleRight {
    position: relative;
    display: block;
    float: right;
    @include RoobertMedium;
    letter-spacing: 0.02em;
    line-height: 15px;

    .TitlePrice {
      float: right;
      font-size: 12px;
      opacity: 0.5;
    }

    .TitlePriceValue {
      font-size: 15px;
    }
  }
}
.ThreadDetailMainFocusModalContainerSubtitle {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include FkDisplayRegular;
  font-size: 16px;
  line-height: 20px;
  color: black;
  margin-bottom: 50px;
  margin-top: 15px;

  &.ThreadDetailMainFocusModalContainerSubtitleLowMargin {
    margin-bottom: 15px;
  }
}
.ThreadDetailMainFocusModalContainerFeedbackTitle {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  padding-left: 65px;
  min-height: 50px;
  padding-top: 15px;
  margin-bottom: 50px;
}

.UserBubble.ThreadDetailMainFocusModalContainerFeedbackTitlePic {
  display: block;
  overflow: hidden;
  position: absolute;
  top: 0px;
  left: 0px;
  cursor: pointer;
  width: 50px;
  height: 50px;
  font-size: 23px;
  line-height: 50px;
  border-radius: 50px;
}
.ThreadDetailMainFocusModalContainerFeedbackTitleText {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include FkDisplayRegular;
  font-size: 14px;
  line-height: 20px;
  color: black;
}

.ThreadDetailMainFocusModalContainerWarning {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include RoobertMedium;
  font-size: 16px;
  line-height: 20px;
  color: $red-error;
}
.ThreadDetailMainFocusModalContainerBtn {
  position: relative;
  display: block;
  overflow: hidden;
  width: 200px;
  padding: 10px 20px;
  @include RoobertSemiBold;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
  transition: all 0.2s;
  color: black;
  border: 1px solid black;
  background: transparent;
  text-align: center;
  margin: auto;
  margin-top: 20px;
  border-radius: 3px;
  text-decoration: none;

  &:hover {
    color: white;
    background: black;
  }
}
.ThreadDetailMainFocusModalForm {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
}
.ThreadDetailMainFocusModalFormField {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  margin-bottom: 20px;
}
.ThreadDetailMainFocusModalFormFieldTitle {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include RoobertSemiBold;
  font-size: 15px;
  line-height: 20px;
  color: black;
  margin-bottom: 5px;
}
.ThreadDetailMainFocusModalFormFieldInput {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
}
.ThreadDetailMainFocusModalChoicesSection {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  margin-bottom: 40px;
}
.ThreadDetailMainFocusModalChoicesTitle {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include RoobertSemiBold;
  font-size: 15px;
  line-height: 20px;
  color: black;
  margin-bottom: 5px;
}
.ThreadDetailMainFocusModalChoicesSubTitle {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  @include RoobertMedium;
  font-size: 16px;
  line-height: 20px;
  color: black;
  opacity: 0.5;
  margin-bottom: 15px;
}
.ThreadDetailMainFocusModalChoicesOptions {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  padding-left: 10px;
}
.ThreadDetailMainFocusModalChoicesOption {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include FkDisplayRegular;
  font-size: 15px;
  line-height: 20px;
  color: black;
  padding: 5px 10px;
  cursor: pointer;
  margin-bottom: 5px;
  border-radius: 5px;
  background: transparent;

  &:hover,
  &.ThreadDetailMainFocusModalChoicesOptionActive {
    background: #f2e8ce;
  }
}
.ThreadDetailMainFocusModalSummarize {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
}
.ThreadDetailMainFocusModalSummarizeInput {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  margin-bottom: 10px;
}
.ThreadDetailMainFocusModalSummarizeTextarea {
  padding: 10px;
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  outline: none;
  border: 1px solid #eaeaea;
  @include ApercuRegular;
  font-size: 16px;
  line-height: 20px;
  text-align: left;
  color: black;
  background: transparent;
  max-height: 60vh;
  border-radius: 3px;
}
.ThreadDetailMainFocusModalContainerBodyEditRequest
  .ThreadDetailMainFocusModalSummarizeTextarea {
  background: white;
  min-height: 100px;
}
.ThreadDetailMainFocusModalSummarizeRich {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  outline: none;
  border: 1px solid #eaeaea;
  @include ApercuRegular;
  font-size: 16px;
  line-height: 20px;
  text-align: left;
  color: black;
  background: transparent;
  border-radius: 3px;
  background: white;
}
.ThreadDetailMainFocusModalSummarizeBtn {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  @include RoobertSemiBold;
  font-size: 18px;
  line-height: 30px;
  color: white;
  text-align: center;
  background: black;
  padding: 10px 0;
  cursor: pointer;
  border-radius: 3px;
  transition: all 0.2s;

  &:hover {
    background: $green;
  }
}
.ThreadDetailMainFocusModalClose {
  position: absolute;
  display: block;
  overflow: hidden;
  @include RoobertSemiBold;
  font-size: 16px;
  color: #4e4e4e;
  background: white;
  top: 10px;
  right: 10px;
  border-radius: 5px;
  padding: 4px 10px 6px 10px;
  line-height: 20px;
  transition: all 0.2s;
  cursor: pointer;
  z-index: 2;
  border: 1px solid #4e4e4e;

  &:hover {
    background: black;
    color: white;
  }
}
.ThreadDetailMainFocusModalProject {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
}
.ThreadDetailMainFocusModalProjectSection {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  margin-bottom: 30px;
  border-top: 1px solid #cecece;
  padding: 10px 0;

  &.LastProjectSection {
    margin-bottom: 20px;
    border-bottom: 2px solid #000;
  }
}
.ThreadDetailMainFocusModalProjectSectionTitle {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  @include RoobertMedium;
  font-size: 12px;
  letter-spacing: 0.02em;
  line-height: 15px;
  color: #000;
  opacity: 0.5;
  margin-bottom: 10px;
}
.ThreadDetailMainFocusModalProjectSectionContent {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  @include ApercuLight;

  font-size: 16px;
  line-height: 20px;
  color: #000;

  &.ProjectSectionContentMedium {
    @include RoobertMedium;
  }

  &.ProjectSectionContentScrollable {
    max-height: 140px;
    overflow-y: auto;
  }
}
.ThreadDetailMainFocusModalProjectChecklist {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  margin-bottom: 20px;
  @include RoobertMedium;

  font-size: 16px;
  line-height: 20px;
  color: #000;
}
.ThreadDetailMainFocusModalProjectChecklistItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  margin-bottom: 20px;
}
.MatchSearchResultHighlight {
  background: #ffc0cb;
  padding-top: 10px;
  padding-bottom: 5px;
}
.ThreadDetailSidebarFileItem {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  margin-top: 10px;
  opacity: 0.7;
  transition: opacity 0.2s;
  background: white;
  border-radius: 5px;
}
.ThreadDetailSidebarFileItemTitle {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  font-size: 16px;
  @include RoobertMedium;
  line-height: 20px;
  padding: 10px;
  color: black;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-decoration: none;
  text-transform: lowercase;
}
.ThreadDetailSidebarFileItemArchive {
  position: absolute;
  display: none;
  overflow: hidden;
  width: 60px;
  text-align: center;
  top: 0;
  right: 0;
  background-color: white;
  color: black;
  @include RoobertBold;
  font-size: 13px;
  line-height: 20px;
  transition: all 0.2s;
  bottom: 0;
  padding: 10px 0;
  cursor: pointer;
  z-index: 2;

  &:hover {
    color: white;
    background: black;
  }
}
.ThreadDetailSidebarFileItem:hover {
  opacity: 1;
  .ThreadDetailSidebarFileItemArchive {
    display: block;
  }
}
.ThreadDetailSidebarAboutSupport {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
}
.ThreadDetailSidebarAboutSupportTitle {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include RoobertMedium;
  color: black;
  font-size: 18px;
  line-height: 25px;
  margin-bottom: 15px;
}
.ThreadDetailSidebarAboutSupportTeam {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
}
.ThreadDetailSidebarAboutSupportTeamMember {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  margin-bottom: 30px;
  padding-left: 50px;
}
.ThreadDetailSidebarAboutSupportTeamMemberPic {
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
  display: block;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('../../images/richard-pic.jpeg');
  background-size: 40px 40px;
  border-radius: 20px;

  &.ThreadDetailSidebarAboutSupportTeamMemberPicLeigh {
    background-image: url('../../images/leigh-pic.jpeg');
  }
  &.ThreadDetailSidebarAboutSupportTeamMemberPicTim {
    background-image: url('../../images/tim-pic.jpeg');
  }
  &.ThreadDetailSidebarAboutSupportTeamMemberPicPique {
    background-image: url('../../images/pique-pic.png');
  }
  &.ThreadDetailSidebarAboutSupportTeamMemberPicRobin {
    background-image: url('../../images/robin-pic.jpg');
  }
  &.ThreadDetailSidebarAboutSupportTeamMemberPicGAYLE {
    background-image: url('../../images/gayle-pic.jpeg');
  }
  &.ThreadDetailSidebarAboutSupportTeamMemberPicCHARLIE {
    background-image: url('../../images/charlie-pic.jpeg');
  }
  &.ThreadDetailSidebarAboutSupportTeamMemberPicDave {
    background-image: url('../../images/dave-pic.png');
  }
}
.ThreadDetailSidebarAboutSupportTeamMemberName {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include RoobertSemiBold;
  color: black;
  font-size: 16px;
  line-height: 20px;
}
.ThreadDetailSidebarAboutSupportTeamMemberDescription {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include ApercuLight;
  color: black;
  font-size: 14px;
  line-height: 16px;
}
.ThreadDetailSidebarAboutSection {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  padding-bottom: 30px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  margin-bottom: 30px;

  &:last-child {
    border: none;
  }
}
.ThreadDetailSidebarHumanSection {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  padding-bottom: 30px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
  margin-bottom: 30px;

  &.ThreadDetailSidebarHumanSectionPadding {
    padding: 10px;
    padding-bottom: 30px;
  }

  &:last-child {
    border: none;
  }
  .HumanBrandSidebarEditor {
    padding: 0;
  }
  .HumanBrandSidebarEditorCard {
    border-radius: 0;
    background-color: transparent;
    padding: 0;
    box-shadow: none;
    margin-bottom: 0;
  }
  .HumanBrandSidebarEditorFormInput {
    margin-bottom: 15px;
  }
}
.ThreadDetailSidebarHumanSectionTitle {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include RoobertSemiBold;
  font-size: 18px;
  line-height: 25px;
  color: black;
  margin-bottom: 5px;

  &.ThreadDetailSidebarHumanSectionTitlePadding {
    padding: 0 10px;
  }
}
.ThreadDetailSidebarHumanSectionSubTitle {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  @include RoobertMedium;
  line-height: 20px;
  font-size: 15px;
  color: black;
  // opacity: 0.75;
  margin-bottom: 10px;

  &.ThreadDetailSidebarHumanSectionSubTitlePadding {
    padding: 0 10px;
  }
}
.ThreadDetailSidebarHumanSectionValue {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  @include RoobertMedium;
  line-height: 15px;
  font-size: 13px;
  color: black;
  opacity: 0.75;
  margin-bottom: 5px;

  span {
  }
}
.ThreadDetailSidebarHumanSectionBtn {
  position: relative;
  display: block;
  overflow: hidden;
  width: 200px;
  padding: 10px 20px;
  @include RoobertSemiBold;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
  transition: all 0.2s;
  color: #3b3b3b;
  border: 1px solid #9c9c9c;
  background: transparent;
  text-align: center;
  // margin: auto;
  margin-top: 20px;
  border-radius: 3px;
  text-decoration: none;

  &:hover {
    color: white;
    background: black;
  }
}
.ThreadDetailSidebarAboutTitle {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include RoobertMedium;
  color: black;
  font-size: 18px;
  line-height: 25px;
  margin-bottom: 0px;
}
.ThreadDetailSidebarAboutSubtitle {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  @include RoobertMedium;
  line-height: 15px;
  font-size: 13px;
  color: black;
  opacity: 0.75;
  margin: 3px 0;
}
.ThreadDetailSidebarAboutDescription {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include ApercuLight;
  color: black;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 30px;
}
.ThreadDetailSidebarAboutSectionTitle {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include RoobertMedium;
  color: black;
  font-size: 14px;
  line-height: 20px;
  opacity: 0.5;
  margin-bottom: 0px;
  margin-bottom: 5px;

  &.ThreadDetailSidebarAboutSectionTitleExtraTop {
    margin-top: 15px;
  }
}
.ThreadDetailSidebarAboutWebsite {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  text-align: left;
}
.ThreadDetailSidebarAboutWebsiteLink {
  position: relative;
  display: inline-block;
  vertical-align: top;
  overflow: hidden;
  @include RoobertMedium;
  color: black;
  font-size: 16px;
  line-height: 25px;
  text-decoration: none;
  background-repeat: no-repeat;
  background-position: right 5px center;
  background-image: url('../../images/view-website-icon.png');
  background-size: 10px 10px;
  padding-right: 20px;
  transition: color 0.2s;

  &:hover {
    color: $green;
  }
}
.ThreadDetailSidebarAboutWebsiteLinkCopy {
  display: inline-block;
  vertical-align: middle;
  margin-top: 5px;
  height: 10px;
  width: 10px;
  background-repeat: no-repeat;
  background-image: url('../../images/copy-icon.png');
  background-size: 10px 10px;
  cursor: pointer;
  transition: opacity 0.2s;

  &:hover {
    opacity: 0.45;
  }
}
.ThreadDetailSidebarAboutEdit {
  position: absolute;
  display: block;
  overflow: hidden;
  top: 0px;
  right: 0px;
  @include RoobertSemiBold;
  font-size: 13px;
  line-height: 22px;
  cursor: pointer;
  transition: opacity 0.2s;
  opacity: 0.58;
  color: black;
  height: 20px;

  &:hover {
    opacity: 1;
  }
}
.ThreadDetailSidebarAboutHuman {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  margin-bottom: 25px;
  padding-right: 60px;
  min-height: 40px;
}
.ThreadDetailSidebarAboutHumanName {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include RoobertSemiBold;
  color: black;
  font-size: 16px;
  line-height: 20px;
}
.ThreadDetailSidebarAboutHumanLocation {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include RoobertMedium;
  color: black;
  font-size: 14px;
  line-height: 20px;
  opacity: 0.5;
}
.ThreadDetailSidebarAboutHumanInfo {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include RoobertMedium;
  color: black;
  font-size: 14px;
  line-height: 20px;
  opacity: 0.5;
}
.ThreadDetailSidebarAboutHumanEdit {
  display: block;
  position: absolute;
  overflow: hidden;
  top: 0px;
  right: 0px;
  @include RoobertSemiBold;
  font-size: 13px;
  color: #fff;
  background: #525252;
  border-radius: 20px;
  line-height: 15px;
  padding: 5px 10px;
  cursor: pointer;
  transition: background 0.2s;

  &:hover {
    background: black;
  }
}
.ThreadDetailSidebarAboutAdd {
  position: relative;
  display: block;
  overflow: hidden;
  width: 200px;
  padding: 10px 20px;
  @include RoobertSemiBold;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
  transition: all 0.2s;
  color: black;
  border: 1px solid black;
  background: transparent;
  text-align: center;
  margin: auto;
  border-radius: 3px;

  &:hover {
    color: white;
    background: black;
  }
}
.ThreadDetailSidebarBodyPanelEmptyWrapper {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  padding: 10px;
}
.ThreadDetailSidebarBodyPanelEmpty {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  padding: 20px;
  background: rgba(255, 255, 255, 0.4);
  border-radius: 5px;

  &.ThreadDetailSidebarBodyPanelEmptyHuman {
    background: #eee;
  }
  &.ThreadDetailSidebarBodyPanelEmptySupportCase {
    background: rgba(255, 0, 0, 0.25);
  }
}
.ThreadDetailSidebarBodyPanelEmptyTitle {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include RoobertSemiBold;
  font-size: 18px;
  line-height: 30px;
  color: black;
}
.ThreadDetailSidebarBodyPanelEmptyDescription {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include RoobertMedium;
  font-size: 14px;
  line-height: 20px;
  color: black;
}
.ThreadDetailSidebarBodyPanelEmptyCaseDesc {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include RoobertMedium;
  font-size: 14px;
  line-height: 20px;
  color: black;
  border-top: 1px solid black;
  margin-top: 10px;
  padding-top: 10px;
  white-space: pre-wrap;
}
.ThreadDetailSidebarUpNextCurrent {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
}
.ThreadDetailSidebarUpNextPast {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
}
.ThreadDetailSidebarUpNextItem {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  padding: 10px;
}
.ThreadDetailSidebarUpNextUnread {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  padding: 15px;
  padding-right: 45px;
  background: #dd5d5c;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.2s;

  &:hover {
    background: #e03c3b;
  }

  &.ThreadDetailSidebarUpNextSnooze {
    background: #9c27b0;

    &:hover {
      background: #b25ac1;
    }
  }
}
.ThreadDetailSidebarUpNextUnreadSummary {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  font-size: 14px;
  line-height: 20px;
  @include RoobertSemiBold;
  color: white;
}
.ThreadDetailSidebarUpNextUnreadClose {
  position: absolute;
  display: block;
  overflow: hidden;
  top: 15px;
  right: 15px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('../../images/global-notification-close.png');
  background-size: 14px 14px;
  height: 20px;
  width: 20px;
}
.ThreadDetailSidebarUpNextReferral {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  padding: 15px;
  background: #9a76bf;
  border-radius: 5px;
}
.ThreadDetailSidebarUpNextReferralSummary {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  font-size: 14px;
  line-height: 20px;
  @include RoobertSemiBold;
  color: white;
  padding-right: 30px;
}
.ThreadDetailSidebarUpNextReferralBtn {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  margin-top: 20px;
  cursor: pointer;
  border-radius: 5px;
  text-align: center;
  padding: 10px 0;
  @include RoobertSemiBold;
  font-size: 16px;
  line-height: 20px;
  background-color: rgba(255, 255, 255, 0.2);
  color: black;
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    background: black;
    color: white;
  }
}

.ThreadDetailSidebarUpNextReferralClose {
  position: absolute;
  display: block;
  overflow: hidden;
  top: 15px;
  right: 15px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('../../images/global-notification-close.png');
  background-size: 14px 14px;
  height: 20px;
  width: 20px;
  cursor: pointer;
  background-color: transparent;

  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
}
.ThreadDetailSidebarTagger {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  padding: 10px;
  border-radius: 8px;
  background: #fff;
  border: 2px solid #fff;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);
}
.ThreadDetailSidebarTaggerTitle {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include RoobertBold;
  font-size: 15px;
  line-height: 25px;
  letter-spacing: 0.1em;
  color: black;
  padding-right: 35px;
  text-transform: uppercase;
  opacity: 0.6;
  margin-bottom: 10px;
}
.ThreadDetailSidebarTaggerCurrent {
  position: absolute;
  display: block;
  overflow: hidden;
  top: 10px;
  right: 10px;
  width: 120px;
  height: 25px;
  border-radius: 4px;
  background: #d6f0d7;
  @include RoobertSemiBold;
  font-size: 16px;
  line-height: 25px;
  text-align: center;
  color: #000;

  &.ThreadDetailSidebarTaggerCurrentWARM {
    background-color: #f7eab4;
  }
  &.ThreadDetailSidebarTaggerCurrentCOLD {
    background-color: #e0e0e0;
  }
  &.ThreadDetailSidebarTaggerCurrentLOST {
    background-color: #efdbdb;
  }
}
.ThreadDetailSidebarTaggerSelect {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
}

.ThreadDetailSidebarTaggerSelectInput {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include ApercuRegular;
  font-size: 15px;
  line-height: 20px;
  padding: 7px 10px;
  color: black;
  background: transparent;
  border-radius: 3px;
  outline: none;
  border: 1px solid #eaeaea;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-repeat: no-repeat;
  background-position: center right 10px;
  background-image: url('../../images/arrow-down-grey.png');
  background-size: 14px 8px;
}
.ThreadDetailSidebarUpNextCard {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);
  transition: background 0.2s;
  text-decoration: none;

  &:hover {
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2);
  }
}
.ThreadDetailSidebarUpNextCardHeader {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  padding: 15px;
  cursor: pointer;
  min-height: 75px;
  text-decoration: none;
}
.ThreadDetailSidebarUpNextCard {
  &.ThreadDetailSidebarUpNextCardMeeting {
    background: #d0e6e5;
    .ThreadDetailSidebarUpNextCardHeader {
      padding-bottom: 40px;
    }
    .ThreadDetailSidebarUpNextCardType {
      background-image: url('../../images/meeting-card-icon.png');
      background-repeat: no-repeat;
      background-size: 24px 24px;
      background-position: center right;
    }
  }
  &.ThreadDetailSidebarUpNextCardProject {
    background: white;
    .ThreadDetailSidebarUpNextCardHeader {
      padding-bottom: 20px;
    }
    .ThreadDetailSidebarUpNextCardType {
      background-image: url('../../images/project-card-icon.png');
      background-repeat: no-repeat;
      background-size: 24px 24px;
      background-position: center right;
    }
  }
  &.ThreadDetailSidebarUpNextCardDraftEmail {
    background: #f8f6ed;
    .ThreadDetailSidebarUpNextCardType {
      background-image: url('../../images/email-card-icon.png');
      background-repeat: no-repeat;
      background-size: 24px 24px;
      background-position: center right;
    }
  }
  &.ThreadDetailSidebarUpNextCardDraftText {
    background: #f8f6ed;
    .ThreadDetailSidebarUpNextCardType {
      background-image: url('../../images/text-card-icon.png');
      background-repeat: no-repeat;
      background-size: 24px 24px;
      background-position: center right;
    }
  }
  &.ThreadDetailSidebarUpNextCardDraftQuote {
    background: #9dc4a2;
    .ThreadDetailSidebarUpNextCardType {
      background-image: url('../../images/project-card-icon.png');
      background-repeat: no-repeat;
      background-size: 24px 24px;
      background-position: center right;
    }
  }
  &.ThreadDetailSidebarUpNextCardLead {
    // background: #d0cfde;
    .ThreadDetailSidebarUpNextCardHeader {
      padding-bottom: 40px;
    }
    .ThreadDetailSidebarUpNextCardType {
      background-image: url('../../images/lead-card-icon.png');
      background-repeat: no-repeat;
      background-size: 24px 24px;
      background-position: center right;
    }
  }
}
.ThreadDetailSidebarUpNextCardType {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include RoobertBold;
  font-size: 15px;
  line-height: 25px;
  letter-spacing: 0.1em;
  color: black;
  padding-right: 35px;
  text-transform: uppercase;
  opacity: 0.6;
}
.ThreadDetailSidebarUpNextCardTitle {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include RoobertSemiBold;
  font-size: 20px;
  line-height: 25px;
  color: black;
  margin-bottom: 5px;
  padding-right: 35px;
}
.ThreadDetailSidebarUpNextCardSubtitle {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include RoobertRegular;
  font-size: 15px;
  line-height: 20px;
  color: black;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-right: 110px;
}
.ThreadDetailSidebarUpNextCardTopRightLinkOut {
  position: absolute;
  display: none;
  overflow: hidden;
  top: 15px;
  right: 15px;
  width: 15px;
  height: 15px;
  background-image: url('../../images/view-website-icon.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 13px 13px;
}
.ThreadDetailSidebarUpNextCardHeader:hover {
  .ThreadDetailSidebarUpNextCardTopRightLinkOut {
    display: block;
  }
}
.ThreadDetailSidebarUpNextCardStatusLine {
  position: absolute;
  display: block;
  overflow: hidden;
  height: 3px;
  width: 40px;
  border-radius: 5px;
  bottom: 37px;
  right: 15px;
}
.ThreadDetailSidebarUpNextCardStatus {
  position: absolute;
  display: block;
  overflow: hidden;
  @include FkDisplayRegular;
  bottom: 15px;
  right: 15px;
  font-size: 13px;
  line-height: 20px;
  color: black;
}
.ThreadDetailSidebarUpNextCardMiddle {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  background: #fff481;
  padding: 10px;
  color: black;
  @include RoobertMedium;
  font-size: 13px;
  line-height: 15px;
  cursor: pointer;
  text-align: center;
}
.ThreadDetailSidebarUpNextCardExpanded {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  border-top: 1px solid #e3e3e3;
}
.ThreadDetailSidebarUpNextCardActions {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  padding: 10px 15px;
}
.ThreadDetailSidebarUpNextCardAction {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  border-radius: 3px;
  @include RoobertSemiBold;
  font-size: 16px;
  line-height: 20px;
  padding: 15px 0;
  text-align: center;
  color: #000;
  background: #e3e3e3;
  margin-top: 5px;
  margin-bottom: 5px;
  cursor: pointer;
  transition: all 0.2s;

  &.ThreadDetailSidebarUpNextCardActionGood {
    background: #02071c;
    color: white;

    &:not(.ThreadDetailMainFocusModalChecklistActionInvalid):hover {
      background: #27325d;
    }
  }
  &.ThreadDetailSidebarUpNextCardActionNeutral {
    color: #000;
    background: #e3e3e3;
    &:not(.ThreadDetailMainFocusModalChecklistActionInvalid):hover {
      background: #d3d3d3;
    }
  }
  &.ThreadDetailSidebarUpNextCardActionBorder {
    color: #000;
    background: transparent;
    border: 1px solid #a7a7a7;
    &:not(.ThreadDetailMainFocusModalChecklistActionInvalid):hover {
      background: #eaeaea;
    }
  }
  &.ThreadDetailSidebarUpNextCardActionBad {
    background: #dd5d5c;
    color: white;
    &:not(.ThreadDetailMainFocusModalChecklistActionInvalid):hover {
      background: #e03c3b;
    }
  }
  &.ThreadDetailMainFocusModalChecklistAction {
    width: 200px;
    margin: auto;
  }
  &.ThreadDetailMainFocusModalChecklistActionInvalid {
    opacity: 0.5;
  }
}
.ThreadDetailSidebarUpNextCardDetails {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  padding: 15px;
}
.ThreadDetailSidebarUpNextCardDetail {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  margin-bottom: 5px;
}
.ThreadDetailSidebarUpNextCardDetailTitle {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  font-size: 14px;
  line-height: 20px;
  @include RoobertMedium;
  color: black;
}
.ThreadDetailSidebarUpNextCardDetailValue {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  font-size: 14px;
  line-height: 20px;
  @include RoobertMedium;
  color: black;
  opacity: 0.5;
  white-space: pre-wrap;
}
.ThreadDetailSidebarUpNextItemFooter {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include RoobertMedium;
  font-size: 14px;
  line-height: 20px;
  color: #000;
  opacity: 0.8;
  padding: 5px 10px 0;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ThreadDetailSidebarUpNextItemFooterRight {
  position: absolute;
  display: block;
  overflow: hidden;
  top: 0;
  right: 0;
  @include RoobertMedium;
  font-size: 14px;
  line-height: 20px;
  color: #000;
  opacity: 0.5;
  padding: 5px 10px 0;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
}
.ThreadDetailSidebarUpNextExpandPast {
  position: relative;
  display: block;
  overflow: hidden;
  float: right;
  clear: both;
  width: 110px;
  height: 30px;
  background-image: url('../../images/arrow-down-black.png');
  background-repeat: no-repeat;
  background-position: left 5px center;
  background-size: 16px 9px;
  transition: background-color 0.2s;
  border-radius: 3px;
  padding: 5px;
  padding-left: 30px;
  @include RoobertSemiBold;
  font-size: 15px;
  text-align: right;
  line-height: 20px;
  color: black;
  opacity: 0.75;
  margin-right: 10px;
  cursor: pointer;

  &:hover {
    background-color: rgba(255, 255, 255, 0.4);
    opacity: 1;
  }
}
.ThreadDetailSidebarUpNextPastDivide {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  padding: 10px 50px 10px 10px;
  margin-bottom: 10px;
  margin-top: 10px;
}
.ThreadDetailSidebarUpNextPastDivideLine {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  border-top: 1px solid black;
  opacity: 0.5;
}
.ThreadDetailSidebarUpNextPastDivideText {
  position: absolute;
  display: block;
  overflow: hidden;
  top: 0px;
  right: 10px;
  @include RoobertSemiBold;
  font-size: 15px;
  text-align: right;
  line-height: 20px;
  color: black;
  opacity: 0.5;
}
@media only screen and (min-width: 375px) {
  .ThreadDetailSidebar {
    border-left: 5px solid white;
    width: 325px;
    right: -325px;
  }
  .ThreadDetailView.ThreadDetailViewWithSidebar {
    .ThreadDetailSidebar {
      right: 0;
    }
  }
}
@media only screen and (min-width: 500px) {
  .ThreadDetailActions {
    right: 15px;
    bottom: 15px;
  }
  .ThreadCurrentAction {
    padding-right: 60px;
    padding-bottom: 10px;
  }
  .ThreadDetailMainFocusModalContainer {
    width: 90%;
    height: 90%;
    top: 5%;
    left: 5%;
    right: 5%;
    max-height: 600px;
  }
}
@media only screen and (min-width: 640px) {
  .ThreadDetailMain {
    position: absolute;
    width: unset;
    top: 0;
    left: 0;
    right: 320px;
    bottom: 0px;

    &.ThreadDetailMainFull {
      right: 0;
    }
  }
  .ThreadDetailActions {
    right: 10px;
    bottom: 10px;
  }
  .ThreadCurrentAction {
    padding-right: 5px;
    padding-bottom: 55px;
  }
  .ThreadDetailMainBodyCover {
    display: none;
  }
  .ThreadDetailSidebar {
    border: none;
    width: 320px;
    right: 0;
    background: transparent;

    &.ThreadDetailSidebarSolo {
      padding-top: 0;

      .ThreadDetailSidebarHeader {
        display: none;
      }
    }
  }
  .SupportChannelDetailModal .ThreadDetailSidebar {
    background: transparent;
  }
  .AdminExpertDetailModal .ThreadDetailSidebar {
    background-color: transparent;
  }
  .AdminMatchDetailModal .ThreadDetailSidebar {
    background-color: transparent;
  }
  .ThreadDetailView.ThreadDetailViewWithSidebar {
    .ThreadDetailMainBodyCover {
      display: none;
    }
    .ThreadDetailSidebar {
      right: 0;
    }
  }
  .ThreadDetailMainHeaderToggle {
    display: none;
  }
  .ThreadDetailSidebarHeader {
    padding: 10px;
  }
  .ThreadDetailSidebarHeaderBack {
    display: none;
  }
  .ThreadDetailSidebarHeaderSearch {
    padding-left: 10px;
    width: 50px;
  }
}

@media only screen and (min-width: 750px) {
  .ThreadDetailMainHeader {
    padding-left: 45px;
  }
  .ThreadDetailMainHeaderPin {
    display: inline-block;
  }
  .ThreadDetailActions {
    right: 15px;
    bottom: 15px;
  }
  .ThreadCurrentAction {
    padding-right: 60px;
    padding-bottom: 10px;
  }
  .ThreadDetailMainFocusModalContainerBody:not(
      .ThreadDetailMainFocusModalContainerBodySearchResults
    ) {
    padding: 30px;
  }
  .ThreadDetailMainFocusModalContainerTitle {
    padding-right: 50px;
  }
  .ThreadDetailMainFocusModalSummarizeTextarea {
    padding: 15px;
  }
}
@media only screen and (min-width: 1000px) {
  .ThreadDetailMainBody {
    .ThreadEvents {
      padding: 40px 55px;
    }
  }
  .ThreadDetailActions {
    right: 0px;
    bottom: 10px;
  }
  .ThreadCurrentAction {
    padding-right: 110px;
    padding-bottom: 10px;
  }
  .ThreadDetailActionTrigger {
    background: transparent;
    box-shadow: none;
  }
  .ThreadDetailActionBtn {
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.26);
    margin-right: 0px;
    background: white;
  }
  .ThreadDetailActionText {
    display: inline-block;
  }
  .ThreadDetailActionTrigger:hover {
    background: white;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.26);
    .ThreadDetailActionBtn {
      color: black;
      box-shadow: none;
      background: white;
    }
    .ThreadDetailActionText {
      color: black;
    }
  }
  .ThreadDetailActions.ThreadDetailActionsOpen .ThreadDetailActionTrigger {
    background: black;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.26);

    .ThreadDetailActionBtn {
      background: black;
      color: white;
      transform: rotate(45deg);
    }
    .ThreadDetailActionText {
      color: white;
    }
  }
  .ThreadDetailActions.ThreadDetailActionsCurrent .ThreadDetailActionTrigger {
    background: transparent;
    // box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.26);

    .ThreadDetailActionBtn {
      background: black;
      color: white;
      transform: rotate(45deg);
    }
    .ThreadDetailActionText {
      color: black;
    }

    &:hover {
      background: black;
      box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.26);
      .ThreadDetailActionText {
        color: white;
      }
    }
  }
  .ThreadActionQuoteTipWrapper {
    padding-left: 50px;
  }
}
@media only screen and (min-width: 1020px) {
  .ThreadDetailActionItem {
    @include RoobertMedium;

    span {
      display: unset;
    }
  }
}
@media only screen and (min-width: 1150px) {
  .ThreadDetailMainBody {
    .ThreadEvents {
      padding-left: 60px;
      padding-right: 80px;
    }
  }
}
