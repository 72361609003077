@import '../Common';

.Dashboard.ExpertDashboardHome,
.Dashboard.HumanDashboardHome {
  .DashboardNavIntro {
    color: white;
  }
  .DashboardNavHeaderLogo {
    &.DashboardNavHeaderLogoWhite {
      display: block;
    }
    &.DashboardNavHeaderLogoBlack {
      display: none;
    }
  }
  .DashboardNavLinkPointer {
    border: 1px solid white;
    border-top: none;
    border-right: none;
  }
  .UserBubble.DashboardNavHeaderProfile {
    background-color: white;
    color: black;
  }
  .DashboardNavLinkText {
    color: white;
  }
  .DashboardNavLinkSuper {
    color: white;
  }
  .DashboardNavFooterLink {
    color: white;
  }
  .DashboardSectionEmpty {
    color: white;
  }
}

@media only screen and (min-width: $base-sidebar-breakpoint) {
  .Dashboard.ExpertDashboardHome,
  .Dashboard.HumanDashboardHome {
    .DashboardNavLinkSuper {
      color: black;
    }
  }
}

.HomeScreen {
  .DashboardSectionHeader {
    color: white;
  }
  .HomeScreenAnnouncementFooterText {
    color: white;
  }
}
.HomeScreenContent {
}
.HomeScreenAnnouncement {
  position: relative;
  display: inline-block;
  // overflow: hidden;
  margin-right: 10px;
  width: 150px;
  white-space: normal;
  padding-top: 5px;

  &.HomeScreenAnnouncementWide {
    width: 75%;
    max-width: 250px;
  }
  &.HomeScreenAnnouncementThin {
    width: 25%;
    min-width: 125px;
    max-width: 150px;
  }
  &.HomeScreenAnnouncementMedium {
    width: 50%;
    max-width: 175px;
  }
}
.HomeScreenAnnouncementBody {
  position: relative;
  display: block;
  overflow: hidden;
  height: 150px;
  width: 100%;
  border-radius: 5px;
  background: #def0df;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  text-align: left;
  margin-bottom: 5px;
  text-decoration: none;
  cursor: pointer;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}
.HomeScreenAnnouncement {
  &.HomeScreenAnnouncementItem-intro-st {
    .HomeScreenAnnouncementBody {
      background-image: url('../../images/home-announcement-intro-st.png');
    }
  }
  &.HomeScreenAnnouncementItem-meet-dashboard {
    .HomeScreenAnnouncementBody {
      background-image: url('../../images/home-announcement-meet-dashboard.png');
    }
  }
  &.HomeScreenAnnouncementItem-expert-profile {
    .HomeScreenAnnouncementBody {
      background-image: url('../../images/home-announcement-expert-profile.png');
    }
  }
  &.HomeScreenAnnouncementItem-featured-expert {
    .HomeScreenAnnouncementBody {
      background-image: url('../../images/home-announcement-featured-expert.png');
    }
  }
  &.HomeScreenAnnouncementItem-managing-launch {
    .HomeScreenAnnouncementBody {
      background-image: url('../../images/home-announcement-managing-launch.png');
    }
  }
  &.HomeScreenAnnouncementItem-bug-tracker {
    .HomeScreenAnnouncementBody {
      background-image: url('../../images/home-announcement-bug-tracker.png');
    }
  }
  &.HomeScreenAnnouncementItem-inbound-guide {
    .HomeScreenAnnouncementBody {
      background-image: url('../../images/home-announcement-inbound-guide.png');
    }
  }
  &.HomeScreenAnnouncementItem-knowledge-base {
    .HomeScreenAnnouncementBody {
      background-image: url('../../images/home-announcement-knowledge-base.png');
    }
  }
  &.HomeScreenAnnouncementItem-intro-bills {
    .HomeScreenAnnouncementBody {
      background-image: url('../../images/home-announcement-intro-bills.png');
    }
  }
  &.HomeScreenAnnouncementItem-expert-cut {
    .HomeScreenAnnouncementBody {
      background-image: url('../../images/home-announcement-expert-cut.png');
    }
  }
  &.HomeScreenAnnouncementItem-quote-calc {
    .HomeScreenAnnouncementBody {
      background-image: url('../../images/home-announcement-quote-calc.png');
    }
  }
  &.HomeScreenAnnouncementItem-sales-playbook {
    .HomeScreenAnnouncementBody {
      background-image: url('../../images/home-announcement-sales-playbook.png');
    }
  }
  &.HomeScreenAnnouncementItem-byo-clients {
    .HomeScreenAnnouncementBody {
      background-image: url('../../images/home-announcement-byo-clients.png');
    }
  }
  &.HomeScreenAnnouncementItem-love-retainers {
    .HomeScreenAnnouncementBody {
      background-image: url('../../images/home-announcement-love-retainers.png');
    }
  }
  &.HomeScreenAnnouncementItem-refer-experts {
    .HomeScreenAnnouncementBody {
      background-image: url('../../images/home-announcement-refer-experts.png');
    }
  }
  &.HomeScreenAnnouncementItem-lagatta-story {
    .HomeScreenAnnouncementBody {
      background-image: url('../../images/home-announcement-lagatta-story.png');
    }
  }
  &.HomeScreenAnnouncementItem-caley-friend {
    .HomeScreenAnnouncementBody {
      background-image: url('../../images/home-announcement-caley-friend.png');
    }
  }
  &.HomeScreenAnnouncementItem-retainer-upgrade {
    .HomeScreenAnnouncementBody {
      background-image: url('../../images/home-announcement-retainer-upgrade.png');
    }
  }
  &.HomeScreenAnnouncementItem-connor-spotlight {
    .HomeScreenAnnouncementBody {
      background-image: url('../../images/home-announcement-connor-spotlight.png');
    }
  }
  &.HomeScreenAnnouncementItem-st-pro {
    .HomeScreenAnnouncementBody {
      background-image: url('../../images/home-announcement-st-pro.png');
    }
  }
  &.HomeScreenAnnouncementItem-brielle-spotlight {
    .HomeScreenAnnouncementBody {
      background-image: url('../../images/home-announcement-brielle-spotlight.png');
    }
  }
  &.HomeScreenAnnouncementItem-refer-friend {
    .HomeScreenAnnouncementBody {
      background-image: url('../../images/home-announcement-refer-friend.png');
    }
  }
  &.HomeScreenAnnouncementItem-sarah-friend {
    .HomeScreenAnnouncementBody {
      background-image: url('../../images/home-announcement-sarah-friend.png');
    }
  }
  &.HomeScreenAnnouncementItem-native-story {
    .HomeScreenAnnouncementBody {
      background-image: url('../../images/home-announcement-native-story.png');
    }
  }
}
.HomeScreenAnnouncementTitle {
  font-size: 16px;
  line-height: 20px;
  left: 10px;
  bottom: 10px;
  right: 20px;
  color: white;
  position: absolute;
  @include RoobertMedium;
}
.HomeScreenAnnouncementFooter {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  text-align: left;
  padding: 0 5px;
}
.HomeScreenAnnouncementFooterText {
  font-size: 12px;
  line-height: 15px;
  display: block;
  overflow: hidden;
  position: relative;
  @include RoobertMedium;
  color: white;
  opacity: 0.5;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.HomeScreenUpNext {
  width: 220px;
  position: relative;
  display: inline-block;
  // overflow: hidden;
  margin-right: 10px;
  white-space: normal;
  padding-top: 10px;
}
.HomeScreenUpNextUnreadTag {
  position: absolute;
  top: 0px;
  right: 10px;
  display: inline-block;
  vertical-align: top;
  overflow: hidden;
  line-height: 20px;
  padding: 0 15px;
  @include RoobertSemiBold;
  font-size: 14px;
  letter-spacing: 0.04em;
  color: black;
  background: #94f5f8;
  letter-spacing: 2px;
  z-index: 2;
  border-radius: 2px;
}
.HomeScreenUpNextBody {
  height: 120px;
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  border-radius: 5px;
  background: white;
  text-align: left;
  color: black;
  margin-bottom: 5px;
  padding: 15px;
  text-decoration: none;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}
.HomeScreenUpNextTitle {
  font-size: 16px;
  line-height: 20px;
  max-height: 40px;
  position: relative;
  display: block;
  overflow: hidden;
  @include RoobertMedium;
}
.HomeScreenUpNext {
  &.HomeScreenUpNextAlerts {
    .HomeScreenUpNextBody {
      background-color: #fcee9b;
      background-image: url('../../images/home-unread-bg.png');
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
    }
    .HomeScreenUpNextTitle {
      width: 75%;
    }
  }
  &.HomeScreenUpNextMeeting {
    .HomeScreenUpNextBody {
      background: #d0cfde;
    }
    .HomeScreenUpNextTitle {
      padding-right: 30px;
      background-image: url('../../images/meeting-card-icon.png');
      background-repeat: no-repeat;
      background-size: 20px 20px;
      background-position: top right;
    }
  }
  &.HomeScreenUpNextLead {
    .HomeScreenUpNextBody {
      background: #f0ece2;
    }
    .HomeScreenUpNextTitle {
      padding-right: 30px;
      background-image: url('../../images/lead-card-icon.png');
      background-repeat: no-repeat;
      background-size: 20px 20px;
      background-position: top right;
    }
  }
  &.HomeScreenUpNextProject {
    .HomeScreenUpNextBody {
      background: #e5fffb;
    }
    .HomeScreenUpNextTitle {
      padding-right: 30px;
      background-image: url('../../images/project-card-icon.png');
      background-repeat: no-repeat;
      background-size: 20px 20px;
      background-position: top right;
    }
  }
  &.HomeScreenUpNextQueue {
    .HomeScreenUpNextBody {
      background-color: #ebdcdc;
    }
  }
  &.HomeScreenUpNextDangerZone {
    .HomeScreenUpNextBody {
      background-color: #eca7a7;
    }
  }
  &.HomeScreenUpNextDangerZoneProjects {
    .HomeScreenUpNextBody {
      background-color: #c7a7ff;
    }
  }
  &.HomeScreenUpNextCalendar {
    .HomeScreenUpNextBody {
      background-color: #ffc56d;
    }
  }
}
.HomeScreenUpNextSubTitle {
  font-size: 11px;
  line-height: 15px;
  @include RoobertMedium;
  position: relative;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  opacity: 0.5;

  &.HomeScreenUpNextSubTitleNoEllipsis {
    white-space: unset;
    text-overflow: unset;
  }
}
.HomeScreenUpNextLeftCorner {
  @include FkDisplayRegular;
  font-size: 12px;
  line-height: 15px;
  position: absolute;
  display: block;
  overflow: hidden;
  bottom: 10px;
  right: 60%;
  left: 15px;
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.HomeScreenUpNextStatusLine {
  position: absolute;
  display: block;
  overflow: hidden;
  height: 3px;
  width: 40px;
  border-radius: 5px;
  bottom: 32px;
  right: 15px;
}
.HomeScreenUpNextRightCorner {
  @include FkDisplayRegular;
  font-size: 12px;
  line-height: 15px;
  position: absolute;
  display: block;
  overflow: hidden;
  bottom: 10px;
  right: 15px;
  left: 40%;
  text-align: right;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.HomeScreenUpNextFooter {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  text-align: left;
  padding: 0 5px;
}
.HomeScreenUpNextFooterText {
  font-size: 12px;
  line-height: 15px;
  display: block;
  overflow: hidden;
  position: relative;
  @include RoobertMedium;
  color: white;
  opacity: 0.5;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@media only screen and (min-width: 600px) {
  .HomeScreenAnnouncement {
    width: 175px;
    white-space: normal;

    &.HomeScreenAnnouncementWide {
      max-width: 275px;
    }
    &.HomeScreenAnnouncementThin {
    }
    &.HomeScreenAnnouncementMedium {
      max-width: 200px;
    }
  }
  .HomeScreenAnnouncementBody {
    height: 175px;
  }
  .HomeScreenAnnouncementTitle {
    font-size: 18px;
    line-height: 20px;
  }
}
@media only screen and (min-width: $base-sidebar-breakpoint) {
  .HomeScreenAnnouncement {
    width: 200px;
    white-space: normal;

    &.HomeScreenAnnouncementWide {
      width: 300px;
      max-width: 300px;
    }
    &.HomeScreenAnnouncementThin {
      width: 175px;
      max-width: 175px;
    }
    &.HomeScreenAnnouncementMedium {
      max-width: 250px;
    }
  }
  .HomeScreenAnnouncementBody {
    height: 200px;
  }
  .HomeScreenAnnouncementTitle {
    left: 15px;
    bottom: 15px;
    font-size: 20px;
    line-height: 25px;
  }
  .HomeScreenAnnouncementFooter {
    padding: 0 10px;
  }
  .HomeScreenAnnouncementFooterText {
    @include RoobertBold;
    font-size: 14px;
    line-height: 20px;
  }
  .HomeScreenAnnouncementFooterUnread {
    height: 20px;
  }
  .HomeScreenUpNext {
    width: 260px;
  }
  .HomeScreenUpNextBody {
    height: 130px;
  }
  .HomeScreenUpNextTitle {
    font-size: 18px;
    line-height: 24px;
    max-height: 48px;
  }
  .HomeScreenUpNext {
    &.HomeScreenUpNextMeeting {
      .HomeScreenUpNextTitle {
        padding-right: 35px;
        background-size: 24px 24px;
      }
    }
    &.HomeScreenUpNextLead {
      .HomeScreenUpNextTitle {
        padding-right: 35px;
        background-size: 24px 24px;
      }
    }
    &.HomeScreenUpNextProject {
      .HomeScreenUpNextTitle {
        padding-right: 35px;
        background-size: 24px 24px;
      }
    }
  }
  .HomeScreenUpNextSubTitle {
    font-size: 12px;
  }
  .HomeScreenUpNextLeftCorner {
    font-size: 13px;
  }
  .HomeScreenUpNextRightCorner {
    font-size: 13px;
  }
  .HomeScreenUpNextFooter {
    padding: 0 10px;
  }
  .HomeScreenUpNextFooterText {
    @include RoobertBold;
    font-size: 13px;
    line-height: 17px;
  }
}
