@import '../Common';

.QuoteAttachedThreadEventWrapper {
  display: block;
  position: relative;
  width: 100%;
}
.QuoteAttachedThreadEvent {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 5px;
  background-color: black;
  border-radius: 0px;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);
}
.QuoteAttachedThreadEventTop {
  padding: 10px 10px;
  display: block;
  position: relative;
  width: 100%;
  overflow: hidden;
  padding-right: 100px;
  cursor: pointer;
}
.QuoteAttachedThreadEventTopLeft {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  text-align: left;
}
.QuoteAttachedThreadEventTopRight {
  right: 10px;
  position: absolute;
  display: block;
  overflow: hidden;
  text-align: right;
  width: 70px;
  top: 10px;
}
.QuoteAttachedThreadEventTopTitle {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include RoobertMedium;
  font-size: 12px;
  line-height: 20px;
  color: white;
  opacity: 0.4;
}
.QuoteAttachedThreadEventTopText {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include FkDisplayRegular;
  font-size: 16px;
  line-height: 20px;
  color: white;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.QuoteAttachedThreadEventMain {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
}
.QuoteAttachedThreadEventBody {
  padding: 10px 10px;
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  border-top: 1px solid rgba(255, 255, 255, 0.24);
}
.QuoteAttachedThreadEventBodyContent {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include ApercuLight;
  font-size: 16px;
  line-height: 20px;
  color: white;
}
.QuoteAttachedThreadEventFooter {
  padding: 10px 10px;
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  border-top: 1px solid rgba(255, 255, 255, 0.24);
}
.QuoteAttachedThreadEventFooterTitle {
  position: relative;
  display: block;
  overflow: hidden;
  @include RoobertMedium;
  font-size: 12px;
  line-height: 20px;
  color: white;
  opacity: 0.4;
}
.QuoteAttachedThreadEventFooterText {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include FkDisplayRegular;
  font-size: 16px;
  line-height: 20px;
  color: white;
  min-height: 20px;
}
.QuoteAttachedThreadEventRevisions {
  padding: 10px 10px;
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  border-top: 1px solid rgba(255, 255, 255, 0.24);
}
.QuoteAttachedThreadEventRevisionsTitle {
  position: relative;
  display: block;
  overflow: hidden;
  @include RoobertMedium;
  font-size: 12px;
  line-height: 20px;
  color: white;
  opacity: 0.4;
}
.QuoteAttachedThreadEventRevisionsContent {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include ApercuLight;
  font-size: 16px;
  line-height: 20px;
  color: white;
}

.QuoteAttachedThreadEvent.QuoteAttachedThreadEventExpanded {
  background-color: white;

  .QuoteAttachedThreadEventTopTitle {
    color: black;
  }
  .QuoteAttachedThreadEventTopText {
    color: black;
  }
  .QuoteAttachedThreadEventBodyContent {
    color: black;
  }
  .QuoteAttachedThreadEventFooterTitle {
    color: black;
  }
  .QuoteAttachedThreadEventFooterText {
    color: black;
  }
  .QuoteAttachedThreadEventRevisionsTitle {
    color: black;
  }
  .QuoteAttachedThreadEventRevisionsContent {
    color: black;
  }
  .QuoteAttachedThreadEventBody {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }
  .QuoteAttachedThreadEventFooter {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }
  .QuoteAttachedThreadEventRevisions {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }
}

@media only screen and (min-width: 1000px) {
  .QuoteAttachedThreadEventTop {
    padding: 10px 20px;
    padding-right: 100px;
  }
  .QuoteAttachedThreadEventTopRight {
    right: 20px;
  }
  .QuoteAttachedThreadEventBody {
    padding: 10px 20px;
  }
  .QuoteAttachedThreadEventFooter {
    padding: 10px 20px;
    padding-left: 85px;
  }
  .QuoteAttachedThreadEventFooterTitle {
    position: absolute;
    top: 10px;
    left: 20px;
  }
  .QuoteAttachedThreadEventRevisions {
    padding: 10px 15px;
  }
}
