@import '../Common';

.HumanAccountViewScreenContent {
  max-width: 700px;
  margin-bottom: 100px;
}

.SettingsPaymentEdit {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  max-width: 420px;
  margin-top: 15px;
}

.SettingsPaymentEditAction {
  overflow: hidden;
  position: relative;
  display: inline-block;
  float: right;
  vertical-align: top;
  @include RoobertSemiBold;
  font-size: 17px;
  line-height: 20px;
  color: white;
  background: black;
  cursor: pointer;
  border-radius: 5px;
  padding: 10px 20px;
  margin-top: 5px;
  transition: background 0.2s, color 0.2s;

  &:hover {
    color: black;
    background: #a2f4c2;
  }

  &.SettingsPaymentEditActionLoading {
    pointer-events: none;
    background: none;
    background-color: #a2f4c2;
    color: transparent;
    user-select: none;

    &::after {
      animation: spining 500ms infinite linear;
      border: 3px solid black;
      border-radius: 20px;
      border-right-color: transparent;
      border-top-color: transparent;
      content: '';
      display: block;
      height: 19px;
      width: 19px;
      left: 0;
      right: 0;
      margin: auto;
      top: 13px;
      bottom: 13px;
      position: absolute;
      text-align: center;
    }
  }
}
.SettingsPaymentEditActionInstead {
  overflow: hidden;
  position: relative;
  display: inline-block;
  float: right;
  vertical-align: top;
  @include RoobertMedium;
  font-size: 14px;
  line-height: 20px;
  padding: 10px;
  margin-top: 5px;
  margin-left: 10px;
  color: black;
  cursor: pointer;
  opacity: 0.5;
  transition: all 0.2s;

  &:hover {
    opacity: 1;
  }
}

.SettingsBrand {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  max-width: 420px;
  border-bottom: 1px solid #eee;
  margin-bottom: 5px;

  &:last-child {
    border: none;
  }

  &.SettingsBrandEdit {
    max-width: unset;
  }
}
.SettingsBrandCard {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  padding: 15px 0px;
  padding-right: 75px;
  min-height: 70px;
}
.SettingsBrandCardName {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  @include RoobertSemiBold;
  font-size: 16px;
  line-height: 20px;
  color: black;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.SettingsBrandCardUrl {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  @include RoobertMedium;
  font-size: 14px;
  line-height: 20px;
  color: black;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-transform: lowercase;
  opacity: 0.5;
}
.SettingsBrandCardEdit {
  display: block;
  overflow: hidden;
  position: absolute;
  top: 20px;
  right: 10px;
  @include RoobertMedium;
  font-size: 16px;
  line-height: 20px;
  padding: 5px;
  opacity: 0.5;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
}

@media only screen and (min-width: 940px) {
}
