@import '../Common';

.UserBubble {
  display: block;
  overflow: hidden;
  position: relative;
  width: 30px;
  height: 30px;
  border-radius: 20px;
  border: none;
  color: white;
  background-color: black;
  @include RoobertSemiBold;
  font-size: 12px;
  line-height: 30px;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;

  img {
    display: block;
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 100%;
  }

  &.UserBubbleFallback {
    width: unset;
    padding: 0 10px;
    max-width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-transform: none;
  }

  &.UserBubbleError {
    background-color: $red-error;
  }
}
