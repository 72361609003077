@import '../Common';

.QuoteEditor {
}
.QuoteEditor .ThreadEditorRowTitle {
  width: 40px;
}
.QuoteEditor .ThreadEditorCard {
  border-radius: 0px;
}
.QuoteEditor .ThreadEditorActionBtn.ThreadEditorActionSend {
  border-radius: 20px;
}
@media only screen and (min-width: 375px) {
}
@media only screen and (min-width: 500px) {
}
@media only screen and (min-width: 640px) {
}
@media only screen and (min-width: 750px) {
}
@media only screen and (min-width: 1000px) {
  .QuoteEditor .ThreadEditorRow {
    padding-left: 50px;
  }
  .QuoteEditor .ThreadEditorCardWrapper {
    padding-left: 50px;
  }
}
@media only screen and (min-width: 1150px) {
}
