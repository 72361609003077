@import '../Common';

.MessageHqEditor {
}
.MessageHqEditor .ThreadEditorRowTitle {
  width: 30px;
}
.MessageHqEditor .ThreadEditorCard {
  border-radius: 10px;
}
.MessageHqEditorWrap {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  padding: 15px;
}
.MessageHqEditorTextarea {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  outline: none;
  border: none;
  @include ApercuLight;
  font-size: 16px;
  line-height: 20px;
  text-align: left;
  color: black;
  background: transparent;
  padding: 0;
  max-height: 60vh;
}

.MessageHqEditor .ThreadEditorActionBtn.ThreadEditorActionSend {
  border-radius: 20px;
}
@media only screen and (min-width: 375px) {
}
@media only screen and (min-width: 500px) {
}
@media only screen and (min-width: 640px) {
}
@media only screen and (min-width: 750px) {
}
@media only screen and (min-width: 1000px) {
  .MessageHqEditor .ThreadEditorRow {
    padding-left: 40px;
  }
  .MessageHqEditor .ThreadEditorCardWrapper {
    padding-left: 40px;
  }
}
@media only screen and (min-width: 1150px) {
}
