@import '../Common';

// .InboundScreen {
// }
.InboundScreenContent {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  // padding: 0 10px;
}
.InboundListColumn {
  z-index: 3;
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  padding: 0 10px;
}
.InboundListContainer {
  display: block;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  background: #e1ded9;
  border-radius: 3px;
  padding: 5px 10px;
  max-width: 500px;
  margin: auto;
  min-height: 400px;
  margin-bottom: 40px;
}
.InboundListContainerEmpty {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  border-radius: 3px;
  background: white;
  padding: 15px;
  margin-top: 5px;
  margin-bottom: 5px;
}
.InboundListContainerEmptyHeader {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  @include RoobertSemiBold;
  font-size: 20px;
  line-height: 25px;
  color: black;
  margin-bottom: 5px;
}
.InboundListContainerEmptySub {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  @include RoobertMedium;
  font-size: 15px;
  line-height: 20px;
  color: black;
  opacity: 0.7;
  margin-bottom: 15px;
}
.InboundListContainerEmptyLink {
  display: inline-block;
  vertical-align: top;
  overflow: hidden;
  position: relative;
  @include RoobertMedium;
  font-size: 15px;
  line-height: 20px;
  text-decoration: none;
  opacity: 0.5;
  color: black;
  background-repeat: no-repeat;
  background-position: right 5px center;
  background-image: url('../../images/view-website-icon.png');
  background-size: 10px 10px;
  background-color: transparent;
  padding-right: 25px;
  transition: opacity 0.2s;

  &:hover {
    opacity: 1;
  }
}
.InboundListItem {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  border-radius: 3px;
  background: white;
  padding: 15px 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  text-decoration: none;

  &.InboundListItemExtended {
    padding-bottom: 45px;
  }
}
.InboundListItemTitle {
  display: block;
  overflow: hidden;
  position: relative;
  width: 95%;
  @include RoobertSemiBold;
  font-size: 16px;
  line-height: 20px;
  color: black;
  margin-bottom: 5px;
  word-break: break-all;
}
.InboundListItemSubtitle {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  @include RoobertSemiBold;
  font-size: 13px;
  line-height: 15px;
  color: black;
  margin-bottom: 5px;
  opacity: 0.8;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.InboundListItemDesc {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include ApercuLight;
  font-size: 12px;
  line-height: 15px;
  color: black;
}
.InboundListItemAssignedTo {
  position: absolute;
  display: block;
  overflow: hidden;
  bottom: 10px;
  left: 15px;
  font-size: 16px;
  line-height: 25px;
  padding-left: 30px;
  @include RoobertMedium;
  color: red;
  background-repeat: no-repeat;
  background-position: center left;
  background-size: 25px 25px;

  &.InboundListItemAssignedToRICHARD {
    background-image: url('../../images/richard-pic.jpeg');
  }

  &.InboundListItemAssignedToLEIGH {
    background-image: url('../../images/leigh-pic.jpeg');
  }
  &.InboundListItemAssignedToTIM {
    background-image: url('../../images/tim-pic.jpeg');
  }

  &.InboundListItemAssignedToPIQUE {
    background-image: url('../../images/pique-pic.png');
  }
  &.InboundListItemAssignedToROBIN {
    background-image: url('../../images/robin-pic.jpg');
  }
  &.InboundListItemAssignedToGAYLE {
    background-image: url('../../images/gayle-pic.jpeg');
  }
  &.InboundListItemAssignedToCHARLIE {
    background-image: url('../../images/charlie-pic.jpeg');
  }

  &.InboundListItemAssignedToDAVE {
    background-image: url('../../images/dave-pic.png');
  }
}
.InboundListItemUnread {
  position: absolute;
  display: block;
  overflow: hidden;
  top: 5px;
  right: 5px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('../../images/unread-circles-icon.png');
  background-size: 10px 10px;
  height: 15px;
  width: 15px;
}

.InboundListItemSelector {
  position: absolute;
  display: block;
  overflow: hidden;
  top: 5px;
  right: 25px;
  height: 20px;
  width: 20px;
  background: white;
  z-index: 4;
}
.InboundListItem:hover,
.InboundListItem.active {
  background: #02071c;

  .InboundListItemTitle {
    color: white;
  }
  .InboundListItemSubtitle {
    color: white;
  }
  .InboundListItemDesc {
    color: white;
  }
  .InboundListItemAssignedTo {
    color: white;
  }

  .InboundListItemUnread {
    display: none;
  }
}
.InboundListItemLoader {
  display: block;
  overflow: hidden;
  position: relative;
  text-align: center;
  width: 100px;
  padding: 10px 0;
  @include RoobertSemiBold;
  font-size: 14px;
  line-height: 20px;
  color: black;
  background: white;
  border-radius: 20px;
  margin: auto;
  cursor: pointer;
  opacity: 0.8;
  transition: all 0.2s;
  margin-top: 20px;
  margin-bottom: 20px;

  &:hover {
    opacity: 1;
    color: white;
    background: #02071c;
  }
}
.InboundDetailColumn {
  z-index: 4;
  display: block;
  position: fixed;
  overflow: hidden;
  top: 5px;
  left: 100%;
  right: 5px;
  bottom: 5px;
  border-radius: 5px;
  transition: left 0.2s;
}
.InboundScreen.InboundScreenWithDetail {
  .InboundDetailColumn {
    left: 5px;
  }
}
.InboundDetailColumnCover {
  z-index: 2;
  display: block;
  position: absolute;
  overflow: hidden;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background: black;
  opacity: 0.5;
}
.InboundDetailColumnMain {
  background: #dedbd3;
  z-index: 3;
  display: block;
  position: absolute;
  overflow: hidden;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  padding-top: 50px;
  max-width: 790px;
}
.InboundDetailColumnHeader {
  display: block;
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 3;
  height: 50px;
}
.InboundDetailColumnHeaderBack {
  left: 10px;
  position: absolute;
  display: block;
  overflow: hidden;
  height: 30px;
  top: 10px;
  background-image: url('../../images/left-arrow-black.png');
  background-size: 12px 20px;
  background-repeat: no-repeat;
  background-position: left 5px center;
  transition: background-color 0.2s;
  border-radius: 3px;
  padding-left: 30px;
  padding-right: 5px;
  @include RoobertMedium;
  font-size: 22px;
  line-height: 30px;
  color: black;
  text-decoration: none;
  cursor: pointer;
  max-width: 100%;

  &:hover {
    background-color: rgba(255, 255, 255, 0.4);
  }
}
.InboundDetailColumnBody {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  height: 100%;
  z-index: 2;
}
.ExpertInboundDetail {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  height: 100%;

  .ThreadDetailMainBody .ThreadEvents {
    padding-top: 15px;
  }
}
.InboundListColumnActions {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  margin-bottom: 15px;
  text-align: right;
}
.InboundListColumnAction {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  overflow: hidden;
  border: 1px solid #02071c;
  background: white;
  border-radius: 3px;
  padding: 10px;
  text-align: center;
  @include RoobertSemiBold;
  font-size: 16px;
  cursor: pointer;

  &:hover {
    background: #02071c;
    color: white;
  }
}
.InboundListColumnSearch {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  margin-bottom: 5px;
  border-radius: 3px;
}
.InboundListColumnSearchInput {
  position: relative;
  overflow: hidden;
  width: 100%;
  @include RoobertMedium;
  padding: 5px 10px;
  font-size: 16px;
  color: black;
  height: 100%;
  border: none;
  outline: none;
  background: white;
}
.InboundListColumnSearchCancel {
  overflow: hidden;
  float: right;
  background-image: url('../../images/search-icon-black.png');
  background-repeat: no-repeat;
  background-position: center right 10px;
  border-radius: 30px;
  padding: 5px 15px 5px 15px;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
  display: block;
  position: absolute;
  top: 0px;
  right: 0px;
  background-size: 12px 12px;
  height: 100%;
  background-image: url('../../images/search-close-black.png');
}

@media only screen and (min-width: $base-sidebar-breakpoint) {
  .InboundListColumn {
    padding-left: 0;
  }
  .InboundListContainer {
    margin-bottom: 60px;
  }
  .InboundDetailColumn {
    transition: left 0.4s;
  }
  .InboundDetailColumnMain {
    border-left: 5px solid white;
  }
}

@media only screen and (min-width: 1000px) {
  .InboundScreen {
    padding: 0;
  }
  .InboundListColumn {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 320px;
    height: 100%;
    padding: 11vh 0 11vh;
  }
  .InboundListContainer {
    min-height: unset;
    overflow: unset;
    overflow-x: hidden;
  }
  .InboundScreenContent {
    height: 100%;
  }
  .InboundDetailColumn {
    display: block;
    position: absolute;
    overflow: hidden;
    top: 0px;
    left: 320px;
    right: 0px;
    bottom: 0px;
  }
  .InboundScreen.InboundScreenWithDetail {
    .InboundDetailColumn {
      left: 320px;
    }
  }
  .InboundDetailColumnCover {
    display: none;
  }
  .InboundDetailColumnMain {
    border: 0;
    background: transparent;
    padding-top: 0;
    max-width: unset;
  }
  .ExpertInboundDetail {
    .ThreadDetailMainBody .ThreadEvents {
      padding-top: 11vh;
      padding-left: 10px;
      padding-right: 10px;
    }
  }
  .InboundDetailColumnHeader {
    display: none;
  }
}

@media only screen and (min-width: 1200px) {
  .ExpertInboundDetail {
    .ThreadDetailMainBody .ThreadEvents {
      padding-left: 30px;
      padding-right: 30px;
    }
  }
}

@media only screen and (min-width: 1200px) {
  .ExpertInboundDetail {
    .ThreadDetailMainBody .ThreadEvents {
      padding-left: 60px;
      padding-right: 60px;
    }
  }
}
