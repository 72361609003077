@import '../Common';

.HumanNewRequestFlow {
}

.ThreadDetailsSidebarAboutSubtitle {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include ApercuLight;
  font-size: 16px;
  line-height: 20px;
  color: black;
  padding: 15px;
  border: 1px solid black;
  border-radius: 3px;
  background-color: #fafafa;
}

.ThreadDetailsSidebarAboutBullets {
  width: 100%;
  display: block;
  overflow: hidden;
  position: relative;
  margin-top: 5px;
  margin-bottom: 5px;
  @include FkDisplayRegular;
  font-size: 15px;
  line-height: 20px;
  color: black;
  padding-left: 30px;
}

.ThreadDetailsSidebarAboutBulletItem {
  margin-top: 10px;
  margin-bottom: 10px;
}

.ThreadDetailsSidebarAboutExamples {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 60px;
}

.ThreadDetailsSidebarAboutExampleItem {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  margin-bottom: 20px;
}

.ThreadDetailsSidebarAboutExampleItemTitle {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  @include RoobertSemiBold;
  font-size: 18px;
  line-height: 25px;
  color: black;
  margin-bottom: 5px;
}

.ThreadDetailsSidebarAboutExampleItemContent {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include ApercuLight;
  font-size: 16px;
  line-height: 20px;
  color: #232323;
  padding: 15px;
  border: 1px solid #cdcdcd;
  border-radius: 3px;
  background-color: #f8f8f8;
}
.ThreadDetailMainBody {
  display: block;
  position: relative;
  width: 100%;
  overflow-x: hidden;
}
.ThreadDetailMainBodyFocus {
  padding: 10px 15px;
  display: block;
  position: relative;
  width: 100%;
  overflow: hidden;
  max-width: 750px;
  margin: 50px auto;

  &.ThreadDetailMainBodyFocusDual {
    max-width: 1000px;
    margin-top: 15px;
    min-height: 300px;

    .ThreadDetailMainBodyFocusTitle {
      margin-bottom: 25px;
    }
  }
}
.ThreadDetailMainBodyFocusDualLeft {
  display: block;
  position: relative;
  overflow: hidden;
  width: 100%;
}
.ThreadDetailMainBodyFocusDualRight {
  display: block;
  position: relative;
  width: 100%;
  overflow: hidden;
}

.ThreadDetailMainBodyFocusTitleTag {
  position: relative;
  display: inline-block;
  vertical-align: top;
  overflow: hidden;
  line-height: 25px;
  padding: 0 15px;
  @include RoobertSemiBold;
  font-size: 13px;
  letter-spacing: 0.04em;
  color: #0619ac;
  z-index: 2;
  border-radius: 6px;
  background: #ccd1fa;
  margin-bottom: 5px;
}
.ThreadDetailMainBodyFocusTitleSuper {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  @include RoobertMedium;
  font-size: 17px;
  line-height: 20px;
  color: black;
  margin-bottom: 0px;
  letter-spacing: -0.02em;
}
.ThreadDetailMainBodyFocusTitle {
  font-size: 45px;
  line-height: 55px;
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  @include RoobertSemiBold;
  letter-spacing: -0.01em;
  color: black;
}
.ThreadDetailMainBodyFocusSubtitle {
  width: 100%;
  display: block;
  overflow: hidden;
  position: relative;
  @include RoobertRegular;
  font-size: 15px;
  line-height: 20px;
  color: black;
  margin-top: 10px;
  margin-bottom: 10px;

  .ThreadDetailMainBodyFocusSubtitleLink {
    @include RoobertSemiBold;
    color: black;
    text-decoration: underline;
    cursor: pointer;
  }
}
.ThreadDetailMainBodyFocusMedtitle {
  font-size: 28px;
  line-height: 34px;
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  @include RoobertSemiBold;
  color: black;
  margin-bottom: 5px;
  letter-spacing: -0.02em;
}
.ThreadDetailMainBodyFocusTitleAction {
  top: 17px;
  right: 15px;
  overflow: hidden;
  position: absolute;
  display: inline-block;
  vertical-align: top;
  @include RoobertSemiBold;
  font-size: 17px;
  line-height: 20px;
  color: black;
  background: white;
  border: 1px solid black;
  cursor: pointer;
  border-radius: 5px;
  padding: 10px 20px;
  transition: all 0.2s;

  &:hover {
    background: black;
    color: white;
  }
}

.BasicForm {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  margin-top: 40px;
  margin-bottom: 0px;

  &.BasicFormLowTop {
    margin-top: 10px;
  }
  &.BasicFormSmallTop {
    margin-top: 20px;
  }
}
.BasicFormTitle {
  width: 100%;
  overflow: hidden;
  position: relative;
  display: block;
  @include RoobertSemiBold;
  font-size: 20px;
  line-height: 25px;
  color: black;
  margin-bottom: 5px;
}
.BasicFormField {
  position: relative;
  display: inline-block;
  vertical-align: top;
  overflow: hidden;
  width: 100%;
  margin-bottom: 35px;

  &.BasicFormFieldLessMargin {
    margin-bottom: 25px;
  }

  &.BasicFormFieldExtraBreak {
    margin-top: 35px;
  }

  &:last-child {
    margin-bottom: 5px;
  }
}
.BasicFormFieldTitle {
  padding-right: 50px;
  overflow: hidden;
  position: relative;
  display: block;
  width: 100%;
  @include RoobertSemiBold;
  font-size: 19px;
  line-height: 27px;
  color: black;

  span {
    @include RoobertMedium;
    opacity: 0.3;
    font-size: 14px;
    line-height: 20px;
  }
}
.BasicFormField.BasicFormFieldSplit .BasicFormFieldTitle {
  padding-right: 0;
}
.BasicFormField.BasicFormFieldMidSplit .BasicFormFieldTitle {
  padding-right: 0;
}
.BasicFormFieldSubtitle {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  @include RoobertMedium;
  font-size: 16px;
  line-height: 20px;
  color: black;
  opacity: 0.5;
}
.BasicFormFieldOptions {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  margin-top: 10px;
}
.BasicFormFieldOption {
  vertical-align: top;
  display: inline-block;
  overflow: hidden;
  position: relative;
  margin-bottom: 10px;
  margin-right: 10px;
  @include RoobertSemiBold;
  font-size: 17px;
  line-height: 20px;
  text-align: center;
  color: #000;
  background: #fff;
  border: 1px solid black;
  border-radius: 6px;
  transition: all 0.2s;
  padding: 15px 20px;
  text-transform: lowercase;
  cursor: pointer;

  &.BasicFormFieldOptionActive {
    background: #000;
    color: #fff;
  }
}

.BasicFormFieldOptionsDesc {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  border-top: 1px solid #efefef;
  padding-top: 10px;
  @include ApercuRegular;
  font-size: 17px;
  letter-spacing: -0.01em;
  line-height: 25px;
  color: #404040;
  margin-top: 10px;
  margin-bottom: 10px;

  span {
    font-weight: bold;
  }
}
.BasicFormFieldInputSideBySide {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
}
.BasicFormFieldInputSide {
  position: relative;
  display: inline-block;
  vertical-align: top;
  overflow: hidden;
  margin-bottom: 5px;
  margin-right: 10px;
  max-width: 100px;
}
.BasicFormFieldInput {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include ApercuLight;
  font-size: 16px;
  line-height: 20px;
  color: black;
  background: transparent;
  border-radius: 3px;
  outline: none;
  border: 1px solid black;
  padding: 15px;
  margin-top: 5px;
  max-width: 340px;

  &.PhoneNumberInput {
    display: flex;
    line-height: 20px;
  }

  &.BasicFormFieldInputInvalid {
    color: $red-error;
    border-color: $red-error;

    input {
      color: $red-error;
    }
  }
}
.BasicFormFieldTextarea {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include ApercuLight;
  font-size: 16px;
  line-height: 20px;
  color: black;
  background: transparent;
  border-radius: 3px;
  outline: none;
  border: 1px solid black;
  padding: 15px;
  margin-top: 5px;
  min-height: 100px;
}
.BasicFormFieldSelect {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include ApercuLight;
  font-size: 16px;
  line-height: 20px;
  padding: 15px;
  color: black;
  background: transparent;
  margin-top: 5px;
  border: 1px solid black;
  border-radius: 3px;
  outline: none;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-repeat: no-repeat;
  background-position: center right 15px;
  background-image: url('../../images/arrow-down-grey.png');
  background-size: 14px 8px;
}
.BasicFormFieldExamples {
  display: none;
  vertical-align: top;
  overflow: hidden;
  position: absolute;
  top: 4px;
  right: 5px;
  @include RoobertMedium;
  font-size: 14px;
  line-height: 20px;
  color: black;
  opacity: 0.63;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
}
.BasicFormFieldAttachments {
  text-indent: -9999px;
  display: inline-block;
  vertical-align: top;
  overflow: hidden;
  position: absolute;
  top: 4px;
  right: 5px;
  @include RoobertMedium;
  font-size: 14px;
  line-height: 20px;
  color: black;
  background-repeat: no-repeat;
  background-position: center left;
  background-image: url('../../images/attach-file-icon.png');
  background-size: 11px 19px;
  opacity: 0.63;
  padding-left: 20px;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
}
.BasicFormFieldRichFooterAttachments {
  display: inline-block;
  vertical-align: top;
  overflow: hidden;
  position: relative;
  @include RoobertMedium;
  font-size: 14px;
  line-height: 20px;
  color: black;
  background-repeat: no-repeat;
  background-position: center left;
  background-image: url('../../images/attach-file-icon.png');
  background-size: 11px 19px;
  opacity: 0.63;
  padding-left: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-right: 15px;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
}
.BasicFormFieldRichFooterAI,
.BasicFormFieldRichFooterOG {
  display: none;
  vertical-align: top;
  overflow: hidden;
  position: relative;
  @include RoobertMedium;
  font-size: 14px;
  line-height: 20px;
  color: #a53cd5;
  background-color: #f9ebff;
  background-repeat: no-repeat;
  background-position: center left 5px;
  background-image: url('../../images/use-ai-icon.png');
  background-size: 20px 20px;
  // opacity: 0.63;
  padding-left: 30px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  cursor: pointer;
  margin-right: 15px;
  border-radius: 6px;
  min-width: 140px;
  text-align: center;
  transition: background-color 0.2s;

  &:hover {
    background-color: #f6e0ff;
  }
}
.BasicFormFieldRichFooterAILoading {
  pointer-events: none;
  user-select: none;
  background-image: none;

  &::after {
    animation: spining 500ms infinite linear;
    border: 3px solid #a53cd5;
    border-radius: 20px;
    border-right-color: transparent;
    border-top-color: transparent;
    content: '';
    display: block;
    height: 20px;
    width: 20px;
    left: 5px;
    top: 5px;
    bottom: 5px;
    position: absolute;
    text-align: center;
  }
}
.BasicFormFieldRichFooterOG {
  background-image: url('../../images/use-original-icon.png');
}
.BasicFormFieldRichFooterTemplate {
  display: none;
  vertical-align: top;
  overflow: hidden;
  position: relative;
  @include RoobertMedium;
  font-size: 14px;
  line-height: 20px;
  color: black;
  background-repeat: no-repeat;
  background-position: center left;
  background-image: url('../../images/use-template-icon.png');
  background-size: 20px 20px;
  opacity: 0.63;
  padding-left: 30px;
  padding-top: 5px;
  padding-bottom: 5px;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
}
.BasicFormFieldRichWrapper {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  margin-top: 5px;
  border: 1px solid black;
  border-radius: 3px;
}
.BasicFormFieldRichWrapperText {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
}
.BasicFormFieldRichWrapperTextLoading {
  opacity: 0.5;
}
.BasicFormFieldRichFooter {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  border-top: 1px solid black;
  height: 50px;
  padding: 10px 15px;
}
.BasicFormFieldRich {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  margin-top: 5px;
  border: 1px solid black;
  padding: 15px;
  border-radius: 3px;
}
.BasicFormInlineError {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  margin-top: 5px;
  @include RoobertSemiBold;
  font-size: 16px;
  line-height: 20px;
  color: black;
  color: #6f1515;
}
.BasicFormFiles {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  margin-top: 5px;
}
.BasicFormFile {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  overflow: hidden;
  padding: 3px 8px;
  margin-top: 2px;
  margin-bottom: 2px;
  margin-left: 5px;
  cursor: pointer;
  @include RoobertMedium;
  font-size: 13px;
  line-height: 20px;
  transition: all 0.2s;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #535353;
  background-color: #e3e3e3;
  border-radius: 3px;
  max-width: 85%;
}
.BasicFormFileRemove {
  display: none;
  position: absolute;
  overflow: hidden;
  top: 3px;
  right: 5px;
  background-color: white;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('../../images/icon-close-black.png');
  background-size: 10px 10px;
  width: 20px;
  height: 20px;
  border-radius: 20px;

  &:hover {
    background-color: #e3e3e3;
  }
}
.BasicFormFile:hover {
  background-color: black;
  color: white;

  .BasicFormFileRemove {
    display: block;
  }
}
.BasicFormFooter {
  display: block;
  width: 100%;
  overflow: hidden;
  position: relative;

  &.BasicFormFooterMarginTop {
    margin-top: 25px;
  }
}
.BasicFormAction {
  overflow: hidden;
  position: relative;
  display: inline-block;
  vertical-align: top;
  background-color: black;
  @include RoobertSemiBold;
  font-size: 17px;
  line-height: 25px;
  color: white;
  cursor: pointer;
  border-radius: 5px;
  padding: 10px 25px;
  padding-right: 55px;
  background-repeat: no-repeat;
  background-position: center right 25px;
  background-image: url('../../images/forward-arrow-white.png');
  background-size: 16px 16px;
  margin-bottom: 20px;
  transition:
    background-color 0.2s,
    color 0.2s;

  &:hover {
    background-color: #a2f4c2;
    color: black;
    background-image: url('../../images/view-icon-black.png');
  }
  &.BasicFormActionInvalid {
    pointer-events: none;
    background-color: #888;
  }
  &.BasicFormActionNoMargin {
    margin-bottom: 0;
  }
  &.BasicFormActionLoading {
    pointer-events: none;
    background: none;
    background-color: #a2f4c2;
    color: transparent;
    user-select: none;

    &::after {
      animation: spining 500ms infinite linear;
      border: 3px solid black;
      border-radius: 20px;
      border-right-color: transparent;
      border-top-color: transparent;
      content: '';
      display: block;
      height: 19px;
      width: 19px;
      left: 0;
      right: 0;
      margin: auto;
      top: 13px;
      bottom: 13px;
      position: absolute;
      text-align: center;
    }
  }
}

.BasicFormFieldInputExtra {
  position: relative;
  display: block;
  overflow: hidden;
  @include RoobertSemiBold;
  font-size: 13px;
  line-height: 22px;
  margin-top: 5px;
  cursor: pointer;
  transition: opacity 0.2s;
  opacity: 0.58;
  color: black;
  background-repeat: no-repeat;
  background-position: left center;
  background-image: url('../../images/add-contact-black.png');
  background-size: 15px 15px;
  padding-left: 20px;
  height: 20px;

  &:hover {
    opacity: 1;
  }
}
.BasicFormFieldAction {
  overflow: hidden;
  position: relative;
  display: inline-block;
  vertical-align: top;
  float: right;
  @include RoobertSemiBold;
  font-size: 17px;
  line-height: 20px;
  color: white;
  background: black;
  cursor: pointer;
  border-radius: 5px;
  padding: 10px 20px;
  margin-left: 10px;
  transition:
    background 0.2s,
    color 0.2s;

  &:hover {
    color: black;
    background: #a2f4c2;
  }
}
.BasicFormFieldActionInstead {
  overflow: hidden;
  position: relative;
  display: inline-block;
  vertical-align: top;
  float: right;
  @include RoobertMedium;
  font-size: 14px;
  line-height: 20px;
  padding: 10px;
  color: black;
  cursor: pointer;
  opacity: 0.5;
  transition: all 0.2s;

  &:hover {
    opacity: 1;
  }
}

.BasicFormFieldValue {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  @include ApercuLight;
  font-size: 16px;
  line-height: 20px;
  color: black;
  padding: 5px 0;
}

.BrandSelector {
  display: block;
  position: relative;
  overflow: hidden;
  width: 100%;
}
.BrandSelected {
  display: inline-block;
  vertical-align: top;
  position: relative;
  overflow: hidden;
  padding: 10px;
  padding-right: 75px;
  cursor: pointer;
  border: 1px solid black;
  border-radius: 5px;
  margin-top: 5px;

  &:hover {
    background-repeat: no-repeat;
    background-position: center right 10px;
    background-image: url('../../images/arrow-down-black.png');
    background-size: 16px 9px;
    background-color: rgba(0, 0, 0, 0.05);
  }
}
.BrandSelectedTitle {
  display: block;
  position: relative;
  overflow: hidden;
  width: 100%;
  @include RoobertSemiBold;
  font-size: 16px;
  line-height: 20px;
  color: black;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.BrandSelectedSubtitle {
  display: block;
  position: relative;
  overflow: hidden;
  width: 100%;
  @include RoobertMedium;
  font-size: 13px;
  line-height: 15px;
  color: black;
  white-space: nowrap;
  text-overflow: ellipsis;
  opacity: 0.75;
}
.BrandOptions {
  display: block;
  position: relative;
  overflow: hidden;
  width: 100%;
}
.BrandOptionsInput {
  display: block;
  position: relative;
  overflow: hidden;
  width: 100%;
}
.BrandOptionsInputField {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include ApercuLight;
  font-size: 16px;
  line-height: 20px;
  padding: 5px 0px;
  color: black;
  background: transparent;
  border-radius: 3px;
  outline: none;
  border: none;
  border: 1px solid black;
  padding: 15px;
  margin-top: 5px;

  &.BrandOptionsInputFieldInvalid {
    color: $red-error;
    border-color: $red-error;
  }
}
.BrandOptionsList {
  display: block;
  position: relative;
  overflow: hidden;
  width: 100%;
  border: 1px solid black;
  border-radius: 5px;
  margin-top: 5px;
}
.BrandOptionsListItem {
  display: block;
  position: relative;
  overflow: hidden;
  padding: 10px;
  padding-right: 75px;
  cursor: pointer;
  border-bottom: 1px solid #eee;

  &:hover {
    background-repeat: no-repeat;
    background-position: center right 15px;
    background-image: url('../../images/view-icon-black.png');
    background-size: 15px 15px;
    background-color: rgba(0, 0, 0, 0.05);
  }

  &:last-child {
    border: none;
  }
}
.BrandOptionsListItemTitle {
  display: block;
  position: relative;
  overflow: hidden;
  width: 100%;
  @include RoobertSemiBold;
  font-size: 16px;
  line-height: 20px;
  color: black;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.BrandOptionsListItemSubtitle {
  display: block;
  position: relative;
  overflow: hidden;
  width: 100%;
  @include RoobertMedium;
  font-size: 13px;
  line-height: 15px;
  color: black;
  white-space: nowrap;
  text-overflow: ellipsis;
  opacity: 0.75;
}

.BasicFormPrefab {
  display: block;
  position: relative;
  overflow: hidden;
  width: 100%;
  padding: 15px;
  border: 1px solid black;
  margin-top: 5px;
}
.BasicFormPrefabTop {
  display: block;
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-bottom: 50px;
}
.BasicFormPrefabTitle {
  display: block;
  position: relative;
  overflow: hidden;
  width: 100%;
  @include RoobertMedium;
  font-size: 20px;
  line-height: 25px;
  color: black;
}
.BasicFormPrefabToggle {
  display: inline-block;
  vertical-align: top;
  position: absolute;
  overflow: hidden;
  bottom: 0px;
  left: 0px;
  cursor: pointer;
  @include RoobertSemiBold;
  font-size: 15px;
  line-height: 20px;
  color: black;
  opacity: 0.5;
  padding: 5px 0;
  padding-right: 25px;
  background-repeat: no-repeat;
  background-position: center right;
  background-image: url('../../images/arrow-down-black.png');
  background-size: 16px 9px;

  &.BasicFormPrefabToggleOpen {
    background-image: url('../../images/arrow-up-black.png');
  }
  &:hover {
    opacity: 1;
  }
}
.BasicFormPrefabFeatures {
  display: block;
  position: absolute;
  overflow: hidden;
  bottom: 0px;
  right: 0px;
}
.BasicFormPrefabFeature {
  position: relative;
  overflow: hidden;
  display: block;
  width: 70px;
  float: right;
  text-align: right;

  &:first-child {
    margin-left: 15px;
  }
}
.BasicFormPrefabFeatureTitle {
  display: block;
  position: relative;
  overflow: hidden;
  width: 100%;
  @include RoobertSemiBold;
  font-size: 13px;
  line-height: 15px;
  color: black;
  opacity: 0.4;
}
.BasicFormPrefabFeatureValue {
  display: block;
  position: relative;
  overflow: hidden;
  width: 100%;
  @include FkDisplayRegular;
  font-size: 17px;
  line-height: 25px;
  color: black;
  opacity: 0.7;
}
.BasicFormPrefabDesc {
  display: block;
  position: relative;
  overflow: hidden;
  width: 100%;
  border-top: 1px solid #eee;
  margin-top: 15px;
  padding-top: 15px;
}
.BasicFormPrefabContent {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include ApercuLight;
  font-size: 16px;
  line-height: 20px;
  color: black;
}

.BasicFormExpertChoices {
  position: relative;
  display: block;
  overflow: hidden;
  overflow-x: auto;
  width: 100%;
  white-space: nowrap;
  margin-top: 5px;

  > :last-child {
    margin-right: 0;
  }
}
.BasicFormExpertPhoto {
  width: 100%;
  height: 220px;
  position: relative;
  display: block;
  overflow: hidden;
  white-space: normal;
  text-decoration: none;
  border-radius: 10px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  border: 2px solid transparent;
}
.BasicFormExpertNew {
  width: 100%;
  height: 220px;
  position: relative;
  display: block;
  overflow: hidden;
  white-space: normal;
  text-decoration: none;
  border-radius: 10px;
  border: 2px solid #ddd;
  text-align: center;
  padding: 150px 10px 0;
  background-color: #eee;
  background-repeat: no-repeat;
  background-position: top 40px center;
  background-image: url('../../images/plus-icon-black.png');
  background-size: 35px 35px;
  @include ApercuRegular;
  font-size: 16px;
  line-height: 20px;
}
.BasicFormExpert {
  position: relative;
  display: inline-block;
  overflow: hidden;
  margin-right: 15px;
  margin-bottom: 15px;
  vertical-align: top;
  transition: opacity 0.2s;
  width: 160px;

  &:not(.BasicFormExpertNoEffects) {
    cursor: pointer;
    opacity: 0.5;

    &:hover {
      opacity: 1;
    }

    &.BasicFormExpertSelected {
      opacity: 1;

      .BasicFormExpertPhoto,
      .BasicFormExpertNew {
        border: 2px solid black;
      }
    }
  }
}
.BasicFormExpertName {
  display: block;
  position: relative;
  overflow: hidden;
  width: 100%;
  @include RoobertMedium;
  font-size: 16px;
  line-height: 20px;
  color: black;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-top: 5px;
}

.BasicFormAction {
  overflow: hidden;
  position: relative;
  display: inline-block;
  vertical-align: top;
  background-color: black;
  @include RoobertSemiBold;
  font-size: 17px;
  line-height: 25px;
  color: white;
  float: right;
  text-decoration: none;
  cursor: pointer;
  border-radius: 3px;
  padding: 10px 25px;
  padding-right: 55px;
  background-repeat: no-repeat;
  background-position: center right 25px;
  background-image: url('../../images/forward-arrow-white.png');
  background-size: 16px 16px;
  transition:
    background-color 0.2s,
    color 0.2s;

  &.BasicFormActionClear {
    font-size: 14px;
    margin-right: 5px;
    line-height: 23px;
    background: white;
    color: black;
    padding: 10px 15px;
    border: 1px solid black;
  }

  &.BasicFormActionApprove {
    font-size: 14px;
    background: black;
    color: white;
    padding: 10px 15px;
  }

  &.BasicFormActionInvalid {
    pointer-events: none;
    background-color: #888;
  }

  &.BasicFormActionSend {
    background-image: url('../../images/send-icon-white.png');
  }

  &.BasicFormActionDownload {
    background-image: url('../../images/download-icon-white.png');
  }

  &:hover {
    background-color: #a2f4c2;
    color: black;
    background-image: url('../../images/view-icon-black.png');

    &.BasicFormActionSend {
      background-image: url('../../images/send-icon-black.png');
    }

    &.BasicFormActionDownload {
      background-image: url('../../images/download-icon-black.png');
    }
    &.BasicFormActionApprove {
      background-image: none;
    }
    &.BasicFormActionClear {
      background-image: none;
    }
  }

  &.BasicFormActionHideSmall {
    display: none;
  }

  &.BasicFormActionLoading {
    pointer-events: none;
    background: none;
    background-color: #a2f4c2;
    color: transparent;
    user-select: none;

    &::after {
      animation: spining 500ms infinite linear;
      border: 3px solid black;
      border-radius: 20px;
      border-right-color: transparent;
      border-top-color: transparent;
      content: '';
      display: block;
      height: 19px;
      width: 19px;
      left: 0;
      right: 0;
      margin: auto;
      top: 13px;
      bottom: 13px;
      position: absolute;
      text-align: center;
    }
  }
}
@keyframes spining {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.BasicFormActionInstead {
  overflow: hidden;
  position: relative;
  display: inline-block;
  vertical-align: top;
  @include RoobertMedium;
  font-size: 14px;
  line-height: 15px;
  padding: 5px 10px;
  margin: 10px 0;
  margin-right: 10px;
  color: black;
  float: right;
  cursor: pointer;
  opacity: 0.5;
  transition: all 0.2s;

  &:hover {
    opacity: 1;
  }
}

.BasicFormDraftStatus {
  overflow: hidden;
  position: relative;
  display: inline-block;
  vertical-align: top;
  @include RoobertSemiBold;
  font-size: 12px;
  line-height: 15px;
  padding: 5px 10px;
  margin: 10px 0;
  margin-right: 10px;
  text-transform: lowercase;
  color: black;
  float: right;
  opacity: 0.25;

  &.BasicFormDraftStatus-saving {
  }
  &.BasicFormDraftStatus-saved {
  }
}
.BasicFormDownload {
  display: none;
  vertical-align: middle;
  overflow: hidden;
  position: absolute;
  bottom: 25px;
  left: 15px;
  @include RoobertMedium;
  font-size: 14px;
  line-height: 25px;
  color: black;
  opacity: 0.5;
  padding-right: 20px;
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: center right;
  background-image: url('../../images/download-icon-black.png');
  background-size: 15px 15px;
  transition: opacity 0.2s;
  &:hover {
    opacity: 1;
  }
}
.BasicFormActionExtended {
  display: block;
  overflow: hidden;
  position: absolute;
  top: 15px;
  right: 0px;
  padding-right: 15px;
  padding-left: 15px;
  width: 100%;
  @include RoobertMedium;
  font-size: 12px;
  line-height: 15px;
  color: black;
  text-align: right;
  opacity: 0.5;

  &.BasicFormActionExtendedShort {
    max-width: 250px;
  }
}
.BasicFormInfoWarning {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  @include RoobertMedium;
  font-size: 13px;
  line-height: 15px;
  color: black;
  text-align: center;
  opacity: 0.75;
  padding: 0 1%;
}

@media only screen and (min-width: 350px) {
  .BasicFormFieldRichFooterAI,
  .BasicFormFieldRichFooterOG {
    display: inline-block;
  }
}

@media only screen and (min-width: 400px) {
  .BasicFormFieldTitle {
    padding-right: 120px;
  }
  .BasicFormFieldAttachments {
    text-indent: unset;
  }
}
@media only screen and (min-width: 450px) {
  .BasicFormFieldExamples {
    display: inline-block;
  }
}

@media only screen and (min-width: 500px) {
  .BasicFormFieldRichFooterTemplate {
    display: inline-block;
  }
  .ThreadDetailMainBodyFocusTitle {
    font-size: 60px;
    line-height: 70px;
  }
  .ThreadDetailMainBodyFocusTitleAction {
    top: 25px;
    right: 15px;
  }
  .ThreadDetailMainBodyFocusMedtitle {
    font-size: 36px;
    line-height: 44px;
  }
  .ThreadDetailMainBodyFocusSubtitle {
    width: 90%;
  }
  .BasicFormTitle {
    width: 65%;
  }
  .BasicFormAction.BasicFormActionHideBig {
    display: none;
  }
  .BasicFormAction {
    &.BasicFormActionHideSmall {
      display: inline-block;
    }
    &.BasicFormActionClear {
      font-size: 15px;
      margin-right: 10px;
    }
    &.BasicFormActionApprove {
      font-size: 15px;
    }
  }
}

@media only screen and (min-width: 600px) {
  .BasicFormActionExtended {
    max-width: 380px;

    &.BasicFormActionExtendedShort {
      max-width: 250px;
    }
  }
}

@media only screen and (min-width: 640px) {
  .BasicFormFieldRichFooterAI,
  .BasicFormFieldRichFooterOG {
    display: none;
  }
  .BasicFormFieldRichFooterTemplate {
    display: none;
  }
  .BasicFormFieldExamples {
    display: none;
  }
}

@media only screen and (min-width: 700px) {
  .HumanNewRequestFlow
    .BasicFormFieldRichWrapperText
    .RichTextEditorWrap
    .DraftEditor-root {
    min-height: 15vh;
  }
  .BasicFormFieldRichFooterAI,
  .BasicFormFieldRichFooterOG {
    display: inline-block;
  }
}

@media only screen and (min-width: 750px) {
  .BasicFormFieldExamples {
    display: inline-block;
  }
}

@media only screen and (min-width: 800px) {
  .BasicFormDownload {
    display: inline-block;
  }
  .BasicFormField.BasicFormFieldSplit {
    width: 48%;
    padding-right: 2%;
  }
  .BasicFormField.BasicFormFieldSplit .BasicFormFieldTitle {
    font-size: 16px;
  }
  .BasicFormField.BasicFormFieldMidSplit .BasicFormFieldTitle {
    font-size: 16px;
  }
  .BasicFormFieldRichFooterTemplate {
    display: inline-block;
  }
}
@media only screen and (min-width: 900px) {
  .ThreadDetailMainBodyFocus.ThreadDetailMainBodyFocusDual {
    padding-left: 220px;
    margin-top: 50px;

    .ThreadDetailMainBodyFocusDualLeft {
      position: absolute;
      width: 220px;
      top: 0;
      left: 0;
      padding: 25px 15px;
    }
    .ThreadDetailMainBodyFocusDualRight {
      padding-left: 20px;
    }
  }
}

@media only screen and (min-width: 1000px) {
  .ThreadDetailMainBodyFocus {
    padding: 10px 25px;
  }
  .BasicFormAction {
    &.BasicFormActionClear {
      font-size: 17px;
    }
    &.BasicFormActionApprove {
      font-size: 17px;
    }
  }
  .BasicFormField.BasicFormFieldMidSplit {
    width: 48%;
    padding-right: 2%;
  }
}
@media only screen and (min-width: 1100px) {
  .ThreadDetailMainBodyFocus.ThreadDetailMainBodyFocusDual {
    padding-left: 250px;
    margin-top: 50px;

    .ThreadDetailMainBodyFocusDualLeft {
      position: absolute;
      width: 250px;
      top: 0;
      left: 0;
      padding: 25px 15px;
    }
    .ThreadDetailMainBodyFocusDualRight {
      padding-left: 60px;
      padding-right: 30px;
    }
  }
}
