@import '../Common';

.Login {
  padding: 0 25px 40px;
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  background: #171717;
  overflow: auto;
}
.LoginLogoWrap {
  position: relative;
  display: block;
  overflow: hidden;
  padding-top: 30px;
}
.LoginLogo {
  position: relative;
  display: block;
  overflow: hidden;
  height: 30px;
}
.LoginHeader {
  position: relative;
  display: block;
  overflow: hidden;
  padding-top: 8vh;
  margin-bottom: 30px;
  width: 100%;
  max-width: 400px;
}
.LoginHeaderIntro {
  font-size: 16px;
  line-height: 20px;
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include FkDisplayRegular;
  margin-bottom: 5px;
  letter-spacing: -0.01em;
  color: #fff;
  opacity: 0.5;
}
.LoginHeaderTitle {
  font-size: 35px;
  line-height: 40px;
  min-height: 80px;
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include FkDisplayRegular;
  letter-spacing: -0.05em;
  color: #fff;
}
.LoginBody {
  max-width: 300px;
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
}
.LoginField {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  border-bottom: 1px solid white;
}
.LoginFieldName {
  font-size: 12px;
  line-height: 15px;
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include FkDisplayRegular;
  color: #fff;
  opacity: 1;
}
.LoginFieldInput {
  font-size: 18px;
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  height: 50px;
  @include RoobertRegular;
  background: transparent;
  border: none;
  outline: none;
  color: white;
}

.LoginChecks {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
}
.LoginCheck {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  padding-left: 30px;
  margin-top: 15px;
}
.LoginCheckBox {
  position: absolute;
  display: block;
  overflow: hidden;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  border: 1px solid white;
  border-radius: 3px;
  background: transparent;
  cursor: pointer;
  transition: background 0.2s;

  &:hover {
    background: #666;
  }
}
.LoginCheck.LoginCheckChecked {
  .LoginCheckBox {
    background: white;
  }
}
.LoginCheckText {
  font-size: 14px;
  line-height: 20px;
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include RoobertRegular;
  color: white;

  a {
    text-decoration: underline;
    color: white;
  }
}

.LoginBtn,
.OAuthButton {
  font-size: 16px;
  line-height: 20px;
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include RoobertSemiBold;
  padding: 15px 0;
  text-align: center;
  background: white;
  margin-top: 15px;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: #d0e6e5;
  }

  &.LoginBtnLoading {
    pointer-events: none;
    background: none;
    background-color: #d0e6e5;
    color: transparent;
    user-select: none;

    &::after {
      animation: spining 500ms infinite linear;
      border: 3px solid black;
      border-radius: 20px;
      border-right-color: transparent;
      border-top-color: transparent;
      content: '';
      display: block;
      height: 19px;
      width: 19px;
      left: 0;
      right: 0;
      margin: auto;
      top: 0;
      bottom: 0;
      position: absolute;
      text-align: center;
    }
  }
}

@media only screen and (min-width: 700px) {
  .Login {
    padding: 0 70px 80px;
  }
  .LoginHeader {
    padding-top: 10vh;
    max-width: 500px;

    &:not(.LoginHeaderWithout) {
      padding-bottom: 110px;
      margin-bottom: 60px;
      background-image: url('../../images/login-arrow-down.png');
      background-repeat: no-repeat;
      background-position: left 0px bottom 0px;
      background-size: 62px 89px;
    }
  }

  .LoginHeaderIntro {
    font-size: 20px;
    line-height: 25px;
  }
  .LoginHeaderTitle {
    font-size: 65px;
    line-height: 70px;
    min-height: 140px;
  }
  .LoginFieldName {
    font-size: 14px;
    line-height: 20px;
  }
  .LoginFieldInput {
    font-size: 20px;
  }
  .LoginBtn {
    font-size: 20px;
    line-height: 30px;
  }
  .LoginBody {
    max-width: 350px;
  }
}
@media only screen and (min-width: $base-sidebar-breakpoint) {
}
