@import '../Common';

.CalendarSchedule {
  padding: 5px;
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  background: white;
  border-radius: 5px;
  margin-bottom: 25px;
  min-height: 100px;
}
.CalendarScheduleWrap {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
}
.CalendarScheduleLoading {
  pointer-events: none;
  background: none;
  background-color: #a2f4c2;
  color: transparent;
  user-select: none;

  &::after {
    animation: spining 500ms infinite linear;
    border: 3px solid black;
    border-radius: 20px;
    border-right-color: transparent;
    border-top-color: transparent;
    content: '';
    display: block;
    height: 19px;
    width: 19px;
    left: 0;
    right: 0;
    margin: auto;
    top: 0;
    bottom: 0;
    position: absolute;
    text-align: center;
  }
}
.CalendarScheduleDayRow {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
}
.CalendarScheduleDayBlank {
  width: 16%;
  position: relative;
  display: inline-block;
  vertical-align: top;
  overflow: hidden;
  height: 25px;
  cursor: pointer;
  background: white;
  @include RoobertSemiBold;
  font-size: 12px;
  line-height: 25px;
  color: black;
  text-align: center;
}
.CalendarScheduleDayItem {
  width: 12%;
  position: relative;
  display: inline-block;
  vertical-align: top;
  overflow: hidden;
  height: 25px;
  border-top: 1px solid #eaeaea;
  border-left: 1px solid #eaeaea;
  cursor: pointer;
  background: white;
  @include RoobertSemiBold;
  font-size: 12px;
  line-height: 25px;
  color: black;
  text-align: center;

  .CalendarScheduleDayItemBig {
    display: none;
  }
  &:last-child {
    border-right: 1px solid #eaeaea;
  }
}
.CalendarScheduleTimeWrap {
  position: relative;
  display: block;
  overflow-x: hidden;
  width: 100%;
  max-height: 421px;
  border: 1px solid #eaeaea;
}
.CalendarScheduleTimeColumn {
  width: 16%;
  position: relative;
  display: inline-block;
  vertical-align: top;
  overflow: hidden;
}
.CalendarScheduleTimeItem {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  text-align: right;
  padding-right: 5px;
  height: 30px;
  border-top: 1px solid #eaeaea;
  @include RoobertSemiBold;
  font-size: 12px;
  line-height: 15px;
  color: black;

  &:first-child {
    border: none;
  }
}
.CalendarScheduleSelectColumn {
  width: 12%;
  position: relative;
  display: inline-block;
  vertical-align: top;
  overflow: hidden;
  border-left: 1px solid #eaeaea;
}
.CalendarScheduleSelectItem {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  height: 15px;
  border-top: 1px solid #eaeaea;
  cursor: pointer;
  @include RoobertSemiBold;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  user-select: none;
  background: white;
  color: white;
  transition: color 0.2s, background 0.2s;

  &:first-child {
    border: none;
  }

  &:hover {
    background: green;
    color: black;
  }

  &.CalendarScheduleSelectItemSelected {
    background: #a2e0a5;
    color: #a2e0a5;

    &:hover {
      background: red;
      color: black;
    }
  }

  &.CalendarScheduleSelectItemAdd {
    background: #055808;
    color: #055808;

    &:hover {
      color: black;
      background: #055808;
    }
  }
  &.CalendarScheduleSelectItemRemove {
    background: red;
    color: red;

    &:hover {
      color: black;
      background: red;
    }
  }
}

@media only screen and (min-width: 375px) {
}
@media only screen and (min-width: 500px) {
  .CalendarSchedule {
    padding: 15px;
  }
  .CalendarScheduleDayBlank {
    width: 9%;
  }
  .CalendarScheduleDayItem {
    width: 13%;
  }
  .CalendarScheduleTimeColumn {
    width: 9%;
  }
  .CalendarScheduleSelectColumn {
    width: 13%;
  }
}
@media only screen and (min-width: 640px) {
}
@media only screen and (min-width: 750px) {
}
@media only screen and (min-width: 1000px) {
  .CalendarScheduleDayItem {
    .CalendarScheduleDayItemSmall {
      display: none;
    }
    .CalendarScheduleDayItemBig {
      display: inline;
    }
  }
}
@media only screen and (min-width: 1150px) {
}
