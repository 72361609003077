@import '../Common';

.EmailEditor {
}
.EmailEditor .ThreadEditorCard {
  border-radius: 0px;
}
.EmailEditor .ThreadEditorActionBtn.ThreadEditorActionSend {
  border-radius: 20px;
}
.EmailEditor.EmailEditorWithSignatue .RichTextEditorWrap {
  padding-bottom: 45px;
}
.EmailEditorSignature {
  position: absolute;
  display: block;
  overflow: hidden;
  padding: 5px 10px;
  bottom: 8px;
  left: 15px;
  border-radius: 2px;
  border: 1px solid black;
  @include RoobertMedium;
  font-size: 12px;
  line-height: 15px;
  color: #000;
  opacity: 0.22;
  cursor: pointer;
  text-decoration: none;
}
.EmailEditorWrap {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  padding: 25px 15px 45px;
}
.EmailEditorTextarea {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  outline: none;
  border: none;
  @include ApercuLight;
  font-size: 16px;
  line-height: 20px;
  text-align: left;
  color: black;
  background: transparent;
  padding: 0;
  max-height: 60vh;
}
@media only screen and (min-width: 375px) {
}
@media only screen and (min-width: 500px) {
}
@media only screen and (min-width: 640px) {
}
@media only screen and (min-width: 750px) {
}
@media only screen and (min-width: 1000px) {
}
@media only screen and (min-width: 1150px) {
}
