@import '../Common';

.ThreadEvents {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  padding: 0;
}
.InboundEncourageMerge {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  margin-bottom: 50px;
  padding: 15px;
  max-width: 500px;
  border-radius: 3px;
  background-color: #f5f1d5;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: background-color 0.2s;
  background-repeat: no-repeat;
  background-position: top 15px right 15px;
  background-image: url('../../images/search-close-black.png');
  background-size: 12px 12px;

  &:hover {
    background-color: #fdf5be;
  }
}
.InboundEncourageMergeTitle {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include FkDisplayRegular;
  font-size: 22px;
  line-height: 30px;
  margin-bottom: 5px;
}
.InboundEncourageMergeBody {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include RoobertMedium;
  font-size: 15px;
  line-height: 20px;
  color: #555;
}
.ThreadEventGroup {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  margin-bottom: 30px;
}
.ThreadEvent {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;

  .UserBubble {
    background-color: white;
    color: black;
    width: 25px;
    height: 25px;
    font-size: 10px;
    line-height: 25px;

    &.UserBubblePrimary {
      background-color: black;
      color: white;
    }
  }
}
.ThreadEventWrapper {
  position: relative;
  display: inline-block;
  vertical-align: top;
  overflow: hidden;
  width: 100%;
  max-width: 100%;
}
.ThreadEvent.ThreadEventOwnerHuman {
  .ThreadEventWrapper {
    padding-right: 20px;
    float: left;
    clear: both;
  }
}
.ThreadEvent.ThreadEventOwnerExpert {
  .ThreadEventWrapper {
    padding-left: 20px;
    float: right;
    clear: both;
  }
}
.ThreadEvents.ThreadEventsHuman {
  .ThreadEvent.ThreadEventOwnerHuman {
    .ThreadEventWrapper {
      padding-left: 20px;
      padding-right: 0;
      float: right;
      clear: both;
    }
  }
  .ThreadEvent.ThreadEventOwnerExpert {
    .ThreadEventWrapper {
      padding-right: 20px;
      padding-left: 0;
      float: left;
      clear: both;
    }
  }
}
.ThreadEventTop {
  padding-left: 0px;
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  padding-right: 70px;
  min-height: 25px;
}
.ThreadEventTopBubbles {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;

  .UserBubble {
    display: inline-block;
    vertical-align: top;
    margin-right: 5px;
    margin-bottom: 5px;
  }
}
.ThreadEvent.ThreadEventSolo.RequestThreadEvent {
  .ThreadEventTop {
    padding-left: 30px;
  }
  .ThreadEventTopBubbles {
    display: block;
    position: absolute;
    overflow: hidden;
    bottom: 5px;
    left: 0;
    width: unset;

    .UserBubble {
      width: 20px;
      height: 20px;
      font-size: 8px;
      line-height: 20px;
      display: block;
      margin: 0;
    }
  }
}
.ThreadEventTopTitle {
  display: block;
  overflow: hidden;
  position: relative;
  @include RoobertMedium;
  font-size: 18px;
  line-height: 20px;
  padding-bottom: 5px;
  color: black;
}
.ThreadEventTopDate {
  position: absolute;
  bottom: 5px;
  right: 0px;
  @include RoobertRegular;
  font-size: 12px;
  line-height: 20px;
  text-align: right;
  color: black;
  opacity: 0.45;
}
.ThreadEventCardWrapper {
  position: relative;
  display: block;
  // overflow: hidden;
  width: 100%;
}
.ThreadEventCardLeft {
  display: none;
  position: absolute;
  top: 0px;
  left: 0px;
  overflow: hidden;
  width: 25px;
}
.ThreadEventCardLeftUser {
  display: block;
  overflow: hidden;
  position: relative;
}
.ThreadEventCardLeftDate {
  display: block;
  overflow: hidden;
  position: relative;
  @include RoobertRegular;
  font-size: 12px;
  line-height: 25px;
  text-align: right;
  color: black;
  opacity: 0.45;
}
.ThreadEventCard {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  background: white;
  border-radius: 0px;
  margin-bottom: 5px;
  min-height: 40px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);

  &.ThreadEventCardTip {
    background: #f0f0f0;
    box-shadow: none;
    padding: 5px;
    border: 1px solid black;
  }

  .ThreadActionQuoteTipBody {
    font-size: 16px;
    line-height: 20px;
  }
}
.RequestDetailClaimColumn {
  .ThreadEventWrapperTip {
    margin-top: 40px;
    padding: 0 10px;
  }
}
.ThreadEvent.ThreadEventWithUnknown .ThreadEventCard {
  margin-bottom: 10px;
  min-height: 70px;
}
.ThreadEventCardBody {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  padding: 10px;
}
.ThreadEventCardBodyUnthread {
  position: absolute;
  display: none;
  overflow: hidden;
  padding: 5px;
  top: 0;
  right: 0;
  z-index: 3;
  text-align: right;

  &.ThreadEventCardBodyUnthreadActive {
    display: block;
  }
}
.ThreadEventCardBodyUnthreadToggle {
  position: relative;
  display: inline-block;
  vertical-align: top;
  overflow: hidden;
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('../../images/dot-dot-dot.png');
  background-size: 18px 4px;
  background-color: white;
  height: 20px;
  width: 30px;
  border-radius: 2px;
  border: 1px solid #e3e3e3;
  transition: all 0.2s;

  &:hover {
    background-color: #eee;
    border-color: black;
  }
}
.ThreadEventCardBodyUnthreadOption {
  position: relative;
  display: block;
  overflow: hidden;
  cursor: pointer;
  background: #eee;
  border-radius: 3px;
  box-shadow: 0px 2px 4px rgba(31, 31, 31, 0.24);
  @include RoobertSemiBold;
  font-size: 14px;
  line-height: 20px;
  padding: 5px 10px;
  color: black;
  margin-top: 5px;
  transition: all 0.2s;

  &:hover {
    color: white;
    background-color: black;
  }

  &.ThreadEventCardBodyUnthreadOptionLoading {
    pointer-events: none;
    background: none;
    background-color: #a2f4c2;
    color: transparent;
    user-select: none;

    &::after {
      animation: spining 500ms infinite linear;
      border: 3px solid black;
      border-radius: 20px;
      border-right-color: transparent;
      border-top-color: transparent;
      content: '';
      display: block;
      height: 19px;
      width: 19px;
      left: 0;
      right: 0;
      margin: auto;
      top: 0;
      bottom: 0;
      position: absolute;
      text-align: center;
    }
  }
}
.ThreadEventCardBodyTitle {
  position: relative;
  display: block;
  overflow: hidden;
  font-size: 16px;
  line-height: 20px;
  @include RoobertSemiBold;
  color: black;
  text-align: left;
  // margin-bottom: 10px;
}
.ThreadEventCardBodySubtitle {
  position: relative;
  display: block;
  overflow: hidden;
  font-size: 13px;
  line-height: 15px;
  @include RoobertMedium;
  color: black;
  text-align: left;
  margin-bottom: 10px;
  opacity: 0.7;
}
.ThreadEventCardBodyContent {
  position: relative;
  display: block;
  overflow: hidden;
  @include ApercuLight;
  width: 100%;
  font-size: 16px;
  line-height: 20px;
  color: black;

  &.ThreadEventCardBodyContentExtra {
    border-top: 1px solid #e3e3e3;
    margin-top: 10px;
    padding-top: 10px;
  }
}
.ThreadEvent.EmailMessageThreadEvent {
  .ThreadEventCardBodyContent {
    overflow-x: auto;
  }
}
.ThreadEventCardBodyMeeting {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
}
.ThreadEventCardBodyMeetingTitle {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include RoobertMedium;
  font-size: 20px;
  line-height: 24px;
  color: black;
}
.ThreadEventCardBodyMeetingDate {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include RoobertRegular;
  font-size: 14px;
  line-height: 18px;
  color: #464646;
  margin-top: 5px;
}
.ThreadEventCardBodyMeetingLocation {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include RoobertRegular;
  font-size: 14px;
  line-height: 18px;
  color: #969696;
}
.ThreadEventCardBodyMeetingNotes {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include ApercuLight;
  font-size: 14px;
  line-height: 18px;
  color: black;
  margin-top: 5px;
  white-space: pre-wrap;
}
.ThreadEventCardBodyMeetingAction {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  margin-top: 10px;
}
.ThreadEventCardBodyMeetingActionAlert {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  padding: 10px;
  color: black;
  @include RoobertMedium;
  font-size: 13px;
  line-height: 15px;
  background: #fff481;
  text-align: center;
  margin-bottom: 10px;
  max-width: 420px;
}
.ThreadEventCardBodyMeetingActionAlertLink {
  color: black;
  @include RoobertMedium;
  font-size: 13px;
  line-height: 15px;
}
.ThreadEventCardBodyMeetingActionBtn {
  position: relative;
  display: inline-block;
  overflow: hidden;
  text-align: center;
  border-radius: 5px;
  width: 160px;
  @include RoobertBold;
  font-size: 12px;
  line-height: 15px;
  padding: 10px 0;
  background: #323232;
  color: white;
  transition: all 0.2s;
  cursor: pointer;
  max-width: 45%;

  &:hover {
    background-color: #a2f4c2;
    color: black;
  }

  &.ThreadEventCardBodyMeetingActionDecline {
    background: #e3e3e3;
    color: #535353;
    &:hover {
      background: #d3d3d3;
      color: black;
    }
    margin-left: 10px;
  }
}
.ThreadEventCardBodyMeetingStatus {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include RoobertBold;
  font-size: 14px;
  line-height: 30px;
  color: black;
  background-repeat: no-repeat;
  background-position: left center;
  background-image: url('../../images/meeting-canceled-icon.png');
  background-size: 16px 16px;
  background-color: transparent;
  padding-left: 25px;

  &.ThreadEventCardBodyMeetingStatusACCEPTED {
    background-image: url('../../images/meeting-active-icon.png');
  }
}

.ThreadEventCardBodyMeetingInvites {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  margin: 15px 0;
}
.ThreadEventCardBodyMeetingInvitePerson {
  @include RoobertMedium;
  font-size: 16px;
  line-height: 20px;
  color: black;
  margin-bottom: 5px;

  &:last-child {
    margin: 0;
  }
}
.ThreadEventCardTags {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  border-top: 1px solid #e3e3e3;
  padding: 10px 10px 5px 10px;
}
.ThreadEventCardTag {
  position: relative;
  display: inline-block;
  overflow: hidden;
  vertical-align: top;
  max-width: 100%;
  border-radius: 20px;
  color: black;
  background-color: white;
  @include RoobertMedium;
  font-size: 13px;
  line-height: 15px;
  padding: 5px 10px;
  margin-right: 5px;
  margin-bottom: 5px;
  white-space: nowrap;
  text-overflow: ellipsis;
  border: 1px solid black;
}
.ThreadEventCardFiles {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  border-top: 1px solid #e3e3e3;
  padding: 10px 10px 5px 10px;
}
.ThreadEventCardFile {
  position: relative;
  display: inline-block;
  overflow: hidden;
  vertical-align: top;
  max-width: 100%;
  border-radius: 2px;
  color: #535353;
  background-color: #e3e3e3;
  @include RoobertMedium;
  font-size: 12px;
  line-height: 15px;
  padding: 5px 10px;
  margin-right: 5px;
  margin-bottom: 5px;
  text-decoration: none;
  white-space: nowrap;
  text-overflow: ellipsis;
  transition: all 0.2s;

  &:hover {
    background-color: black;
    color: white;
  }
}
.ThreadEventCardFileAudio {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;

  audio {
    position: relative;
    display: block;
    overflow: hidden;
    width: 100%;
  }
}
.ThreadEventCardSendingError {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  background: $red-error;
  color: white;
  padding: 15px;
  @include RoobertSemiBold;
  font-size: 14px;
  line-height: 16px;
}
.ThreadEvents.ThreadEventsHuman .ThreadEventCardSendingError {
  display: none;
}
.ThreadEventCardRight {
  display: none;
  position: absolute;
  top: 0px;
  right: 0px;
  overflow: hidden;
  width: 25px;
}
.ThreadEventCardRightUser {
  display: block;
  overflow: hidden;
  position: relative;
}
.ThreadEventCardRightDate {
  display: block;
  overflow: hidden;
  position: relative;
  @include RoobertRegular;
  font-size: 12px;
  line-height: 25px;
  text-align: left;
  color: black;
  opacity: 0.45;
}
.ThreadEventBottom {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  text-align: left;
  height: 20px;
  padding: 0;
}
.ThreadEventBottomExpand {
  font-size: 14px;
  line-height: 20px;
  display: block;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0px;
  @include RoobertMedium;
  color: black;
  padding-right: 5px;
  padding-left: 5px;
  transition: all 0.2s;
  cursor: pointer;
  &:hover {
    background-color: rgba(255, 255, 255, 0.4);
  }
}
.ThreadEventBottomStatus {
  display: block;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0px;
  color: black;
  padding-right: 5px;
  padding-left: 5px;
  @include RoobertRegular;
  font-size: 13px;
  line-height: 20px;
  opacity: 0.45;
  // text-transform: lowercase;
}
.ThreadEvents.ThreadEventsHuman .ThreadEventBottomStatus {
  display: none;
}
.ThreadEventBottomReply {
  font-size: 14px;
  line-height: 20px;
  display: block;
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0px;
  @include RoobertMedium;
  color: black;
  background-repeat: no-repeat;
  background-position: left 5px center;
  background-image: url('../../images/reply-icon-black.png');
  background-size: 11px 10px;
  background-color: transparent;
  padding-right: 5px;
  padding-left: 20px;
  transition: all 0.2s;
  cursor: pointer;

  &:hover {
    background-color: rgba(255, 255, 255, 0.4);
  }
}
.ThreadEventCardQuoteTop {
  padding: 10px 10px;
  display: block;
  position: relative;
  width: 100%;
  overflow: hidden;
  padding-right: 100px;
  cursor: pointer;
}
.ThreadEventCardQuoteTopLeft {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  text-align: left;
}
.ThreadEventCardQuoteTopRight {
  right: 10px;
  position: absolute;
  display: block;
  overflow: hidden;
  text-align: right;
  width: 70px;
  top: 10px;
}
.ThreadEventCardQuoteTopTitle {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include RoobertMedium;
  font-size: 12px;
  line-height: 20px;
  color: white;
  opacity: 0.4;
}
.ThreadEventCardQuoteTopText {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include FkDisplayRegular;
  font-size: 16px;
  line-height: 20px;
  color: white;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ThreadEventCardQuoteMain {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
}
.ThreadEventCardQuoteBody {
  padding: 10px 10px;
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  border-top: 1px solid rgba(255, 255, 255, 0.24);
}
.ThreadEventCardQuoteBodyContent {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include ApercuLight;
  font-size: 16px;
  line-height: 20px;
  color: white;
}
.ThreadEventCardQuoteFooter {
  padding: 10px 10px;
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  border-top: 1px solid rgba(255, 255, 255, 0.24);
}
.ThreadEventCardQuoteFooterTitle {
  position: relative;
  display: block;
  overflow: hidden;
  @include RoobertMedium;
  font-size: 12px;
  line-height: 20px;
  color: white;
  opacity: 0.4;
}
.ThreadEventCardQuoteFooterText {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include FkDisplayRegular;
  font-size: 16px;
  line-height: 20px;
  color: white;
  min-height: 20px;
}
.ThreadEventCardQuoteRevisions {
  padding: 10px 10px;
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  border-top: 1px solid rgba(255, 255, 255, 0.24);
}
.ThreadEventCardQuoteRevisionsTitle,
.ThreadEventCardQuoteBodyTitle {
  position: relative;
  display: block;
  overflow: hidden;
  @include RoobertMedium;
  font-size: 12px;
  line-height: 20px;
  color: white;
  opacity: 0.4;
}
.ThreadEventCardQuoteRevisionsContent {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include ApercuLight;
  font-size: 16px;
  line-height: 20px;
  color: white;
}
.ThreadEvent.QuoteThreadEvent:not(.MeetingThreadEventInvite) {
  .ThreadEventCard.ThreadEventCardExpanded:not(.ThreadEventCardTip):not(.ThreadEventCardRequestRevisions) {
    background: white;
    .ThreadEventCardQuoteTopTitle {
      color: black;
    }
    .ThreadEventCardQuoteTopText {
      color: black;
    }
    .ThreadEventCardQuoteBodyContent {
      color: black;
    }
    .ThreadEventCardQuoteFooterTitle {
      color: black;
    }
    .ThreadEventCardQuoteFooterText {
      color: black;
    }
    .ThreadEventCardQuoteRevisionsTitle {
      color: black;
    }
    .ThreadEventCardQuoteBodyTitle {
      color: black;
    }
    .ThreadEventCardQuoteRevisionsContent {
      color: black;
    }
    .ThreadEventCardQuoteBody {
      border-top: 1px solid rgba(0, 0, 0, 0.1);
    }
    .ThreadEventCardQuoteFooter {
      border-top: 1px solid rgba(0, 0, 0, 0.1);
    }
    .ThreadEventCardQuoteRevisions {
      border-top: 1px solid rgba(0, 0, 0, 0.1);
    }
  }
}
.ThreadEvent.ThreadEventOwnerExpert {
  .ThreadEventCardWrapper {
    padding-right: 35px;
  }
  .ThreadEventCardRight {
    display: block;
  }
  .ThreadEventBottomReply {
    right: 35px;
  }
  &.PhoneCallThreadEvent,
  &.QuoteThreadEvent,
  &.TextMessageThreadEvent,
  &.MeetingThreadEvent,
  &.MessageThreadEvent {
    .ThreadEventTop {
      padding-right: 120px;
    }
    .ThreadEventTopDate {
      right: 35px;
    }
  }
  .QuoteAttachedThreadEventWrapper {
    padding-right: 35px;
  }
}

.ThreadEvent.MessageThreadEvent {
  .ThreadEventWrapper {
    width: unset;
    min-width: 300px;
  }
  .ThreadEventCardLeft {
    top: 7px;
  }
  .ThreadEventCardRight {
    top: 7px;
  }
  .ThreadEventCard {
    background-color: white;
    border-radius: 5px;
  }
}
.ThreadEvent.TextMessageThreadEvent {
  .ThreadEventWrapper {
    width: unset;
    min-width: 300px;
  }
  .ThreadEventCardLeft {
    top: 7px;
  }
  .ThreadEventCardRight {
    top: 7px;
  }
  .ThreadEventCard {
    background-color: #cbe0f1;
    border-radius: 20px;
  }
}
.ThreadEvent.RequestThreadEvent {
  .ThreadEventCard {
    border: 1px solid black;
  }
}
.ThreadEvent.QuoteThreadEvent,
.ThreadEvent.PhoneCallThreadEvent,
.ThreadEvent.MeetingThreadEvent {
  .ThreadEventWrapper {
    width: unset;
    min-width: 300px;
  }
  .ThreadEventCardLeft {
    top: 7px;
  }
  .ThreadEventCardRight {
    top: 7px;
  }
  &:not(.MeetingThreadEventInvite) {
    .ThreadEventCard:not(.ThreadEventCardTip):not(.ThreadEventCardRequestRevisions) {
      background-color: black;
      border-radius: 0px;

      .ThreadEventCardBodyContent {
        @include RoobertMedium;
        font-size: 16px;
        line-height: 20px;
        color: white;
      }
    }
  }
}

.ThreadEvent.QuoteThreadEvent {
  .ThreadEventCard:not(.ThreadEventCardTip):not(.ThreadEventCardRequestRevisions) {
    .ThreadEventCardBody {
      padding: 0;
    }
  }
}

@media only screen and (min-width: 450px) {
  .ThreadEvent.ThreadEventOwnerHuman {
    .ThreadEventWrapper {
      padding-right: 50px;
    }
  }
  .ThreadEvent.ThreadEventOwnerExpert {
    .ThreadEventWrapper {
      padding-left: 50px;
    }
  }
  .ThreadEvents.ThreadEventsHuman {
    .ThreadEvent.ThreadEventOwnerHuman {
      .ThreadEventWrapper {
        padding-left: 50px;
      }
    }
    .ThreadEvent.ThreadEventOwnerExpert {
      .ThreadEventWrapper {
        padding-right: 50px;
      }
    }
  }
}
@media only screen and (min-width: 640px) {
  .ThreadEvent.ThreadEventOwnerHuman {
    .ThreadEventWrapper {
      padding-right: 20px;
    }
  }
  .ThreadEvent.ThreadEventOwnerExpert {
    .ThreadEventWrapper {
      padding-left: 20px;
    }
  }
  .ThreadEvents.ThreadEventsHuman {
    .ThreadEvent.ThreadEventOwnerHuman {
      .ThreadEventWrapper {
        padding-left: 20px;
      }
    }
    .ThreadEvent.ThreadEventOwnerExpert {
      .ThreadEventWrapper {
        padding-right: 20px;
      }
    }
  }
}
@media only screen and (min-width: 767px) {
  .ThreadEvent {
    .ThreadEventWrapper {
      width: unset;
    }
  }
  .ThreadEvent.RequestThreadEvent {
    .ThreadEventWrapper {
      min-width: 350px;
    }
  }
  .ThreadEvent.EmailMessageThreadEvent {
    .ThreadEventWrapper {
      min-width: 350px;
    }
  }
  .ThreadEvent.QuoteThreadEvent {
    .ThreadEventWrapper {
      min-width: 350px;
    }
  }
  .ThreadEvent.MeetingThreadEvent.MeetingThreadEventInvite {
    .ThreadEventWrapper {
      min-width: 350px;
    }
  }
  .ThreadEvent.ThreadEventSolo,
  .ThreadEvent.ThreadEventSolo.RequestThreadEvent,
  .ThreadEvent.ThreadEventSolo.EmailMessageThreadEvent,
  .ThreadEvent.ThreadEventSolo.TextMessageThreadEvent,
  .ThreadEvent.ThreadEventSolo.PhoneCallThreadEvent,
  .ThreadEvent.ThreadEventSolo.QuoteThreadEvent,
  .ThreadEvent.ThreadEventSolo.MeetingThreadEvent,
  .ThreadEvent.ThreadEventSolo.MessageThreadEvent {
    .ThreadEventTop {
      padding-left: 35px;
    }
    .ThreadEventTopBubbles {
      display: none;
    }
    .ThreadEventCardLeft {
      display: block;
    }
  }
  .ThreadEvent.ThreadEventOwnerHuman {
    .ThreadEventWrapper {
      padding-right: 50px;
    }
    .ThreadEventCardWrapper {
      padding-left: 35px;
    }
    .ThreadEventTop {
      padding-left: 35px;
    }
    .ThreadEventBottom {
      padding-left: 35px;
    }
    .ThreadEventBottomExpand {
      left: 35px;
    }
  }
  .ThreadEvent.ThreadEventOwnerExpert {
    .ThreadEventTop {
      padding-right: 120px;
    }
    .ThreadEventWrapper {
      padding-left: 50px;
    }
    .ThreadEventTopDate {
      right: 35px;
    }
  }
  .ThreadEvents.ThreadEventsHuman {
    .ThreadEvent.ThreadEventOwnerHuman {
      .ThreadEventWrapper {
        padding-left: 50px;
      }
    }
    .ThreadEvent.ThreadEventOwnerExpert {
      .ThreadEventWrapper {
        padding-right: 50px;
      }
    }
  }
  .ThreadEvent.TextMessageThreadEvent,
  .ThreadEvent.PhoneCallThreadEvent,
  .ThreadEvent.MessageThreadEvent {
    .ThreadEventTopDate {
      display: none;
    }
    .ThreadEventCardRight {
      display: block;
    }
    .ThreadEventCardLeft {
      display: block;
    }
    &.ThreadEventOwnerExpert {
      .ThreadEventCardWrapper {
        padding-left: 80px;
      }
      .ThreadEventCardLeft {
        width: 70px;
      }
      .ThreadEventTop {
        padding-left: 80px;
      }
      .QuoteAttachedThreadEventWrapper {
        padding-left: 80px;
      }
    }
    &.ThreadEventOwnerHuman {
      .ThreadEventCardRight {
        width: 70px;
      }
      .ThreadEventCardWrapper {
        padding-right: 80px;
      }
      .ThreadEventBottomReply {
        right: 80px;
      }
    }
  }
  .ThreadEventGroup:hover {
    .ThreadEventCardBodyUnthread {
      display: block;
    }
  }
}

@media only screen and (min-width: 1000px) {
  .ThreadEventCardQuoteTop {
    padding: 10px 15px;
    padding-right: 100px;
  }
  .ThreadEventCardQuoteTopRight {
    right: 15px;
  }
  .ThreadEventCardQuoteBody {
    padding: 10px 15px;
  }
  .ThreadEventCardQuoteFooter {
    padding: 10px 15px;
    padding-left: 85px;
  }
  .ThreadEventCardQuoteFooterTitle {
    position: absolute;
    top: 10px;
    left: 15px;
  }
  .ThreadEventCardQuoteRevisions {
    padding: 10px 15px;
  }
  .ThreadEvent.RequestThreadEvent {
    .ThreadEventWrapper {
      min-width: 400px;
    }
  }
  .ThreadEvent.EmailMessageThreadEvent {
    .ThreadEventWrapper {
      min-width: 400px;
    }
  }
  .ThreadEvent.QuoteThreadEvent {
    .ThreadEventWrapper {
      min-width: 400px;
    }
  }
  .ThreadEvent.MeetingThreadEvent.MeetingThreadEventInvite {
    .ThreadEventWrapper {
      min-width: 400px;
    }
  }
}

@media only screen and (min-width: 1225px) {
  .ThreadEvent.RequestThreadEvent {
    .ThreadEventWrapper {
      max-width: 600px;
    }
  }
  .ThreadEvent.EmailMessageThreadEvent {
    .ThreadEventWrapper {
      max-width: 775px;
    }
  }
  .ThreadEvent.TextMessageThreadEvent {
    .ThreadEventWrapper {
      max-width: 600px;
    }
  }
  .ThreadEvent.MessageThreadEvent {
    .ThreadEventWrapper {
      max-width: 650px;
    }
  }
  .ThreadEvent.PhoneCallThreadEvent {
    .ThreadEventWrapper {
      max-width: 600px;
    }
  }
  .ThreadEvent.QuoteThreadEvent {
    .ThreadEventWrapper {
      max-width: 750px;
    }
  }
  .ThreadEvent.MeetingThreadEvent {
    .ThreadEventWrapper {
      max-width: 650px;
    }
  }
}
