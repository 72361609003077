@import '../Common';

.TextEditor {
}
.TextEditor .ThreadEditorRowTitle {
  width: 30px;
}
.TextEditor .ThreadEditorCard {
  border-radius: 15px;
  background-color: #cbe0f1;
}
.TextEditor .ThreadEditorBodySwitchToggle {
  background-color: #eee;
}
.TextEditorWrap {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  padding: 20px 15px;
}
.TextEditorTextarea {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  outline: none;
  border: none;
  @include ApercuLight;
  font-size: 16px;
  line-height: 20px;
  text-align: left;
  color: black;
  background: transparent;
  padding: 0;
  max-height: 60vh;
}

.TextEditor .ThreadEditorActionBtn.ThreadEditorActionSend {
  border-radius: 20px;
}
@media only screen and (min-width: 375px) {
}
@media only screen and (min-width: 500px) {
}
@media only screen and (min-width: 640px) {
}
@media only screen and (min-width: 750px) {
}
@media only screen and (min-width: 1000px) {
  .TextEditor .ThreadEditorRow {
    padding-left: 40px;
  }
  .TextEditor .ThreadEditorCardWrapper {
    padding-left: 40px;
  }
  .TextEditor .ThreadEditorRecipientsPopoverWrapper {
    left: 40px;
  }
}
@media only screen and (min-width: 1150px) {
}
