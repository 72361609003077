@import '../Common';

.ExpertCollabView {
}

.CollabScreenSplit {
  display: block;
  position: relative;
  overflow: hidden;
  width: 100%;

  &.CollabScreenSplitLeft {
    display: block;
    position: relative;
    overflow: hidden;
    width: 100%;
  }
  &.CollabScreenSplitRight {
    display: block;
    position: relative;
    overflow: hidden;
    width: 100%;
  }
}

.CollabScreenContent .DashboardSectionAligned {
  margin-top: 10px;
}
.CollabInvites {
  display: block;
  position: relative;
  overflow: hidden;
  width: 100%;
}
.CollabInvitesHeader {
  display: block;
  position: relative;
  overflow: hidden;
  width: 100%;
  @include RoobertSemiBold;
  color: black;
  font-size: 20px;
  line-height: 25px;
  margin-bottom: 10px;
}
.CollabInvitesList {
  display: block;
  position: relative;
  overflow: hidden;
  width: 100%;
}
.CollabInvitesListItem {
  display: block;
  position: relative;
  overflow: hidden;
  width: 100%;
  border-top: 1px solid #d5d5d5;
  padding: 10px 0;
}
.CollabInvitesListName {
  display: block;
  position: relative;
  overflow: hidden;
  width: 100%;
  @include RoobertMedium;
  font-size: 18px;
  line-height: 25px;
  color: #02071c;
}
.CollabInvitesListTitle {
  display: block;
  position: relative;
  overflow: hidden;
  width: 100%;
  @include ApercuRegular;
  font-size: 16px;
  line-height: 20px;
  color: #02071c;
}
.CollabInvitesListEmail {
  display: block;
  position: relative;
  overflow: hidden;
  width: 100%;
  @include ApercuLight;
  font-size: 14px;
  line-height: 20px;
  color: #02071c;
  opacity: 0.32;
}
.CollabSearch {
  display: block;
  position: relative;
  overflow: hidden;
  width: 100%;
  background: white;
  padding: 5px 10px;
  border-radius: 5px;
  margin-bottom: 30px;
  max-width: 500px;
}
.CollabSearchHeader {
  display: block;
  position: relative;
  overflow: hidden;
  width: 100%;
}
.CollabSearchHeaderTitle {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include RoobertSemiBold;
  font-size: 20px;
  line-height: 35px;
  color: black;
  margin-bottom: 10px;
}
.CollabSearchHeaderBtn {
  display: inline-block;
  position: absolute;
  bottom: 10px;
  right: 0px;
  overflow: hidden;
  vertical-align: middle;
  line-height: 20px;
  font-size: 15px;
  @include RoobertSemiBold;
  padding: 5px 20px;
  transition: all 0.2s;
  background: black;
  color: white;
  border-radius: 3px;
  cursor: pointer;
  float: right;

  &:hover {
    background-color: #a2f4c2;
    color: black;
  }
}
.CollabSearchInput {
  display: block;
  position: relative;
  overflow: hidden;
  width: 100%;
  margin-bottom: 15px;
}
.CollabSearchInputTitle {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include RoobertSemiBold;
  font-size: 13px;
  line-height: 15px;
  color: black;

  span {
    position: absolute;
    display: block;
    overflow: hidden;
    top: 0;
    right: 0;
    @include ApercuRegular;
    font-size: 13px;
    line-height: 15px;
    color: black;
    opacity: 0.55;
  }
}
.CollabSearchInputField {
  display: block;
  position: relative;
  overflow: hidden;
  width: 100%;
}
.CollabSearchInputFieldBox {
  margin-top: 5px;
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  @include ApercuRegular;
  font-size: 15px;
  padding: 0 10px;
  color: black;
  background: transparent;
  outline: none;
  border-radius: 5px;
  border: 2px solid black;
  height: 40px;
}
.CollabExpertList {
  display: block;
  position: relative;
  overflow: hidden;
  width: 100%;
}
.CollabExpertListItem {
  display: block;
  position: relative;
  overflow: hidden;
  width: 100%;
  max-width: 600px;
}
.CollabExpertListItemLink {
  padding-left: 140px;
  min-height: 180px;
  display: block;
  position: relative;
  overflow: hidden;
  width: 100%;
  text-decoration: none;
  color: black;
  margin-bottom: 70px;
}
.CollabExpertListItemPic {
  width: 120px;
  height: 180px;
  display: block;
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  border-radius: 10px;
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
}
.CollabExpertListItemHeader {
  margin-bottom: 10px;
  display: block;
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 5px;
}
.CollabExpertListItemName {
  display: block;
  position: relative;
  overflow: hidden;
  @include RoobertMedium;
  font-size: 18px;
  line-height: 25px;
  color: #02071c;
}
.CollabExpertListItemTitle {
  display: block;
  position: relative;
  overflow: hidden;
  @include ApercuRegular;
  font-size: 14px;
  line-height: 20px;
  color: #02071c;
}
.CollabExpertListItemLocation {
  display: block;
  position: relative;
  overflow: hidden;
  @include ApercuLight;
  font-size: 12px;
  line-height: 15px;
  color: #02071c;
  opacity: 0.32;
}
.CollabExpertListItemBody {
  display: block;
  position: relative;
  overflow: hidden;
  width: 100%;
}

.CollabExpertListItemBodyText {
  display: block;
  position: relative;
  overflow: hidden;
  width: 100%;
  @include ApercuRegular;
  font-size: 14px;
  line-height: 20px;
  color: #02071c;
  margin-bottom: 20px;
}
.CollabExpertListItemSkills {
  position: relative;
  overflow: hidden;
  display: inline-block;
  vertical-align: top;
  width: 48%;
  min-width: 130px;
  margin-right: 1%;
}
.CollabExpertListItemSkillsHeader {
  display: block;
  position: relative;
  overflow: hidden;
  width: 100%;
  @include RoobertSemiBold;
  font-size: 10px;
  line-height: 15px;
  color: black;
  opacity: 0.5;
  text-transform: uppercase;
  letter-spacing: 0.03em;
  margin-bottom: 10px;
}
.CollabExpertListItemSkillsList {
  position: relative;
  overflow: hidden;
  display: block;
  width: 100%;
}
.CollabExpertListItemSkillsListItem {
  display: block;
  position: relative;
  overflow: hidden;
  width: 100%;
  @include ApercuRegular;
  font-size: 10px;
  line-height: 15px;
  color: #02071c;
  margin-bottom: 10px;
}

.CollabExpertListItemSlack {
  display: block;
  position: relative;
  overflow: hidden;
  width: 100%;
  margin-top: 15px;
}
.CollabExpertListItemSlackBtn {
  display: inline-block;
  position: relative;
  overflow: hidden;
  vertical-align: middle;
  font-size: 14px;
  line-height: 20px;
  @include RoobertSemiBold;
  padding: 5px 20px;
  transition: all 0.2s;
  background: black;
  color: white;
  border-radius: 3px;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    background-color: #a2f4c2;
    color: black;
  }
}

@media only screen and (min-width: 1000px) {
  .CollabExpertListItemLink {
    padding-left: 170px;
    min-height: 220px;
  }
  .CollabExpertListItemPic {
    width: 150px;
    height: 220px;
  }

  .CollabExpertListItemName {
    display: inline-block;
    vertical-align: middle;
    margin-right: 30px;
  }
  .CollabExpertListItemTitle {
    display: inline-block;
    vertical-align: middle;
    margin-right: 30px;
  }
  .CollabExpertListItemLocation {
    display: inline-block;
    vertical-align: middle;
  }

  .CollabExpertListItemHeader {
    margin-bottom: 25px;
  }
}

@media only screen and (min-width: 1150px) {
  .CollabScreenContent .DashboardSectionAligned {
    padding-right: 400px;
  }
  .CollabScreenSplit {
    &.CollabScreenSplitLeft {
    }
    &.CollabScreenSplitRight {
      position: absolute;
      top: 0;
      right: 50px;
      width: 300px;
    }
  }
}
