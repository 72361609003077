@import '../Common';

.ClientsScreen {
}
.ClientsScreenContent {
}

.ClientSummary {
  width: 220px;
  position: relative;
  display: inline-block;
  // overflow: hidden;
  margin-right: 10px;
  white-space: normal;
  padding-top: 10px;
}
.ClientSummaryUnreadTag {
  position: absolute;
  top: 0px;
  right: 10px;
  display: inline-block;
  vertical-align: top;
  overflow: hidden;
  line-height: 20px;
  padding: 0 15px;
  @include RoobertSemiBold;
  font-size: 14px;
  letter-spacing: 0.04em;
  color: black;
  background: #94f5f8;
  letter-spacing: 2px;
  z-index: 2;
  border-radius: 2px;
}
.ClientSummaryBody {
  height: 120px;
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  border-radius: 0px;
  background: white;
  text-align: left;
  color: black;
  margin-bottom: 5px;
  padding: 15px;
  text-decoration: none;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
}
.ClientSummary {
  &.ClientSummaryAlert,
  &.ClientSummarySearch,
  &.ClientSummaryRecent {
    .ClientSummaryBody {
      height: 95px;

      &.ClientSummaryBodyExtended {
        height: 110px;
      }
    }
  }
  &.ClientSummaryDangerZone {
    .ClientSummaryBody {
      height: 165px;
    }
  }
}
.ClientSummaryTitle {
  font-size: 16px;
  line-height: 20px;
  max-height: 40px;
  position: relative;
  display: block;
  overflow: hidden;
  @include RoobertMedium;

  &.ClientSummaryTitleClip {
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
.ClientSummarySubTitle {
  font-size: 11px;
  line-height: 15px;
  @include RoobertMedium;
  position: relative;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  opacity: 0.5;

  &.ClientSummarySubTitleAlert {
    color: red;
  }
}
.ClientSummaryAccountTier {
  position: absolute;
  top: 0px;
  right: 0px;
  background-color: rgb(255, 201, 255);
  padding: 5px;
  @include FkDisplayRegular;
  font-size: 12px;
  line-height: 15px;
}
.ClientSummaryDangerDesc {
  display: block;
  position: relative;
  overflow: hidden;
  width: 100%;
  border-top: 1px solid black;
  margin-top: 10px;
  padding: 10px 0;
  @include FkDisplayRegular;
  font-size: 12px;
  line-height: 15px;
}
.ClientSummaryLeftCorner {
  @include FkDisplayRegular;
  font-size: 12px;
  line-height: 15px;
  position: absolute;
  display: block;
  overflow: hidden;
  bottom: 10px;
  right: 60%;
  left: 15px;
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ClientSummaryTaggerCurrent {
  position: absolute;
  display: block;
  overflow: hidden;
  bottom: 10px;
  left: 15px;
  width: 100px;
  height: 25px;
  border-radius: 4px;
  background: #d6f0d7;
  @include RoobertSemiBold;
  font-size: 14px;
  line-height: 25px;
  text-align: center;
  color: #000;

  &.ClientSummaryTaggerCurrentRight {
    left: unset;
    right: 15px;
  }

  &.ClientSummaryTaggerCurrentWARM {
    background-color: #f7eab4;
  }
  &.ClientSummaryTaggerCurrentCOLD {
    background-color: #e0e0e0;
  }
  &.ClientSummaryTaggerCurrentLOST {
    background-color: #efdbdb;
  }
}
.ClientSummaryStatusLine {
  position: absolute;
  display: block;
  overflow: hidden;
  height: 3px;
  width: 40px;
  border-radius: 5px;
  bottom: 32px;
  right: 15px;
}
.ClientSummaryAssignedTo {
  position: absolute;
  display: block;
  overflow: hidden;
  bottom: 10px;
  left: 15px;
  font-size: 16px;
  line-height: 25px;
  padding-left: 30px;
  @include RoobertMedium;
  color: red;
  background-repeat: no-repeat;
  background-position: center left;
  background-size: 25px 25px;

  &.ClientSummaryAssignedToRICHARD {
    background-image: url('../../images/richard-pic.jpeg');
  }

  &.ClientSummaryAssignedToLEIGH {
    background-image: url('../../images/leigh-pic.jpeg');
  }
  &.ClientSummaryAssignedToTIM {
    background-image: url('../../images/tim-pic.jpeg');
  }
  &.ClientSummaryAssignedToPIQUE {
    background-image: url('../../images/pique-pic.png');
  }
  &.ClientSummaryAssignedToROBIN {
    background-image: url('../../images/robin-pic.jpg');
  }
  &.ClientSummaryAssignedToGAYLE {
    background-image: url('../../images/gayle-pic.jpeg');
  }
  &.ClientSummaryAssignedToCHARLIE {
    background-image: url('../../images/charlie-pic.jpeg');
  }

  &.ClientSummaryAssignedToDAVE {
    background-image: url('../../images/dave-pic.png');
  }
}
.ClientSummaryStatusTag {
  @include FkDisplayRegular;
  font-size: 12px;
  line-height: 15px;
  position: absolute;
  display: block;
  overflow: hidden;
  bottom: 10px;
  right: 15px;
  max-width: 80%;
  text-align: right;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: white;
  background: pink;
  padding: 0 5px;

  &.ClientSummaryStatusTagPENDING {
    background: green;
  }
  &.ClientSummaryStatusTagHQ {
    background: black;
  }
  &.ClientSummaryStatusTagACTIVE {
    background: #2d5858;
  }
  &.ClientSummaryStatusTagBAD {
    background: orange;
  }
  &.ClientSummaryStatusTagBACK {
    background: orange;
  }
  &.ClientSummaryStatusTagCHURNED {
    background: red;
  }
  &.ClientSummaryStatusTagWINBACK {
    background: red;
  }
  &.ClientSummaryStatusTagNEW {
    background: green;
  }
  &.ClientSummaryStatusTagNO-MATCH {
    background: red;
  }
  &.ClientSummaryStatusTagNO-MATCH-QUOTE {
    background: red;
  }
  &.ClientSummaryStatusTagBLACKLIST {
    background: red;
  }
  &.ClientSummaryStatusTagINACTIVE {
    background: red;
  }
  &.ClientSummaryStatusTagADMIN {
    background: black;
  }
  &.ClientSummaryStatusTagSUPPORT {
    background: black;
  }
  &.ClientSummaryStatusTagSUPPORT-CASE {
    background: red;
  }
  &.ClientSummaryStatusTagBRAND {
    background: red;
  }
  &.ClientSummaryStatusTagSHOPIFY {
    background: #2d5858;
  }
  &.ClientSummaryStatusTagOTHER {
    background: red;
  }
}
.ClientSummaryRightCorner {
  @include FkDisplayRegular;
  font-size: 12px;
  line-height: 15px;
  position: absolute;
  display: block;
  overflow: hidden;
  bottom: 10px;
  right: 15px;
  left: 40%;
  text-align: right;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ClientSummaryFooter {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  text-align: left;
  padding: 0 5px;
}
.ClientSummaryFooterText {
  font-size: 12px;
  line-height: 15px;
  display: block;
  overflow: hidden;
  position: relative;
  @include RoobertMedium;
  color: black;
  opacity: 0.5;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ClientSummaryArchiveCover {
  position: absolute;
  display: none;
  overflow: hidden;
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;
  z-index: 6;
}
.ClientSummaryArchive {
  position: absolute;
  display: block;
  overflow: hidden;
  top: 5px;
  right: 5px;
  padding: 5px 10px;
  @include FkDisplayRegular;
  font-size: 12px;
  line-height: 15px;
  color: black;
  border: 1px solid black;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    color: white;
    background: black;
  }
}

.ClientSummary.ClientSummaryLeadArchive {
  .ClientSummaryBody {
    box-shadow: none;
    background: rgba(255, 255, 255, 0.38);
  }
  .ClientSummaryTitle {
    opacity: 0.38;
  }
  .ClientSummarySubTitle {
    opacity: 0.38;
  }
  .ClientSummaryLeftCorner {
    opacity: 0.38;
  }
  .ClientSummaryStatusLine {
    opacity: 0.38;
  }
  .ClientSummaryRightCorner {
    opacity: 0.38;
  }
  .ClientSummaryArchiveCover {
    display: block;
  }
}

.ClientsScreenSearch {
  position: absolute;
  display: inline-block;
  vertical-align: top;
  overflow: hidden;
  z-index: 3;
  height: 50px;
  top: 0px;
  right: 0px;
  padding: 10px;
  max-width: 320px;
}
.ClientsScreenSearchBar {
  display: block;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  border-radius: 30px;
  pointer-events: all;
  transition: all 0.2s;

  &:hover {
    background-color: white;
  }
}
.ClientsScreenSearchBarInput {
  display: none;
  position: relative;
  overflow: hidden;
  width: 100%;
  @include RoobertMedium;
  padding: 5px 10px;
  font-size: 16px;
  color: black;
  height: 100%;
  border: none;
  outline: none;
  background: transparent;
}
.ClientsScreenSearchToggle {
  position: relative;
  display: inline-block;
  vertical-align: top;
  overflow: hidden;
  float: right;
  background-image: url('../../images/search-icon-black.png');
  background-repeat: no-repeat;
  background-position: center right 10px;
  background-size: 15px 15px;
  border-radius: 30px;
  padding: 5px 35px 5px 15px;
  @include RoobertSemiBold;
  font-size: 14px;
  line-height: 20px;
  color: black;
  color: rgba(0, 0, 0, 0.8);
  cursor: pointer;
}
.ClientsScreenSearch.ClientsScreenSearchActive {
  width: 100%;

  .ClientsScreenSearchBar {
    background-color: white;
    padding-right: 30px;
  }

  .ClientsScreenSearchBarInput {
    display: block;
  }
  .ClientsScreenSearchToggle {
    display: block;
    vertical-align: unset;
    position: absolute;
    top: 0px;
    right: 0px;
    padding-right: 15px;
    background-size: 12px 12px;
    background-image: url('../../images/search-close-black.png');
    text-indent: -9999px;
  }
}

@media only screen and (min-width: 600px) {
}
@media only screen and (min-width: $base-sidebar-breakpoint) {
  .ClientSummary {
    width: 260px;
  }
  .ClientSummaryBody {
    height: 130px;
  }
  .ClientSummary {
    &.ClientSummaryAlert,
    &.ClientSummarySearch,
    &.ClientSummaryRecent {
      .ClientSummaryBody {
        height: 95px;
        &.ClientSummaryBodyExtended {
          height: 120px;
        }
      }
    }
    &.ClientSummaryDangerZone {
      .ClientSummaryBody {
        height: 155px;
      }
    }
  }
  .ClientSummaryTitle {
    font-size: 17px;
    line-height: 20px;
    max-height: 40px;
    margin-bottom: 6px;
  }
  .ClientSummarySubTitle {
    font-size: 12px;
  }
  .ClientSummaryLeftCorner {
    font-size: 13px;
  }
  .ClientSummaryRightCorner {
    font-size: 13px;
  }
  .ClientSummaryFooter {
    padding: 0 10px;
  }
  .ClientSummaryFooterText {
    @include RoobertSemiBold;
    font-size: 13px;
    line-height: 17px;
  }
}
