@import '../Common';

.HumanSupportViewScreenContent {
  max-width: 650px;
  margin-bottom: 100px;
}

.SupportInfo {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  margin-top: 20px;
}
.SupportInfoSection {
  display: inline-block;
  vertical-align: top;
  position: relative;
  overflow: hidden;
  width: 30%;
  margin-right: 5%;
  margin-bottom: 20px;
  min-width: 180px;

  &:last-child {
    margin-right: 0;
  }
}
.SupportInfoSectionTitle {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  @include RoobertSemiBold;
  font-size: 16px;
  line-height: 20px;
  color: black;
}
.SupportInfoSectionLine {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  @include RoobertRegular;
  font-size: 14px;
  line-height: 20px;
  color: black;
  text-decoration: none;
}
a.SupportInfoSectionLine:hover {
  color: #2d5858;
}

@media only screen and (min-width: 400px) {
}
