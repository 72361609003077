@import '../Common';

.ExpertRequestDetail {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  height: 100%;
  padding-top: 50px;
}
.RequestDetailHeader {
  padding: 10px 10px 10px 40px;
  position: absolute;
  display: block;
  overflow: hidden;
  top: 0px;
  left: 0px;
  height: 50px;
  width: 100%;
  z-index: 3;
}
.RequestDetailHeaderClose,
.RequestDetailHeaderBack {
  left: 10px;
  position: absolute;
  display: block;
  overflow: hidden;
  height: 30px;
  top: 10px;
  background-image: url('../../images/search-close-black.png');
  background-repeat: no-repeat;
  background-position: left 5px center;
  background-size: 18px 18px;
  transition: background-color 0.2s;
  border-radius: 3px;
  padding-left: 35px;
  padding-right: 5px;
  @include RoobertMedium;
  font-size: 22px;
  line-height: 30px;
  color: black;
  text-decoration: none;
  cursor: pointer;
  max-width: 100%;

  &:hover {
    background-color: rgba(255, 255, 255, 0.4);
  }
}
.RequestDetailHeaderBack {
  background-image: url('../../images/left-arrow-black.png');
  background-size: 12px 20px;
  padding-left: 30px;
}
.RequestDetailHeaderNext {
  position: absolute;
  right: 10px;
  top: 10px;
  display: block;
  overflow: hidden;
  @include RoobertSemiBold;
  font-size: 16px;
  line-height: 20px;
  padding: 5px 15px;
  background: transparent;
  border: 1px solid black;
  border-radius: 20px;
  color: black;
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    color: white;
    background: black;
  }
}
.RequestDetailHeaderClock {
  position: absolute;
  right: 90px;
  top: 15px;
  display: block;
  overflow: hidden;
  border-radius: 2px;
  background: rgba(255, 255, 255, 0.5);
  @include FkDisplayRegular;
  font-size: 13px;
  line-height: 14px;
  padding: 3px 0px;
  width: 50px;
  text-align: center;
  color: black;

  &.RequestDetailHeaderClockApprove {
    right: 150px;
  }
  &.RequestDetailHeaderClockRight {
    right: 10px;
  }
}
.RequestDetailBody {
  position: relative;
  display: block;
  overflow-x: hidden;
  width: 100%;
  height: 100%;
}
.RequestDetailView {
  padding-top: 5vh;
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  text-align: center;
}
.RequestDetailViewColumn {
  position: relative;
  display: inline-block;
  vertical-align: top;
  overflow: hidden;
  width: 100%;
  max-width: 450px;
  text-align: left;

  &.RequestDetailViewColumnRequestTop {
  }
  &.RequestDetailViewColumnInfo {
    padding: 0 15px;
  }
  &.RequestDetailViewColumnRequestMiddle {
    max-width: 450px;
    display: none;
  }
  &.RequestDetailViewColumnActivity {
    display: none;
    padding: 0 15px;
  }
}
.RequestDetailViewActivityUnder {
  display: block;
}

.RequestDetailViewCard {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  text-align: left;
  margin-bottom: 25px;
  padding: 0 10px;

  &.RequestDetailViewCardSkipping {
  }
}
.RequestDetailViewCardTop {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  padding-right: 60px;
  padding-left: 5px;
  height: 30px;
}
.RequestDetailViewCardTopWhySkip {
  @include RoobertMedium;
  font-size: 16px;
  line-height: 30px;
  color: black;
  display: block;
  overflow: hidden;
  position: relative;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.RequestDetailViewCardTopText {
  @include RoobertMedium;
  font-size: 20px;
  line-height: 30px;
  color: black;
  display: block;
  overflow: hidden;
  position: relative;
  white-space: nowrap;
  text-overflow: ellipsis;

  &.RequestDetailViewCardTopTextAlt {
    display: none;
  }
}
.RequestDetailViewCardTopSkip {
  position: absolute;
  top: 5px;
  right: 0px;
  font-size: 14px;
  line-height: 20px;
  @include RoobertMedium;
  color: black;
  cursor: pointer;
  padding-left: 5px;
  padding-right: 20px;
  background-repeat: no-repeat;
  background-position: right 5px center;
  background-image: url('../../images/leads-skip-icon.png');
  background-size: 10px 10px;
  background-color: transparent;
  transition: background-color 0.2s;

  &:hover {
    background-color: rgba(255, 255, 255, 0.4);
  }
}
.RequestDetailViewCardBody {
  padding: 10px 0px;
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  border-radius: 5px;
  background: white;
  text-align: left;
  margin-bottom: 5px;
  text-decoration: none;
  min-height: 50px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
}
.RequestDetailViewCard.RequestDetailViewCardSkipping {
  .RequestDetailViewCardBody {
    min-height: 150px;
  }
}
.RequestDetailViewCardBodyHqApprove {
  position: relative;
  display: inline-block;
  vertical-align: top;
  overflow: hidden;
  line-height: 25px;
  padding: 0 15px;
  @include RoobertSemiBold;
  font-size: 13px;
  letter-spacing: 0.04em;
  color: #193415;
  z-index: 2;
  border-radius: 6px;
  background: #a4efac;
  margin-top: 10px;
  margin-left: 10px;
}
.RequestDetailViewCardBodyPro {
  position: relative;
  display: inline-block;
  vertical-align: top;
  overflow: hidden;
  line-height: 25px;
  padding: 0 15px;
  @include RoobertSemiBold;
  font-size: 13px;
  letter-spacing: 0.04em;
  color: #0619ac;
  z-index: 2;
  border-radius: 6px;
  background: #ccd1fa;
  margin-top: 10px;
  margin-left: 10px;
}
.RequestDetailViewCardBodyAgency {
  position: relative;
  display: inline-block;
  vertical-align: top;
  overflow: hidden;
  line-height: 25px;
  padding: 0 15px;
  @include RoobertSemiBold;
  font-size: 13px;
  letter-spacing: 0.04em;
  color: black;
  z-index: 2;
  border-radius: 6px;
  background: #ffde86;
  margin-top: 10px;
  margin-left: 10px;
}
.RequestDetailViewCardBodyVIP {
  position: relative;
  display: inline-block;
  vertical-align: top;
  overflow: hidden;
  line-height: 25px;
  padding: 0 15px;
  @include RoobertSemiBold;
  font-size: 13px;
  letter-spacing: 0.04em;
  color: black;
  z-index: 2;
  border-radius: 6px;
  background: #d9cbff;
  margin-top: 10px;
  margin-left: 10px;
}
.RequestDetailViewCardBodyTags {
  position: relative;
  display: block;
  overflow: hidden;
  max-width: 400px;
  padding: 0 20px;
}
.RequestDetailViewCardBodyField {
  padding: 0 10px;
  position: relative;
  display: block;
  overflow: hidden;
  font-size: 13px;
  line-height: 15px;
  @include RoobertMedium;
  color: black;
  text-align: left;
  margin-bottom: 5px;
  opacity: 0.5;
}
.RequestDetailViewCardBodyFieldValue {
  padding: 0 10px;
  position: relative;
  display: block;
  overflow: hidden;
  font-size: 14px;
  line-height: 20px;
  @include RoobertMedium;
  color: black;
  text-align: left;
  margin-bottom: 5px;
}
.RequestDetailViewCardBodyFieldSelect {
  margin-left: 10px;
  position: relative;
  display: block;
  overflow: hidden;
  // width: 100%;
  @include ApercuRegular;
  font-size: 15px;
  line-height: 20px;
  padding: 7px 5px;
  color: black;
  background: transparent;
  border-radius: 3px;
  outline: none;
  border: 1px solid #eaeaea;
}
.RequestDetailViewCardBodyTags {
  .RequestDetailViewCardBodyFieldSelect {
    margin: 0;
  }
  .ThreadDetailSidebarAdminFormInput {
    max-width: 100px;
  }
}
.RequestDetailViewCardBodyTitle {
  padding: 0 10px;
  position: relative;
  display: block;
  overflow: hidden;
  font-size: 16px;
  line-height: 20px;
  @include RoobertSemiBold;
  color: black;
  text-align: left;
  // margin-bottom: 10px;
}
.RequestDetailViewCardBodySubtitle {
  padding: 0 10px;
  position: relative;
  display: block;
  overflow: hidden;
  font-size: 13px;
  line-height: 15px;
  @include RoobertMedium;
  color: black;
  text-align: left;
  margin-bottom: 10px;
  opacity: 0.7;
}
.RequestDetailViewCardBodyContent {
  padding: 0 10px;
  position: relative;
  display: block;
  overflow: hidden;
  @include ApercuRegular;
  width: 100%;
  font-size: 16px;
  line-height: 20px;
  color: #070707;

  &.RequestDetailViewCardBodyContentExtra {
    border-top: 1px solid #e3e3e3;
    margin-top: 10px;
    padding-top: 10px;
  }
}
.RequestDetailViewCardBodyInternal {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  border-top: 1px solid #e3e3e3;
  margin-top: 10px;
  padding-top: 10px;
}
.ThreadEvents.ThreadEventsHuman .RequestDetailViewCardBodyInternal {
  display: none;
}
.RequestDetailViewCardBodyFiles {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  border-top: 1px solid #e3e3e3;
  padding: 5px 10px 0px 10px;
  margin-top: 10px;
}
.RequestDetailViewCardBodyFile {
  position: relative;
  display: inline-block;
  overflow: hidden;
  vertical-align: top;
  max-width: 100%;
  border-radius: 2px;
  color: #535353;
  background-color: #e3e3e3;
  @include RoobertMedium;
  font-size: 12px;
  line-height: 15px;
  padding: 5px 10px;
  margin-right: 5px;
  margin-top: 5px;
  text-decoration: none;
  white-space: nowrap;
  text-overflow: ellipsis;
  transition: all 0.2s;

  &:hover {
    background-color: black;
    color: white;
  }
}
.RequestDetailViewCardBodySkipFlow {
  padding: 10px;
  position: absolute;
  display: block;
  overflow-x: hidden;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.45);
  z-index: 3;
}
.RequestDetailViewCardBodySkipOption {
  @include RoobertMedium;
  font-size: 14px;
  line-height: 20px;
  padding: 5px 10px;
  display: inline-block;
  vertical-align: top;
  position: relative;
  overflow: hidden;
  color: black;
  border-radius: 20px;
  background-color: white;
  margin-right: 5px;
  margin-bottom: 5px;
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    background-color: black;
    color: white;
  }
}
.RequestDetailViewCardFooter {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 20px;
  padding: 0;
}
.RequestDetailViewCardFooterRight {
  display: block;
  overflow: hidden;
  position: absolute;
  top: 0px;
  right: 5px;
  line-height: 20px;
  font-size: 13px;
  @include RoobertMedium;
  color: black;
  opacity: 0.5;
}

.RequestDetailViewSection {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  margin-bottom: 35px;
}
.RequestDetailViewSectionTitle {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  @include RoobertMedium;
  line-height: 20px;
  font-size: 13px;
  color: black;
  opacity: 0.5;
}
.RequestDetailViewSectionHuge {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  @include RoobertSemiBold;
  line-height: 25px;
  font-size: 20px;
  color: black;
}
.RequestDetailViewSectionDesc {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include ApercuLight;
  color: black;
  font-size: 14px;
  line-height: 18px;
}
.RequestDetailViewSectionWebsite {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
}
.RequestDetailViewSectionWebsiteLink {
  position: relative;
  display: inline-block;
  vertical-align: top;
  overflow: hidden;
  @include RoobertMedium;
  color: black;
  font-size: 16px;
  line-height: 25px;
  text-decoration: none;
  background-repeat: no-repeat;
  background-position: right 5px center;
  background-image: url('../../images/view-website-icon.png');
  background-size: 10px 10px;
  padding-right: 20px;
  transition: color 0.2s;

  &:hover {
    color: $green;
  }
}
.RequestDetailViewSectionWebsiteLinkCopy {
  display: inline-block;
  vertical-align: middle;
  margin-top: 5px;
  height: 10px;
  width: 10px;
  background-repeat: no-repeat;
  background-image: url('../../images/copy-icon.png');
  background-size: 10px 10px;
  cursor: pointer;
  transition: opacity 0.2s;

  &:hover {
    opacity: 0.45;
  }
}
.RequestDetailViewSectionNormal {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  @include RoobertMedium;
  line-height: 20px;
  font-size: 16px;
  color: black;
  letter-spacing: 0.03em;

  &.RequestDetailViewSectionNormalHighlight {
    color: red;
  }
}
.RequestDetailViewSectionLocation {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  @include RoobertMedium;
  line-height: 15px;
  font-size: 13px;
  color: black;
  opacity: 0.75;
  margin: 3px 0;
}
.RequestDetailViewSectionReferral {
  display: inline-block;
  vertical-align: top;
  overflow: hidden;
  position: relative;
  @include RoobertMedium;
  line-height: 20px;
  font-size: 15px;
  color: black;
  margin-top: 5px;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  padding: 5px 10px;
  text-decoration: none;
  transition: all 0.2s;

  &:hover {
    background: rgba(0, 0, 0, 0.3);
  }
}
.RequestDetailViewSectionAction {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  overflow: hidden;
  @include RoobertSemiBold;
  line-height: 20px;
  cursor: pointer;
  padding: 5px 20px;
  background: #323232;
  color: white;
  font-size: 15px;
  border-radius: 3px;

  &:hover {
    background-color: #a2f4c2;
    color: black;
  }

  &.RequestDetailViewSectionActionLoading {
    pointer-events: none;
    background: none;
    background-color: #a2f4c2;
    color: transparent;
    user-select: none;

    &::after {
      animation: spining 500ms infinite linear;
      border: 3px solid black;
      border-radius: 20px;
      border-right-color: transparent;
      border-top-color: transparent;
      content: '';
      display: block;
      height: 19px;
      width: 19px;
      left: 0;
      right: 0;
      margin: auto;
      top: 0;
      bottom: 0;
      position: absolute;
      text-align: center;
    }
  }
}
.RequestDetailViewSectionUnclaim {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  margin-bottom: 20px;
}
.RequestDetailViewSectionUnclaimTitle {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  @include RoobertSemiBold;
  line-height: 20px;
  font-size: 16px;
  color: black;
  margin-bottom: 3px;
}
.RequestDetailViewSectionUnclaimInfo {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  @include RoobertMedium;
  line-height: 18px;
  font-size: 14px;
  color: black;
  margin-bottom: 3px;
}
.RequestDetailViewSectionUnclaimQuote {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include ApercuLight;
  color: black;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 5px;
  white-space: pre-wrap;
}
.RequestDetailViewSectionUnclaimHistoryLink {
  display: inline-block;
  vertical-align: top;
  overflow: hidden;
  position: relative;
  @include RoobertMedium;
  line-height: 20px;
  font-size: 14px;
  color: black;
  padding-right: 15px;
  background-image: url('../../images/view-icon-black.png');
  background-repeat: no-repeat;
  background-position: center right;
  background-size: 10px 10px;
  opacity: 0.4;
  transition: all 0.2s;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
}

.RequestDetailHistory {
  padding-top: 3vh;
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
}
.RequestDetailHistoryColumn {
  padding: 0 10px;
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  max-width: 800px;
  text-align: left;
  margin: 0 auto;
}
.RequestDetailHistoryTop {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  margin-bottom: 50px;
  max-width: 550px;
}
.RequestDetailHistoryTitle {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  @include RoobertSemiBold;
  line-height: 25px;
  font-size: 20px;
  color: black;
  margin-bottom: 5px;
}
.RequestDetailHistorySubtitle {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  @include RoobertMedium;
  line-height: 18px;
  font-size: 14px;
  color: black;
  margin-bottom: 5px;
}
.RequestDetailHistoryDesc {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include ApercuLight;
  color: black;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 5px;
  white-space: pre-wrap;
}
.RequestDetailHistoryOthers {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
}
.RequestDetailHistoryOther {
  display: inline-block;
  vertical-align: top;
  overflow: hidden;
  position: relative;
  @include RoobertMedium;
  line-height: 20px;
  font-size: 14px;
  color: black;
  padding-right: 15px;
  background-image: url('../../images/view-icon-black.png');
  background-repeat: no-repeat;
  background-position: center right;
  background-size: 10px 10px;
  opacity: 0.4;
  transition: all 0.2s;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
}

.RequestDetailClaim {
  padding-top: 5vh;
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  text-align: center;

  .ThreadEditor {
    padding-left: 10px;
  }

  .RequestDetailViewCardTopText {
    display: none;

    &.RequestDetailViewCardTopTextAlt {
      display: block;
    }
  }
  .RequestDetailViewCardTopSkip {
    display: none;
  }
}
.RequestDetailClaimColumn {
  position: relative;
  display: inline-block;
  vertical-align: top;
  overflow: hidden;
  width: 100%;
  max-width: 450px;
  text-align: left;
  margin-bottom: 40px;

  &.RequestDetailClaimColumnRequestLeft {
    display: none;
  }
}

.RequestDetailViewSectionProjects {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
}
.PreviousProjectItem {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  margin-bottom: 10px;
  margin-top: 5px;
}
.PreviousProjectCard {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  border-radius: 3px;
  background: white;
  padding: 10px;
}
.PreviousProjectCardTitle {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  padding-right: 60px;
  white-space: nowrap;
  text-overflow: ellipsis;
  @include RoobertSemiBold;
  font-size: 16px;
  line-height: 20px;
  color: black;
  margin-bottom: 10px;
}
.PreviousProjectCardSubtitle {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  padding-left: 40px;
  @include RoobertMedium;
  font-size: 15px;
  line-height: 30px;
  color: black;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-right: 135px;

  .ExpertUserBubble {
    position: absolute;
    top: 0px;
    left: 0px;
  }
}
.PreviousProjectCardRight {
  position: absolute;
  display: block;
  overflow: hidden;
  top: 10px;
  right: 10px;
  @include RoobertMedium;
  font-size: 14px;
  line-height: 20px;
  color: black;
}
.PreviousProjectCardStatusLine {
  position: absolute;
  display: block;
  overflow: hidden;
  height: 3px;
  width: 40px;
  border-radius: 5px;
  bottom: 32px;
  right: 10px;
}
.PreviousProjectCardStatus {
  position: absolute;
  display: block;
  overflow: hidden;
  @include FkDisplayRegular;
  bottom: 10px;
  right: 10px;
  font-size: 13px;
  line-height: 20px;
  color: black;
}
.PreviousProjectFooter {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include RoobertMedium;
  font-size: 14px;
  line-height: 20px;
  color: #000;
  opacity: 0.8;
  padding: 5px 10px 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: none;
}
.PreviousProjectItemLoader {
  display: block;
  overflow: hidden;
  position: relative;
  text-align: center;
  width: 160px;
  padding: 5px 0;
  @include RoobertSemiBold;
  font-size: 12px;
  line-height: 20px;
  color: black;
  border: 1px solid black;
  // background: white;
  border-radius: 20px;
  margin: auto;
  cursor: pointer;
  opacity: 0.8;
  transition: all 0.2s;

  &:hover {
    opacity: 1;
    color: white;
    background: black;
  }
}

@media only screen and (min-width: 550px) {
  .RequestDetailHistoryColumn {
    padding: 0 15px;

    .ThreadEvent.RequestThreadEvent {
      .ThreadEventWrapper {
        max-width: 600px;
      }
    }
    .ThreadEvent.EmailMessageThreadEvent {
      .ThreadEventWrapper {
        max-width: 775px;
      }
    }
    .ThreadEvent.TextMessageThreadEvent {
      .ThreadEventWrapper {
        max-width: 600px;
      }
    }
    .ThreadEvent.MessageThreadEvent {
      .ThreadEventWrapper {
        max-width: 650px;
      }
    }
    .ThreadEvent.PhoneCallThreadEvent {
      .ThreadEventWrapper {
        max-width: 600px;
      }
    }
    .ThreadEvent.QuoteThreadEvent {
      .ThreadEventWrapper {
        max-width: 750px;
      }
    }
    .ThreadEvent.MeetingThreadEvent {
      .ThreadEventWrapper {
        max-width: 650px;
      }
    }
  }
}
@media only screen and (min-width: 850px) {
  .RequestDetailViewColumn {
    &.RequestDetailViewColumnRequestTop {
      display: none;
    }
    &.RequestDetailViewColumnInfo {
      max-width: 350px;
      margin-right: 10px;
    }
    &.RequestDetailViewColumnRequestMiddle {
      display: inline-block;
      margin-left: 10px;
      margin-right: 5px;
    }
  }
  .RequestDetailViewCardBodySkipFlow {
    padding: 15px;
  }
}
@media only screen and (min-width: 875px) {
  .RequestDetailClaimColumn {
    max-width: 400px;

    &.RequestDetailClaimColumnRequestLeft {
      display: inline-block;
      margin-right: 10px;
    }
    &.RequestDetailClaimColumnEditor {
      margin-left: 10px;
    }
    &.RequestDetailClaimColumnRequestUnder {
      display: none;
    }
  }
}
@media only screen and (min-width: 1000px) {
  .RequestDetailView {
    padding-top: 10vh;
  }
  .RequestDetailHistory {
    padding-top: 5vh;
  }
  .RequestDetailClaim {
    padding-top: 10vh;
  }
  .RequestDetailViewColumn {
    &.RequestDetailViewColumnInfo {
      max-width: 400px;
      margin-right: 30px;
    }
    &.RequestDetailViewColumnRequestMiddle {
      max-width: 500px;
      margin-left: 30px;
    }
  }
  .RequestDetailClaimColumn {
    &.RequestDetailClaimColumnRequestLeft {
    }
    &.RequestDetailClaimColumnEditor {
      max-width: 550px;
    }
    &.RequestDetailClaimColumnRequestUnder {
    }
  }
}

@media only screen and (min-width: 1200px) {
  .RequestDetailClaimColumn {
    &.RequestDetailClaimColumnRequestLeft {
      margin-right: 30px;
    }
    &.RequestDetailClaimColumnEditor {
      max-width: 650px;
      margin-left: 30px;
    }
    &.RequestDetailClaimColumnRequestUnder {
    }
  }
}
@media only screen and (min-width: 1300px) {
  .RequestDetailView.RequestDetailViewWithActivity {
    .RequestDetailViewColumn {
      &.RequestDetailViewColumnRequestTop {
      }
      &.RequestDetailViewColumnInfo {
        max-width: 350px;
        margin-right: 15px;
      }
      &.RequestDetailViewColumnRequestMiddle {
        max-width: 500px;
        margin-left: 15px;
        margin-right: 15px;
      }
      &.RequestDetailViewColumnActivity {
        max-width: 350px;
        display: inline-block;
        margin-left: 15px;
      }
    }
    .RequestDetailViewActivityUnder {
      display: none;
    }
  }
  .RequestDetailClaimColumn {
    &.RequestDetailClaimColumnRequestLeft {
      max-width: 450px;
    }
    &.RequestDetailClaimColumnEditor {
      max-width: 700px;
    }
    &.RequestDetailClaimColumnRequestUnder {
    }
  }
}
