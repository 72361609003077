@import '../Common';

.HumanBrandSidebarEditor {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  padding: 0 10px;
}
.HumanBrandSidebarEditorTop {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  height: 25px;
  padding-right: 110px;
}
.HumanBrandSidebarEditorTopTitle {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include RoobertSemiBold;
  font-size: 16px;
  line-height: 20px;
  color: black;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.HumanBrandSidebarEditorTopActions {
  position: absolute;
  display: block;
  overflow: hidden;
  top: 0px;
  right: 0px;
}
.HumanBrandSidebarEditorTopActionBtn {
  display: inline-block;
  position: relative;
  overflow: hidden;
  vertical-align: middle;
  height: 20px;
  font-size: 12px;
  line-height: 20px;
  @include RoobertSemiBold;
  padding: 0 5px;
  margin-left: 5px;
  transition: all 0.2s;
  cursor: pointer;

  &.HumanBrandSidebarEditorTopActionIgnore {
    text-align: left;
    background-color: transparent;
    color: black;
    opacity: 0.34;

    &:hover {
      opacity: 1;
      background-color: rgba(255, 255, 255, 0.4);
    }
  }
  &.HumanBrandSidebarEditorTopActionSave {
    padding: 0 10px;
    background-color: black;
    color: white;
    border-radius: 10px;

    &:hover {
      background-color: #a2f4c2;
      color: black;
    }
  }

  &.HumanBrandSidebarEditorTopActionBtnLoading {
    pointer-events: none;
    background: none;
    background-color: #a2f4c2;
    color: transparent;
    user-select: none;

    &::after {
      animation: spining 500ms infinite linear;
      border: 3px solid black;
      border-radius: 20px;
      border-right-color: transparent;
      border-top-color: transparent;
      content: '';
      display: block;
      height: 19px;
      width: 19px;
      left: 0;
      right: 0;
      margin: auto;
      top: 0;
      bottom: 0;
      position: absolute;
      text-align: center;
    }
  }
}
.HumanBrandSidebarEditorCardWrapper {
  position: relative;
  display: block;
  width: 100%;
  margin-top: 5px;
}
.HumanBrandSidebarEditorCard {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  border-radius: 3px;
  background-color: white;
  padding: 10px;
  margin-bottom: 10px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
}
.HumanBrandSidebarEditorFormRow {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  clear: both;
}
.HumanBrandSidebarEditorFormInput {
  position: relative;
  display: block;
  overflow: hidden;
  float: left;
  width: 100%;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0px;
  }
}
.HumanBrandSidebarEditorFormInputTitle {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include RoobertSemiBold;
  font-size: 13px;
  line-height: 20px;
  color: black;
}
.HumanBrandSidebarEditorFormInputSelect {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include ApercuRegular;
  font-size: 15px;
  line-height: 20px;
  padding: 10px;
  color: black;
  background: transparent;
  margin-top: 5px;
  border: 1px solid #9c9c9c;
  border-radius: 5px;
  outline: none;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-repeat: no-repeat;
  background-position: center right 10px;
  background-image: url('../../images/arrow-down-grey.png');
  background-size: 14px 8px;
}

.HumanBrandSidebarEditorFormInput {
  .MultiSelectTag {
    border: 1px solid #9c9c9c;
  }
  .MultiSelectContainer {
    border: 1px solid #9c9c9c;
  }
}

.HumanBrandSidebarEditorFormInputField {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include ApercuRegular;
  font-size: 15px;
  line-height: 20px;
  padding: 7px 10px;
  color: black;
  background: transparent;
  border-radius: 3px;
  outline: none;
  border: 1px solid #eaeaea;
  margin-top: 5px;

  &.PhoneNumberInput {
    display: flex;
    line-height: 20px;
  }
}
.HumanBrandSidebarEditorFormInput.HumanBrandSidebarEditorFormInputInvalid {
  .HumanBrandSidebarEditorFormInputField {
    color: $red-error;
    border-color: $red-error;
    input {
      color: $red-error;
    }
  }
}

.HumanBrandSidebarEditorFormExtraAction {
  position: relative;
  display: block;
  overflow: hidden;
  @include RoobertSemiBold;
  font-size: 13px;
  line-height: 22px;
  margin-top: 10px;
  cursor: pointer;
  transition: opacity 0.2s;
  opacity: 0.58;
  color: black;
  background-repeat: no-repeat;
  background-position: left center;
  background-image: url('../../images/add-contact-black.png');
  background-size: 15px 15px;
  padding-left: 20px;
  height: 20px;

  &:hover {
    opacity: 1;
  }
}

.UserNoteSidebarEdit {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  border-radius: 3px;
  background-color: white;
  margin-bottom: 10px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
}

.UserNoteSidebarList {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
}
.UserNoteSidebarListLoading {
  position: relative;
  display: block;
  overflow: hidden;
  height: 32px;
  width: 32px;
  animation: spining 500ms infinite linear;
  border: 3px solid black;
  border-radius: 50px;
  border-right-color: transparent;
  border-top-color: transparent;
  content: '';
  left: 0;
  right: 0;
  margin: auto;
  top: 0;
  bottom: 0;
  position: absolute;
  text-align: center;
  pointer-events: none;
  user-select: none;
}
.UserNoteSidebarListEmpty {
  position: relative;
  display: block;
  overflow: hidden;
  width: 98%;
  padding: 10px;
  border-radius: 5px;
  @include RoobertMedium;
  font-size: 14px;
  line-height: 20px;
  color: #666;
}
.UserNoteSidebarListItem {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  border-radius: 3px;
  background-color: white;
  padding: 10px;
  margin-bottom: 10px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
}
.UserNoteSidebarListItemHeader {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  border-bottom: 1px solid #f0f0f0;
  cursor: pointer;
  transition: background 0.2s;
  padding: 5px;

  &:hover {
    background: #f0f0f0;
  }
}
.UserNoteSidebarListItemName {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include RoobertSemiBold;
  font-size: 20px;
  line-height: 25px;
  color: #000;
}
.UserNoteSidebarListItemEdit {
  position: absolute;
  display: block;
  overflow: hidden;
  top: 10px;
  right: 10px;
  @include RoobertMedium;
  font-size: 14px;
  line-height: 15px;
  color: #000;
  opacity: 0.45;
}
.UserNoteSidebarListItemSub {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include RoobertMedium;
  font-size: 16px;
  line-height: 20px;
  color: #000;
  opacity: 0.45;
}
.UserNoteSidebarListItemBody {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
}

.UserNoteSidebarListItemBodyContent {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  padding: 10px 5px;
  @include ApercuLight;
  font-size: 16px;
  line-height: 20px;
  text-align: left;
  color: black;
}
