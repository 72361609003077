@import '../Common';

.UnknownHuman {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  margin-top: 5px;
}
.UnknownHumanWrapper {
  position: relative;
  display: inline-block;
  vertical-align: top;
  overflow: hidden;
  width: 100%;
  max-width: 100%;
  float: left;
  clear: both;
}
.UnknownHumanTop {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  height: 20px;
  padding-left: 25px;
  padding-right: 60px;
}
.UnknownHuman.UnknownHumanCreating {
  .UnknownHumanTop {
    padding-right: 110px;
  }
}
.UnknownHumanTopIndent {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  background-color: black;
  border-radius: 15px;
  @include RoobertSemiBold;
  font-size: 16px;
  color: white;
}
.UnknownHumanTopTitle {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include RoobertSemiBold;
  font-size: 16px;
  line-height: 20px;
  color: black;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.UnknownHumanTopActions {
  position: absolute;
  display: block;
  overflow: hidden;
  top: 0px;
  right: 0px;
}
.UnknownHumanTopActionBtn {
  display: inline-block;
  position: relative;
  overflow: hidden;
  vertical-align: middle;
  height: 20px;
  font-size: 12px;
  line-height: 20px;
  @include RoobertSemiBold;
  padding: 0 5px;
  margin-left: 5px;
  transition: all 0.2s;
  cursor: pointer;

  &.UnknownHumanTopActionIgnore {
    text-align: left;
    background-color: transparent;
    color: black;
    opacity: 0.34;

    &:hover {
      opacity: 1;
      background-color: rgba(255, 255, 255, 0.4);
    }
  }
  &.UnknownHumanTopActionSave {
    padding: 0 10px;
    background: #424242;
    color: white;
    border-radius: 10px;

    &:hover {
      background-color: black;
    }
  }
}
.UnknownHumanCardWrapper {
  padding-left: 15px;
  position: relative;
  display: block;
  width: 100%;
  margin-top: 5px;
}
.UnknownHumanCard {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  border-radius: 3px;
  background-color: white;
  padding: 10px;
  margin-bottom: 10px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
}
.UnknownHumanCardTitle {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include RoobertMedium;
  font-size: 16px;
  line-height: 20px;
  color: black;

  span {
    @include RoobertSemiBold;
    color: black;
  }
}
.UnknownHumanCardSection {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  margin-top: 20px;
}
.UnknownHumanCardSectionTitle {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include RoobertSemiBold;
  color: black;
  font-size: 15px;
  line-height: 20px;
}
.UnknownHumanCardOptions {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
}
.UnknownHumanCardOption {
  position: relative;
  display: inline-block;
  vertical-align: top;
  overflow: hidden;
  padding: 5px 10px;
  @include RoobertMedium;
  font-size: 14px;
  line-height: 20px;
  color: #535353;
  background-color: #e3e3e3;
  border-radius: 3px;
  transition: all 0.2s;
  margin-right: 5px;
  margin-top: 5px;
  cursor: pointer;

  &:hover {
    background-color: black;
    color: white;
  }
}
.UnknownHumanCardActionBtn {
  display: inline-block;
  position: relative;
  overflow: hidden;
  vertical-align: middle;
  line-height: 20px;
  font-size: 13px;
  @include RoobertSemiBold;
  padding: 5px 10px;
  transition: all 0.2s;
  background: #323232;
  color: white;
  margin-top: 5px;
  border-radius: 3px;
  cursor: pointer;

  &:hover {
    background-color: black;
  }
}
.UnknownHumanForm {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
}
.UnknownHumanFormRow {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  clear: both;
}
.UnknownHumanFormInput {
  position: relative;
  display: block;
  overflow: hidden;
  float: left;
  width: 100%;
  margin-bottom: 10px;

  &.UnknownHumanFormInputHalf {
    width: 100%;
  }
  &.UnknownHumanFormInputFull {
    width: 100%;
  }
  &:last-child {
    margin-bottom: 0px;
  }
}
.UnknownHumanFormInputTitle {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include RoobertSemiBold;
  font-size: 13px;
  line-height: 20px;
  color: black;
}
.UnknownHumanFormInputField {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include ApercuRegular;
  font-size: 15px;
  line-height: 20px;
  padding: 7px 5px;
  color: black;
  background: transparent;
  border-radius: 3px;
  outline: none;
  border: 1px solid #eaeaea;

  &.PhoneNumberInput {
    display: flex;
    line-height: 20px;
  }

  &.UnknownHumanFormInputFieldReadOnly {
    cursor: not-allowed;
    background: #f7f7f7;
    color: rgba(0, 0, 0, 0.4);
  }
}
.UnknownHumanFormInput.UnknownHumanFormInputInvalid {
  .UnknownHumanFormInputField {
    color: $red-error;
    border-color: $red-error;
    input {
      color: $red-error;
    }
  }
}

@media only screen and (min-width: 767px) {
  .UnknownHumanWrapper {
    width: unset;
    padding-right: 25px;
    padding-left: 35px;
    min-width: 400px;
  }
  .UnknownHumanCardWrapper {
    padding-left: 25px;
  }
  .UnknownHumanFormInput.UnknownHumanFormInputHalf {
    width: 50%;
  }
  .UnknownHumanFormInput {
    &:first-child {
      padding-right: 10px;
    }
  }
  .UnknownHumanFormInputField {
    padding: 7px 10px;
  }
}

@media only screen and (min-width: 1000px) {
  .UnknownHumanWrapper {
    max-width: 650px;
  }
  .UnknownHumanFormInput.UnknownHumanFormInputFull {
    width: 50%;
  }
  .UnknownHumanFormInput {
    &:nth-child(odd) {
      padding-right: 10px;
    }
  }
}
