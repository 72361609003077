/* Note: nothing in this file should render output, so it's safe to import multiple times. */

$base-sidebar-breakpoint: 800px;

$red-error: #e28181;
$green: #2d5858;

$light-shade: #c1c1c1;
$mid-shade: #a9a9a9;
$mid-dark-shade: #9c9c9c;
$dark-shade: #434343;
$darkest-shade: #343434;
$black-shade: #121212;

@mixin FkDisplayRegular {
  font-family: 'fk_displayregular', sans-serif;
  font-weight: normal;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@mixin RoobertRegular {
  font-family: 'roobert-regular', sans-serif;
  font-weight: normal;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@mixin RoobertMedium {
  font-family: 'roobert-medium', sans-serif;
  font-weight: normal;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@mixin RoobertSemiBold {
  font-family: 'roobert-semibold', sans-serif;
  font-weight: normal;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@mixin RoobertBold {
  font-family: 'roobert-bold', sans-serif;
  font-weight: normal;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@mixin ApercuLight {
  font-family: 'apercu-light-pro', sans-serif;
  font-weight: normal;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@mixin ApercuRegular {
  font-family: 'apercu-regular-pro', sans-serif;
  font-weight: normal;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
