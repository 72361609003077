@import '../Common';

.HumanProjects {
}

.ProjectsScreenProject {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  margin-bottom: 30px;
  margin-top: 10px;
  border: 1px solid #aaa;
  border-radius: 3px;
  padding: 10px;
  text-decoration: none;
  max-width: 700px;
  color: black;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.18);
  transition: all 0.2s;

  &:hover {
    border-color: black;
  }
}
.ProjectsScreenProjectTitle {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  @include RoobertSemiBold;
  font-size: 20px;
  line-height: 30px;
  color: black;
}
.ProjectsScreenProjectBrand {
  display: block;
  position: relative;
  overflow: hidden;
  width: 100%;
  @include RoobertMedium;
  font-size: 13px;
  line-height: 15px;
  color: black;
  white-space: nowrap;
  text-overflow: ellipsis;
  opacity: 0.75;
}
.ProjectsScreenProjectMatch {
  position: relative;
  display: block;
  overflow: hidden;
  text-decoration: none;
  color: black;
  padding: 10px;
  padding-left: 55px;
  min-height: 45px;
  max-width: 100%;
  margin-top: 20px;
  padding-right: 90px;
}
.UserBubble.ProjectsScreenProjectMatchBubble {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  overflow: hidden;
  width: 45px;
  height: 45px;
  font-size: 30px;
  line-height: 45px;
  border-radius: 45px;
}
.ProjectsScreenProjectMatchName {
  display: inline-block;
  vertical-align: top;
  overflow: hidden;
  position: relative;
  @include RoobertRegular;
  font-size: 15px;
  line-height: 25px;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: black;
  max-width: 100%;
}
.ProjectsScreenProjectPrice {
  display: block;
  overflow: hidden;
  position: absolute;
  bottom: 30px;
  right: 10px;
  text-align: right;
  @include FkDisplayRegular;
  font-size: 20px;
  line-height: 20px;
  color: black;
  opacity: 0.53;
  width: 80px;
}
.ProjectsScreenProjectStatus {
  display: block;
  overflow: hidden;
  position: absolute;
  bottom: 10px;
  right: 10px;
  text-align: right;
  @include RoobertMedium;
  font-size: 13px;
  line-height: 20px;
  color: black;
  width: 80px;
}

.ProjectsScreenRequest {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  margin-bottom: 30px;
  margin-top: 10px;
  border: 1px solid #aaa;
  border-radius: 3px;
  padding: 10px 20px 20px;
  text-decoration: none;
  max-width: 700px;
  color: black;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.18);
  transition: all 0.2s;

  &:hover {
    border-color: black;
  }
}
.ProjectsScreenRequestHeader {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
}
.ProjectsScreenRequestHeaderTitle {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  @include RoobertSemiBold;
  font-size: 20px;
  line-height: 30px;
  color: black;
}
.ProjectsScreenRequestStatus {
  display: none;
  vertical-align: top;
  overflow: hidden;
  position: absolute;
  top: 2px;
  right: 0;
  @include RoobertSemiBold;
  font-size: 12px;
  line-height: 16px;
  padding: 5px 10px;
  text-transform: uppercase;
  border-radius: 25px;
  background: #f7bc34;
  color: white;

  &.ProjectsScreenRequestStatusWaiting {
    background: #70beaf;
  }
  &.ProjectsScreenRequestStatusMatched {
    background: #f7bc34;
  }
  &.ProjectsScreenRequestStatusCanceled {
    background: #cc7e7e;
  }
}
.ProjectsScreenRequestBody {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  border-top: 1px solid #aaa;
  margin-top: 10px;
  padding-top: 10px;
}
.ProjectsScreenRequestText {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include ApercuLight;
  font-size: 16px;
  line-height: 20px;
}

.ProjectsScreenRequestMatches {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  border-top: 1px solid #aaa;
  margin-top: 10px;
  padding-top: 10px;
}
.ProjectsScreenRequestMatchesTitle {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  @include RoobertSemiBold;
  font-size: 16px;
  line-height: 20px;
  color: black;
}
.ProjectsScreenRequestMatchItem {
  display: inline-block;
  vertical-align: top;
  overflow: hidden;
  position: relative;
  width: 155px;
  margin-top: 10px;
  padding-left: 55px;
  margin-right: 10px;
  min-height: 45px;
  padding-top: 5px;
}
.UserBubble.ProjectsScreenRequestMatchItemPhoto {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  overflow: hidden;
  width: 45px;
  height: 45px;
  font-size: 30px;
  line-height: 45px;
  border-radius: 45px;
}
.ProjectsScreenRequestMatchItemName {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  @include FkDisplayRegular;
  font-size: 17px;
  line-height: 20px;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: black;
}
.ProjectsScreenRequestMatchItemSub {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  @include RoobertRegular;
  font-size: 13px;
  line-height: 15px;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: black;
}

@media only screen and (min-width: 500px) {
  .ProjectsScreenRequestStatus {
    display: inline-block;
  }
  .ProjectsScreenRequestHeaderTitle {
    padding-right: 170px;
  }
}
