@import '../Common';

.HumanExpertDetail {
}

.ExpertDetailPhoto {
  height: 320px;
  width: 100%;
  max-width: 350px;
  position: relative;
  display: block;
  overflow: hidden;
  border-radius: 10px;
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  margin-bottom: 25px;
}
.ExpertDetailPhotoFooter {
  position: absolute;
  bottom: 10px;
  left: 10px;
  right: 10px;
  display: block;
  overflow: hidden;
}
.ExpertDetailPhotoTitle {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 20px;
  font-size: 17px;
  @include RoobertSemiBold;
  text-transform: uppercase;
  color: white;
  letter-spacing: 0.03em;
}
.ExpertDetailPhotoSubTitle {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 20px;
  font-size: 14px;
  @include ApercuRegular;
  text-transform: uppercase;
  color: white;
  letter-spacing: 0.03em;
  opacity: 0.9;
}
.ExpertDetailSection {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  margin-bottom: 20px;
}
.ExpertDetailSectionTitle {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include RoobertSemiBold;
  font-size: 14px;
  line-height: 25px;
  color: black;
  opacity: 0.5;
  text-transform: uppercase;
  letter-spacing: 0.03em;
}
.ExpertDetailDesc {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include ApercuRegular;
  font-size: 16px;
  line-height: 22px;
  color: black;
}
.ExpertDetailContactLineWrap {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
}
.ExpertDetailContactLine {
  display: inline-block;
  vertical-align: top;
  overflow: hidden;
  position: relative;
  @include ApercuRegular;
  font-size: 16px;
  line-height: 25px;
  color: black;
  text-decoration: none;

  &:hover {
    color: #2d5858;
  }
}
.ExpertDetailSkills {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
}
.ExpertDetailSkill {
  display: block;
  overflow: hidden;
  position: relative;
  @include ApercuRegular;
  font-size: 16px;
  line-height: 20px;
  color: black;
  margin-bottom: 5px;
}

.ExpertDetailAction {
  overflow: hidden;
  position: relative;
  display: inline-block;
  vertical-align: top;
  background-color: black;
  @include RoobertSemiBold;
  margin-top: 5px;
  font-size: 16px;
  line-height: 25px;
  color: white;
  text-decoration: none;
  cursor: pointer;
  border-radius: 3px;
  padding: 10px 25px;
  padding-right: 55px;
  margin-bottom: 5px;
  background-repeat: no-repeat;
  background-position: center right 20px;
  background-image: url('../../images/forward-arrow-white.png');
  background-size: 14px 14px;
  transition: background-color 0.2s, color 0.2s;

  &:hover {
    background-color: #a2f4c2;
    color: black;
    background-image: url('../../images/view-icon-black.png');
  }
}

.ExpertDetailInfo {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  max-width: 400px;
  background: #eee;
  border-radius: 3px;
  padding: 20px;
  text-align: center;
  margin-top: 5px;
}
.ExpertDetailInfoText {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include ApercuRegular;
  font-size: 16px;
  line-height: 20px;
  color: black;
  margin-bottom: 10px;
}
.ExpertDetailInfoLink {
  overflow: hidden;
  position: relative;
  display: inline-block;
  vertical-align: top;
  background-color: white;
  @include RoobertSemiBold;
  margin-top: 5px;
  font-size: 16px;
  line-height: 20px;
  color: black;
  border: 1px solid black;
  text-decoration: none;
  cursor: pointer;
  border-radius: 3px;
  padding: 5px 15px;
  margin-bottom: 5px;
  transition: background-color 0.2s, color 0.2s;

  &:hover {
    background-color: black;
    color: white;
  }
}

@media only screen and (min-width: 400px) {
  .ExpertDetailPhoto {
    height: 380px;
    max-width: 400px;
  }
}
@media only screen and (min-width: 640px) {
  .ExpertDetailPhoto {
    height: 320px;
  }
}
@media only screen and (min-width: 720px) {
  .ExpertDetailPhoto {
    height: 380px;
  }
}
@media only screen and (min-width: 900px) {
  .ExpertDetailPhoto {
    height: 250px;
    background-position: center center;
    margin-bottom: 0;
  }
}
@media only screen and (min-width: 1100px) {
  .ExpertDetailPhoto {
    height: 300px;
  }
}
