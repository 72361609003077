@import '../Common';

.PhoneCallEditor {
}
.PhoneCallEditor .ThreadEditorCard {
  border-radius: 0px;
}
.PhoneCallEditor .ThreadEditorCardWrapper {
  padding-left: 0px;
}
@media only screen and (min-width: 375px) {
}
@media only screen and (min-width: 500px) {
}
@media only screen and (min-width: 640px) {
}
@media only screen and (min-width: 750px) {
}
@media only screen and (min-width: 1000px) {
}
@media only screen and (min-width: 1150px) {
}
