@import '../Common';

.ThreadEditor {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  padding-right: 10px;
  padding-bottom: 10px;
}
.ThreadEditorTop {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
}
.ThreadEditorTopStatus {
  display: none;
  position: absolute;
  overflow: hidden;
  width: 60px;
  bottom: 5px;
  right: 5px;
  text-align: right;
  color: black;
  opacity: 0.34;
  @include RoobertRegular;
  font-size: 12px;
  line-height: 20px;
}
.ThreadEditorTopTitle {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  outline: none;
  border: none;
  @include RoobertSemiBold;
  font-size: 20px;
  line-height: 25px;
  background: transparent;
  color: black;
  padding: 0;
  margin-bottom: 5px;
}
.ThreadEditorTopAction {
  position: absolute;
  overflow: hidden;
  width: 60px;
  bottom: 0px;
  right: 0px;
  text-align: center;
  color: black;
  opacity: 0.5;
  @include RoobertRegular;
  font-size: 12px;
  line-height: 25px;
  background-color: transparent;
  transition: all 0.2s;
  cursor: pointer;
  padding: 0 5px;
  border-radius: 3px;
  margin-bottom: 5px;

  &:hover {
    opacity: 1;
    color: white;
    background-color: black;
  }
}
.ThreadEditorRow {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  min-height: 30px;
  &:last-child {
    margin-bottom: 5px;
  }
}
.ThreadEditorRowTitle {
  display: none;
  overflow: hidden;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 70px;
  text-align: right;
  color: black;
  opacity: 0.54;
  @include FkDisplayRegular;
  font-size: 14px;
  line-height: 30px;
}
.ThreadEditorRowBubbles {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
}
.ThreadEditorRowBubble {
  display: inline-block;
  overflow: hidden;
  position: relative;
  vertical-align: top;
  max-width: 80%;
  white-space: nowrap;
  text-overflow: ellipsis;
  border-radius: 20px;
  padding: 0 10px;
  color: black;
  background-color: white;
  @include RoobertMedium;
  font-size: 12px;
  line-height: 30px;
  margin: 0 5px 5px 0;
  min-width: 30px;
  min-height: 30px;
  cursor: pointer;
  transition: background-color 0.2s;
}
.ThreadEditorRowBubbleRemove {
  display: none;
  position: absolute;
  overflow: hidden;
  top: 5px;
  right: 5px;
  background-color: white;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('../../images/search-close-black.png');
  background-size: 10px 10px;
  width: 20px;
  height: 20px;
  border-radius: 20px;

  &:hover {
    background-color: #e3e3e3;
  }
}
.ThreadEditorRowBubble:hover {
  background-color: black;
  color: white;

  .ThreadEditorRowBubbleRemove {
    display: block;
  }
}
.ThreadEditorRowBubbleAdd {
  display: inline-block;
  overflow: hidden;
  position: relative;
  vertical-align: top;
  border-radius: 20px;
  @include RoobertMedium;
  font-size: 24px;
  line-height: 28px;
  background: white;
  color: black;
  text-align: center;
  width: 30px;
  height: 30px;
  margin: 0 5px 5px 0;
  cursor: pointer;
  z-index: 5;

  &:hover {
    background: black;
    color: white;
  }

  &.ThreadEditorRowBubbleAddCollapse {
    background: black;
    color: white;
    transform: rotate(45deg);
  }
}
.ThreadEditorRowInput {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  margin-top: 5px;
  margin-bottom: 5px;
}
.ThreadEditorRowInputTextarea {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  outline: none;
  border: none;
  @include RoobertMedium;
  font-size: 18px;
  line-height: 20px;
  background: transparent;
  color: black;
  padding: 0;
}
.ThreadEditorCardWrapper {
  display: block;
  position: relative;
  width: 100%;
}
.ThreadEditorCard {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  border-radius: 5px;
  background: white;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
}
.ThreadEditorBody {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;

  &.MeetingEditorBody {
    padding: 15px;
    // background: #eee;
  }

  &.ThreadEditorBodyWithTemplates {
    min-height: 230px;
  }
}
.ThreadEditorCardWrapper.ThreadEditorCardWrapperEmailIntroContent {
  margin-bottom: 15px;
  opacity: 0.7;

  .ThreadEditorCard {
    box-shadow: none;
    padding: 15px;
  }
}
.RequestDetailViewEmailIntroContentExplain {
  position: absolute;
  display: block;
  overflow: hidden;
  top: 0;
  right: 0;
  color: black;
  @include RoobertSemiBold;
  font-size: 16px;
  line-height: 20px;
}
.ThreadEditorBodySwitch {
  position: absolute;
  display: block;
  overflow: hidden;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  padding: 5px;
  padding-top: 25px;
  z-index: 3;
  text-align: right;

  &.ThreadEditorBodySwitchInactive {
    height: 20px;
    width: 40px;
  }
}
.ThreadEditorBodySwitchToggle {
  position: absolute;
  display: block;
  overflow: hidden;
  top: 5px;
  right: 5px;
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('../../images/dot-dot-dot.png');
  background-size: 18px 4px;
  background-color: white;
  height: 20px;
  width: 30px;
  border-radius: 5px;
  border: 1px solid #e3e3e3;
  transition: all 0.2s;

  &:hover {
    background-color: #eee;
    border-color: black;
  }
}
.ThreadEditorBodySwitchOption {
  position: relative;
  display: inline-block;
  vertical-align: top;
  overflow: hidden;
  cursor: pointer;
  background: #eee;
  border-radius: 3px;
  box-shadow: 0px 2px 4px rgba(31, 31, 31, 0.24);
  @include RoobertSemiBold;
  font-size: 14px;
  line-height: 20px;
  padding: 5px 10px;
  color: black;
  margin-top: 5px;
  margin-left: 5px;
  transition: all 0.2s;

  &:hover {
    color: white;
    background-color: black;
  }
}
.ThreadEditorBodyError {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 5;
  background: #dd5d5c;
  @include RoobertSemiBold;
  line-height: 20px;
  font-size: 16px;
  padding: 5px 15px;
  color: white;
}
.ThreadEditorBodyTemplates {
  position: absolute;
  bottom: 0;
  left: 0;
  top: 0;
  right: 0;
  z-index: 4;
  background: white;
  padding: 15px;
  overflow-x: hidden;
}
.ThreadEditorBodyTemplatesHeader {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  @include RoobertSemiBold;
  line-height: 25px;
  font-size: 18px;
  color: black;
  margin-bottom: 10px;
  z-index: 2;

  a {
    opacity: 0.5;
    font-size: 14px;
    margin-left: 5px;
    color: black;
  }
}
.ThreadEditorBodyTemplatesClose {
  display: block;
  position: absolute;
  overflow: hidden;
  top: 15px;
  right: 15px;
  z-index: 3;
  background-color: white;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('../../images/icon-close-black.png');
  background-size: 18px 18px;
  width: 25px;
  height: 25px;
  cursor: pointer;

  &:hover {
    background-color: #e3e3e3;
  }
}
.ThreadEditorBodyTemplatesList {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
}
.ThreadEditorBodyTemplatesListItem {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  @include RoobertMedium;
  font-size: 16px;
  line-height: 25px;
  padding: 10px 5px;
  border-top: 1px solid black;
  transition: opacity 0.2s, background 0.2s;
  opacity: 0.7;
  cursor: pointer;

  &:hover {
    opacity: 1;
    background: #eee;
  }
}
.ThreadEditorBodyTemplatesEmpty {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  @include RoobertMedium;
  font-size: 16px;
  line-height: 25px;
  padding: 10px;
  background: #eee;
}

.ThreadEditorPhoneCall {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  padding: 15px 10px;
}
.ThreadEditorPhoneCallOptions {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  margin-bottom: 30px;
}
.ThreadEditorPhoneCallOption {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  @include RoobertMedium;
  font-size: 18px;
  line-height: 30px;
  padding: 5px 10px;
  cursor: pointer;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-bottom: 5px;
  color: black;
  background-color: transparent;
  border-radius: 3px;
  transition: background-color 0.2s;

  &:hover {
    background-color: #e3e3e3;
  }
}
.ThreadEditorPhoneCallInfo {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  @include RoobertRegular;
  font-size: 13px;
  line-height: 15px;
  color: #aaa;
  padding: 0 10px;

  span {
    text-decoration: underline;
    color: black;
    cursor: pointer;
  }
}
.ThreadEditorPhoneCall.ThreadEditorPhoneCallEmpty {
  .ThreadEditorPhoneCallOptions {
    margin: 0;
  }
  .ThreadEditorPhoneCallInfo {
    font-size: 16px;
    line-height: 20px;
  }
}

.ThreadEditorMiddle {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  padding: 10px;
  color: black;
  @include RoobertMedium;
  font-size: 13px;
  line-height: 15px;
  background: #fff481;
  text-align: center;
}

.ThreadEditorMiddleLink {
  color: black;
  @include RoobertMedium;
  font-size: 13px;
  line-height: 15px;
}

.ThreadEditorTools {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  border-top: 1px solid #e3e3e3;
  padding: 5px 10px;
  max-height: 41px;
}
.ThreadEditorTool {
  overflow: hidden;
  position: relative;
  display: inline-block;
  vertical-align: top;
  padding: 5px;
  padding-left: 35px;
  color: black;
  @include FkDisplayRegular;
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  text-decoration: none;
  background-repeat: no-repeat;
  background-position: left 5px center;
  background-size: 20px 20px;
  background-color: transparent;
  transition: background-color 0.2s;
  border-radius: 3px;
  cursor: pointer;
  margin-right: 10px;

  &:last-child {
    margin-right: 0;
  }

  span {
    display: none;
  }
  &.ThreadEditorToolCalculator {
    background-image: url('../../images/quote-calc-icon.png');
  }
  &.ThreadEditorToolExamples {
    background-image: url('../../images/quote-examples-icon.png');
    display: none;
  }
  &.ThreadEditorToolLines {
    background-image: url('../../images/quote-template-icon.png');
  }
  &.ThreadEditorToolTemplate {
    background-image: url('../../images/quote-template-icon.png');
  }
  &.ThreadEditorToolFiles {
    // background-position: center left;
    background-image: url('../../images/attach-file-icon.png');
    background-size: 11px 19px;
    background-position: left 12px center;
  }

  &:hover {
    background-color: #eee;
  }
}

.ThreadEditorFooter {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  border-top: 1px solid #e3e3e3;
  min-height: 50px;
}
.ThreadEditorFooterUpper {
  padding: 10px 5px 55px 5px;
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
}
.ThreadEditorFooterLower {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  padding: 10px 180px 10px 5px;
}
.ThreadEditorFiles {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
}
.ThreadEditorFile {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  overflow: hidden;
  padding: 3px 8px;
  margin-top: 2px;
  margin-bottom: 2px;
  margin-left: 5px;
  cursor: pointer;
  @include RoobertMedium;
  font-size: 13px;
  line-height: 20px;
  transition: all 0.2s;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #535353;
  background-color: #e3e3e3;
  border-radius: 3px;
  max-width: 85%;
}
.ThreadEditorFileRemove {
  display: none;
  position: absolute;
  overflow: hidden;
  top: 3px;
  right: 5px;
  background-color: white;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('../../images/search-close-black.png');
  background-size: 10px 10px;
  width: 20px;
  height: 20px;
  border-radius: 20px;

  &:hover {
    background-color: #e3e3e3;
  }
}
.ThreadEditorFile:hover {
  background-color: black;
  color: white;

  .ThreadEditorFileRemove {
    display: block;
  }
}
.ThreadEditorUpload {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  overflow: hidden;
  cursor: pointer;
  padding: 5px 15px;
  line-height: 20px;
  font-size: 14px;
  color: black;
  @include RoobertSemiBold;
  opacity: 0.6;
  transition: all 0.2s;

  &:hover {
    opacity: 1;
  }
  &.ThreadEditorUploadHasFiles {
    @include RoobertMedium;
    color: #535353;
    background-color: #e3e3e3;
    border-radius: 3px;
    padding: 3px 0;
    margin-left: 5px;
    width: 26px;
    text-align: center;
    font-size: 22px;
    opacity: 1;

    &:hover {
      color: white;
      background-color: black;
    }
  }
}
.ThreadEditorQuoteCustomizations {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  padding-left: 10px;
}
.ThreadEditorQuoteCustomization {
  display: inline-block;
  vertical-align: top;
  overflow: hidden;
  position: relative;
  max-width: 140px;
  width: 49%;
}
.ThreadEditorQuoteCustomizationTitle {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
}
.ThreadEditorQuoteCustomizationTitleOption {
  display: inline-block;
  vertical-align: top;
  overflow: hidden;
  position: relative;
  opacity: 0.34;
  cursor: pointer;
  transition: opacity 0.2s;
  @include RoobertMedium;
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  color: black;

  span {
    position: relative;
    display: inline-block;
    vertical-align: inherit;
    overflow: hidden;
    width: 20px;
    height: 20px;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url('../../images/info-icon-black.png');
    background-size: 10px 10px;
  }

  &.ThreadEditorQuoteCustomizationTitleOptionOther {
    margin-left: 5px;
    display: none;
  }
}
.ThreadEditorQuoteCustomization.ThreadEditorQuoteCustomizationFixed
  .ThreadEditorQuoteCustomizationTitleOption {
  cursor: default;
  opacity: 0.34;
}
.ThreadEditorQuoteCustomization:not(.ThreadEditorQuoteCustomizationFixed)
  .ThreadEditorQuoteCustomizationTitleOption {
  &:hover {
    opacity: 1;
  }
}
.ThreadEditorQuoteCustomizationField {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
}
.SingleDatePicker_picker.SingleDatePicker_picker__portal {
  z-index: 200;
}
.ThreadEditorQuoteCustomizationDate {
  .SingleDatePickerInput {
    background: transparent;
  }
  .DateInput__small {
    width: 100%;
  }
  .DateInput {
    background: transparent;
  }
  input {
    @include FkDisplayRegular;
    font-size: 16px;
    line-height: 20px;
    margin: 0;
    padding: 3px 0;
    outline: none;
    border: none;
    height: 26px;
    background-color: transparent;
    color: black;
  }
}
.ThreadEditorQuoteCustomizationFieldValue {
  display: inline-block;
  vertical-align: top;
  overflow: hidden;
  position: relative;
  padding: 3px 0px;
  @include FkDisplayRegular;
  font-size: 16px;
  line-height: 20px;
  min-width: 35px;
  min-height: 26px;
}
.ThreadEditorQuoteCustomizationFieldGrow {
  display: inline-block;
  overflow: hidden;
  position: relative;
  vertical-align: top;
}
.ThreadEditorQuoteCustomizationFieldGrowHidden {
  display: inline-block;
  vertical-align: top;
  overflow: hidden;
  position: relative;
  padding: 3px 0px;
  @include FkDisplayRegular;
  font-size: 16px;
  line-height: 20px;
  visibility: hidden;
  min-width: 35px;
  min-height: 26px;
}
.ThreadEditorQuoteCustomizationFieldBank {
  display: inline-block;
  vertical-align: top;
  overflow: hidden;
  position: relative;
  padding: 3px 0px 3px 10px;
  @include FkDisplayRegular;
  font-size: 13px;
  line-height: 20px;
  min-width: 35px;
  min-height: 26px;
  color: green;
}
.ThreadEditorQuoteCustomization:not(.ThreadEditorQuoteCustomizationFixed):hover
  .ThreadEditorQuoteCustomizationFieldBank {
  display: none;
}
.ThreadEditorQuoteCustomization.ThreadEditorQuoteCustomizationPriceFocused
  .ThreadEditorQuoteCustomizationFieldBank {
  display: none;
}
.ThreadEditorQuoteCustomization.ThreadEditorQuoteCustomizationFixed {
  .ThreadEditorQuoteCustomizationFieldValue {
    opacity: 0.6;
  }
  .ThreadEditorQuoteCustomizationFieldBank {
    opacity: 0.6;
    color: black;
  }
}
.ThreadEditorQuoteCustomizationFieldGrowInput {
  display: block;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  outline: none;
  border: none;
  @include FkDisplayRegular;
  font-size: 16px;
  background-color: transparent;
  height: 26px;
  border-radius: 5px;
  color: black;
  padding: 3px 0px;
  transition: background-color 0.2s;

  &.ThreadEditorQuoteCustomizationFieldGrowInputInvalid {
    color: $red-error;
    background-color: #e3e3e3;
  }
  &:focus {
    background-color: #e3e3e3;
  }
}
.ThreadEditorQuoteCustomizationDays {
  span {
    display: inline-block;
    vertical-align: top;
    overflow: hidden;
    position: relative;
    padding: 3px 0px;
    @include FkDisplayRegular;
    font-size: 16px;
    line-height: 20px;
    margin-left: 5px;
  }
  .ThreadEditorQuoteCustomizationFieldGrowHidden {
    min-width: 15px;
  }
}
.ThreadEditorQuoteCustomization:not(.ThreadEditorQuoteCustomizationFixed):hover {
  .ThreadEditorQuoteCustomizationTitleOption.ThreadEditorQuoteCustomizationTitleOptionActive {
    opacity: 1;
  }
  .ThreadEditorQuoteCustomizationTitleOption.ThreadEditorQuoteCustomizationTitleOptionOther {
    display: inline-block;
  }
  .ThreadEditorQuoteCustomizationFieldGrowInput {
    background-color: #e3e3e3;
  }
}
.ThreadEditorActions {
  display: block;
  position: absolute;
  overflow: hidden;
  bottom: 10px;
  right: 10px;
}
.ThreadEditorActionBtn {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  overflow: hidden;
  @include RoobertSemiBold;
  float: right;
  line-height: 20px;
  cursor: pointer;

  &.ThreadEditorActionDelete {
    padding: 5px 10px;
    font-size: 12px;
    color: black;
    opacity: 0.4;
    transition: opacity 0.2s;

    &:hover {
      opacity: 1;
    }
  }
  &.ThreadEditorActionSend {
    padding: 5px 20px;
    background: #323232;
    color: white;
    font-size: 15px;
    margin-left: 5px;
    border-radius: 3px;

    &:hover {
      background-color: #a2f4c2;
      color: black;
    }

    &.ThreadEditorActionSendLoading {
      pointer-events: none;
      background: none;
      background-color: #a2f4c2;
      color: transparent;
      user-select: none;

      &::after {
        animation: spining 500ms infinite linear;
        border: 3px solid black;
        border-radius: 20px;
        border-right-color: transparent;
        border-top-color: transparent;
        content: '';
        display: block;
        height: 19px;
        width: 19px;
        left: 0;
        right: 0;
        margin: auto;
        top: 0;
        bottom: 0;
        position: absolute;
        text-align: center;
      }
    }
  }
}
.ThreadEditorQuoteAttachedWrapper {
  display: block;
  position: relative;
  width: 100%;
}
.ThreadEditorQuoteAttached {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  margin-top: 10px;
  background-color: black;
  border-radius: 0px;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);
  padding: 10px 20px;
  padding-right: 100px;
  cursor: pointer;
}
.ThreadEditorQuoteAttachedLeft {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  text-align: left;
}
.ThreadEditorQuoteAttachedRight {
  position: absolute;
  display: block;
  overflow: hidden;
  text-align: right;
  width: 70px;
  top: 10px;
  right: 20px;
}
.ThreadEditorQuoteAttachedTitle {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include RoobertMedium;
  font-size: 12px;
  line-height: 20px;
  color: white;
  opacity: 0.4;
}
.ThreadEditorQuoteAttachedText {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include FkDisplayRegular;
  font-size: 16px;
  line-height: 20px;
  color: white;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ThreadEditorRecipientsPopoverWrapper {
  left: 0px;
  position: absolute;
  display: block;
  overflow-x: hidden;
  top: 15px;
  max-width: 70%;
  max-height: 80%;
  padding: 10px;
  padding-top: 20px;
}
.ThreadEditorRecipientsPopoverClose {
  z-index: 3;
  position: absolute;
  display: block;
  top: 0px;
  right: 15px;
  overflow: hidden;
  line-height: 20px;
  font-size: 14px;
  @include RoobertSemiBold;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: right 5px center;
  background-image: url('../../images/search-close-black.png');
  background-size: 10px 10px;
  height: 20px;
  cursor: pointer;
  text-align: right;
  padding-left: 5px;
  padding-right: 25px;

  &:hover {
    background-color: rgba(255, 255, 255, 0.4);
  }
}
.ThreadEditorRecipientsPopover {
  display: block;
  overflow: hidden;
  position: relative;
  border-radius: 5px;
  background: white;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  padding: 10px;
  z-index: 4;
}
.ThreadEditorRecipientsOptions {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  margin-bottom: 0px;
}
.ThreadEditorRecipientsOption {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  @include RoobertMedium;
  font-size: 16px;
  line-height: 20px;
  padding: 5px 10px;
  cursor: pointer;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-bottom: 5px;
  color: black;
  background-color: transparent;
  border-radius: 3px;
  transition: background-color 0.2s;

  &:hover {
    background-color: #e3e3e3;
  }
}
.ThreadEditorRecipientsInfo {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  @include RoobertRegular;
  font-size: 12px;
  line-height: 15px;
  color: #aaa;
  padding: 0 10px;

  span {
    text-decoration: underline;
    color: black;
    cursor: pointer;
  }
}

@media only screen and (min-width: 400px) {
  .ThreadEditorTool.ThreadEditorToolExamples {
    display: inline-block;
  }
}
@media only screen and (min-width: 500px) {
  .ThreadEditorFooterUpper {
    padding: 10px 180px 10px 5px;
  }
}
@media only screen and (min-width: 640px) {
  .ThreadEditorFooterUpper {
    padding: 10px 5px 55px 5px;
  }
  .ThreadEditorTool.ThreadEditorToolExamples {
    display: none;
  }
}
@media only screen and (min-width: 750px) {
  .ThreadEditorTop {
    padding-right: 60px;
  }
  .ThreadEditorTopStatus {
    display: block;
  }
}
@media only screen and (min-width: 800px) {
  .ThreadEditorTool.ThreadEditorToolExamples {
    display: inline-block;
  }
}
@media only screen and (min-width: 840px) {
  .ThreadEditorFooterUpper {
    padding: 10px 180px 10px 5px;
  }
}
@media only screen and (min-width: 900px) {
  .ThreadEditorTool span {
    display: inline;
  }
}
@media only screen and (min-width: 1000px) {
  .ThreadEditorRowTitle {
    display: block;
  }
  .ThreadEditorRow {
    padding-left: 80px;
  }
  .ThreadEditorCardWrapper {
    padding-left: 80px;
  }
  .ThreadEditorQuoteAttachedWrapper {
    padding-left: 80px;
  }
  .ThreadEditorRecipientsPopoverWrapper {
    left: 80px;
  }
}
@media only screen and (min-width: 1150px) {
}
