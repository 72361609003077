@import '../Common';

.ReadOnlyEditor {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;

  .DraftEditor-root {
    position: relative;
    display: block;
    overflow: hidden;
    width: 100%;
  }

  .DraftEditor-editorContainer,
  .public-DraftEditor-content {
    // line-height: 20px;

    .link-entity {
      color: #4073ff;
    }
  }
}
