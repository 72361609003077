@import '../Common';

.HumanExperts {
}

.HumanExpertsNewRequest {
  position: relative;
  display: inline-block;
  overflow: hidden;
  vertical-align: top;
  white-space: normal;
  margin-top: 15px;
  width: 350px;
}
.HumanExpertsNewRequestBtn {
  display: block;
  position: relative;
  overflow: hidden;
  width: 180px;
  text-align: center;
  line-height: 20px;
  font-size: 16px;
  @include RoobertSemiBold;
  padding: 15px 0;
  transition: all 0.2s;
  background: black;
  color: white;
  border-radius: 3px;
  cursor: pointer;
  text-decoration: none;
  margin-bottom: 30px;

  &:hover {
    background-color: #363636;
  }
}
.HumanExpertsNewRequestLinksExplain {
  display: block;
  position: relative;
  overflow: hidden;
  width: 100%;
  line-height: 20px;
  font-size: 16px;
  @include RoobertSemiBold;
  color: black;
  margin-bottom: 20px;
}
.HumanExpertsNewRequestLinks {
  display: block;
  position: relative;
  overflow: hidden;
  width: 100%;
}
.HumanExpertsNewRequestLinkList {
  display: inline-block;
  position: relative;
  overflow: hidden;
  width: 250px;
  vertical-align: top;

  &.HumanExpertsNewRequestLinkListSecond {
    width: 100px;
  }
}
.HumanExpertsNewRequestLinksItem {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  margin-bottom: 10px;

  a {
    line-height: 20px;
    font-size: 15px;
    @include ApercuRegular;
    text-align: left;
    color: #000;
    opacity: 0.66;
    text-decoration: none;

    &:hover {
      opacity: 1;
    }
  }
}

.HumanExpertsExpert {
  vertical-align: top;
  width: 200px;
  height: 300px;
  position: relative;
  display: inline-block;
  overflow: hidden;
  white-space: normal;
  margin-right: 10px;
  margin-top: 10px;
  text-decoration: none;
  border-radius: 10px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;

  &.HumanExpertsExpertSearching0 {
    background-image: url('../../images/searching-expert-0.png');
  }
  &.HumanExpertsExpertSearching1 {
    background-image: url('../../images/searching-expert-1.png');
  }
  &.HumanExpertsExpertSearching2 {
    background-image: url('../../images/searching-expert-2.png');
  }
}

.HumanExpertsExpert + .HumanExpertsNewRequest {
  margin-left: 30px;
  margin-top: 40px;
}

.HumanExpertsExpertFooter {
  position: absolute;
  bottom: 10px;
  left: 10px;
  right: 10px;
  display: block;
  overflow: hidden;
}

.HumanExpertsExpertTitle {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 20px;
  font-size: 17px;
  @include RoobertSemiBold;
  text-transform: uppercase;
  color: white;
  letter-spacing: 0.03em;

  span {
    position: relative;
    overflow: hidden;
    display: inline-block;
    vertical-align: inherit;
    width: 15px;
    height: 15px;
    margin-left: 5px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 15px 15px;
    background-image: url('../../images/reload-icon-white.png');
    animation: spining 1500ms infinite linear;
  }
}
.HumanExpertsExpertSubTitle {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 20px;
  font-size: 14px;
  @include ApercuRegular;
  text-transform: uppercase;
  color: white;
  letter-spacing: 0.03em;
  opacity: 0.9;
}

@media only screen and (min-width: 940px) {
  .HumanExpertsExpert {
    width: 220px;
    height: 340px;
  }
  .HumanExpertsExpert + .HumanExpertsNewRequest {
    margin-top: 55px;
  }
}
