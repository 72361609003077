@import '../Common';

.HumanProjectDetail {
}

.ThreadDetailMainBodyFocusStatusTag {
  display: inline-block;
  overflow: hidden;
  position: relative;
  vertical-align: top;
  @include RoobertSemiBold;
  color: white;
  text-transform: uppercase;
  font-size: 11px;
  line-height: 15px;
  padding: 5px 15px;
  border-radius: 20px;
  margin-bottom: 7px;
  letter-spacing: 0.5px;
  background: #39d345;

  &.ThreadDetailMainBodyFocusStatusTag-CREATED,
  &.ThreadDetailMainBodyFocusStatusTag-UPDATED {
    background: #0c81d6;
  }
  &.ThreadDetailMainBodyFocusStatusTag-CANCELED {
    background: #cc7e7e;
  }
  &.ThreadDetailMainBodyFocusStatusTag-COMPLETED {
    background: #f1994e;
  }
}

.ProjectDetailFeatures {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
}
.ProjectDetailFeature {
  display: inline-block;
  vertical-align: top;
  overflow: hidden;
  position: relative;
  margin-top: 7px;
  margin-right: 50px;

  &:last-child {
    margin-right: 0px;
  }
}
.ProjectDetailFeatureTitle {
  display: block;
  overflow: hidden;
  position: relative;
  @include RoobertSemiBold;
  font-size: 13px;
  line-height: 20px;
  color: black;
  opacity: 0.5;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}
.ProjectDetailFeatureValue {
  display: block;
  overflow: hidden;
  position: relative;
  @include FkDisplayRegular;
  font-size: 18px;
  line-height: 25px;
  color: black;
  span {
    vertical-align: top;
    margin-left: 10px;
    background: #c4facc;
    color: #03400c;
    padding: 5px;
    font-size: 14px;
    line-height: 25px;
    height: 25px;
    padding-left: 25px;
    border-radius: 5px;
    background-repeat: no-repeat;
    background-position: center left 5px;
    background-image: url('../../images/price-arrow-up.png');
    background-size: 12px 12px;

    &.ProjectDetailPriceRefund {
      background-color: #a3caff;
      color: #032140;
      background-image: url('../../images/price-arrow-down.png');
    }
  }
}
.ProjectDetailFeature {
  &.ProjectDetailFeatureWithDiscount {
  }
  &.ProjectDetailFeatureDiscounted {
    .ProjectDetailFeatureValue {
      text-decoration: line-through;
    }
  }
}
.ProjectDetailScope {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  margin-top: 30px;
}
.ProjectDetailScopeTitle {
  display: block;
  overflow: hidden;
  position: relative;
  @include RoobertSemiBold;
  font-size: 13px;
  line-height: 20px;
  color: black;
  opacity: 0.5;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}
.ProjectDetailScopeBody {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include ApercuLight;
  font-size: 16px;
  line-height: 20px;
  color: black;
}
.ProjectDetailExpertWrap {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  margin-top: 20px;
}
.ProjectDetailExpert {
  position: relative;
  display: inline-block;
  overflow: hidden;
  text-decoration: none;
  color: black;
  padding: 10px;
  padding-left: 80px;
  min-height: 60px;
  max-width: 100%;
}
.UserBubble.ProjectDetailExpertPic {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  overflow: hidden;
  width: 60px;
  height: 60px;
  font-size: 30px;
  line-height: 60px;
  border-radius: 60px;
}
.ProjectDetailExpertName {
  position: relative;
  display: block;
  overflow: hidden;
  max-width: 100%;
}
.ProjectDetailExpertNameTag {
  position: relative;
  display: block;
  overflow: hidden;
  @include RoobertSemiBold;
  text-transform: uppercase;
  opacity: 0.5;
  color: black;
  font-size: 12px;
  letter-spacing: 0.02em;
  line-height: 15px;
}
.ProjectDetailExpertNameText {
  display: inline-block;
  vertical-align: top;
  overflow: hidden;
  position: relative;
  @include RoobertSemiBold;
  font-size: 18px;
  line-height: 25px;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: black;
  padding-right: 20px;
  background-repeat: no-repeat;
  background-position: center right;
  background-image: url('../../images/view-website-icon.png');
  background-size: 10px 10px;
  max-width: 100%;
}

.ProjectDetailClientFee {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  margin-top: 20px;
  padding: 20px 0 0;
  border-top: 1px solid #8e8e8e;

  &.ProjectDetailClientFeeModal {
    padding-bottom: 20px;
    border-bottom: 1px solid #8e8e8e;
  }
}
.ProjectDetailClientFeeRow {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  opacity: 0.5;
  padding-right: 80px;
}
.ProjectDetailClientFeeRowTitle {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include ApercuLight;
  font-size: 16px;
  line-height: 25px;
  color: black;
}
.ProjectDetailClientFeeRowValue {
  position: absolute;
  display: block;
  overflow: hidden;
  width: 80px;
  top: 0;
  right: 0;
  @include FkDisplayRegular;
  font-size: 17px;
  line-height: 25px;
  color: black;
  text-align: right;
}
.ProjectDetailClientFeeRow.ProjectDetailClientFeeRowTotal {
  opacity: 1;
}
.ProjectDetailClientFeeRow.ProjectDetailClientFeeRowFake {
  opacity: 1;
  .ProjectDetailClientFeeRowTitle {
    color: #0619ac;
  }
  .ProjectDetailClientFeeRowValue {
    color: #0619ac;
  }
}
.ProjectDetailFooter {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  margin-top: 20px;
  padding: 20px 0;
  border-top: 1px solid black;
  text-align: center;
}
.ProjectDetailFooterUpsellPro {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  background: #eaecfc;
  border-radius: 5px;
  border: 2px solid #9aa2dd;
  text-align: left;
  padding: 15px 95px 15px 15px;
  margin-bottom: 20px;
  color: black;
}
.ProjectDetailFooterUpsellProText {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  color: black;
  @include RoobertMedium;
  font-size: 16px;
  line-height: 20px;

  span {
    @include RoobertSemiBold;
  }
}
.ProjectDetailFooterUpsellProBtn {
  display: inline-block;
  vertical-align: middle;
  overflow: hidden;
  position: absolute;
  top: 15px;
  right: 15px;
  color: black;
  @include RoobertSemiBold;
  font-size: 16px;
  line-height: 20px;
  cursor: pointer;
  text-decoration: underline;
  transition: color 0.2s;

  &:hover {
    color: #333;
  }
}
.ProjectDetailFooterText {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  @include RoobertMedium;
  font-size: 13px;
  line-height: 15px;
  color: black;
  opacity: 0.5;
  // max-width: 460px;
  margin: auto;
  margin-bottom: 20px;
}
.ProjectDetailFooterAction {
  overflow: hidden;
  position: relative;
  display: inline-block;
  vertical-align: top;
  background-color: black;
  @include RoobertSemiBold;
  font-size: 17px;
  line-height: 25px;
  color: white;
  text-decoration: none;
  cursor: pointer;
  border-radius: 3px;
  padding: 10px 25px;
  padding-right: 55px;
  margin-bottom: 5px;
  background-repeat: no-repeat;
  background-position: center right 25px;
  background-image: url('../../images/forward-arrow-white.png');
  background-size: 16px 16px;
  transition: background-color 0.2s, color 0.2s;

  &.ProjectDetailFooterActionOther {
    font-size: 14px;
    line-height: 23px;
    background: white;
    color: black;
    padding: 10px 15px;
    border: 1px solid black;
  }

  &.ProjectDetailFooterActionPrimary {
    font-size: 14px;
    background: black;
    margin-right: 5px;
    color: white;
    padding: 10px 15px;
  }

  &:hover {
    background-color: #a2f4c2;
    color: black;
    background-image: url('../../images/view-icon-black.png');

    &.ProjectDetailFooterActionPrimary {
      background-image: none;
    }
    &.ProjectDetailFooterActionOther {
      background-image: none;
    }
  }

  &.ProjectDetailFooterActionLoading {
    pointer-events: none;
    background: none;
    background-color: #a2f4c2;
    color: transparent;
    user-select: none;

    &::after {
      animation: spining 500ms infinite linear;
      border: 3px solid black;
      border-radius: 20px;
      border-right-color: transparent;
      border-top-color: transparent;
      content: '';
      display: block;
      height: 19px;
      width: 19px;
      left: 0;
      right: 0;
      margin: auto;
      top: 13px;
      bottom: 13px;
      position: absolute;
      text-align: center;
    }
  }
}
.ProjectDetailFooterWarning {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  @include RoobertMedium;
  font-size: 13px;
  line-height: 15px;
  color: $red-error;
  max-width: 460px;
  margin: auto;
  margin-bottom: 20px;
}

.ProjectDetailPayment {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  margin-top: 25px;
  margin-bottom: 25px;
}
.ProjectDetailPaymentStep {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  margin-bottom: 35px;

  &:last-child {
    margin-bottom: 15px;
  }
}
.ProjectDetailPaymentStepTitle {
  overflow: hidden;
  position: relative;
  display: inline-block;
  vertical-align: top;
  @include RoobertSemiBold;
  font-size: 18px;
  line-height: 25px;
  color: black;
  padding-right: 25px;
  background-repeat: no-repeat;
  background-position: center right;
  background-image: url('../../images/view-icon-black.png');
  background-size: 15px 15px;
}
.ProjectDetailPaymentMethod {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  border: 1px solid black;
  padding: 15px 10px;
  border-radius: 5px;
  padding-left: 50px;
  padding-right: 75px;
  background-repeat: no-repeat;
  background-position: center left 10px;
  background-image: url('../../images/credit-card-icon.png');
  background-size: 29px 20px;
  margin-top: 5px;

  &.ProjectDetailPaymentMethodSettings {
    margin-top: 15px;
    max-width: 420px;
  }
}
.ProjectDetailPaymentMethodDetail {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  @include FkDisplayRegular;
  font-size: 14px;
  line-height: 20px;
  color: black;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-transform: capitalize;
}
.ProjectDetailPaymentMethodSwitch {
  display: block;
  overflow: hidden;
  position: absolute;
  top: 10px;
  right: 5px;
  @include RoobertMedium;
  font-size: 16px;
  line-height: 20px;
  padding: 5px;
  opacity: 0.5;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
}
.ProjectDetailPaymentCard {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  margin-top: 5px;

  &.ProjectDetailPaymentCardMax {
    max-width: 420px;
  }

  .StripeElement {
    height: 50px;
    padding: 15px 12px;
    background-color: #ffffff;
    border: 1px solid black;
    border-radius: 5px;
    transition: box-shadow 150ms ease;

    .expiry & {
      border-right: none;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    .cvc & {
      border-left: none;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  .StripeElement--focus {
  }

  .StripeElement--invalid {
  }

  .StripeElement--webkit-autofill {
    background-color: #fefde5 !important;
  }
}
.ProjectDetailPaymentCardError {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  width: 100%;
  @include FkDisplayRegular;
  font-size: 14px;
  line-height: 25px;
  color: $red-error;
}
.ProjectDetailPaymentOthers {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  margin-top: 5px;
}
.ProjectDetailPaymentOther {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  max-width: 300px;
}

.ProjectDetailFeedback {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
}
.ProjectDetailFeedbackStars {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}
.ProjectDetailFeedbackStar {
  display: inline-block;
  vertical-align: middle;
  overflow: hidden;
  position: relative;
  width: 25px;
  height: 25px;
  margin-right: 5px;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url('../../images/star-icon-empty.png');
  background-size: 25px 25px;
  cursor: pointer;

  &:hover,
  &.ProjectDetailFeedbackStarSelected {
    background-image: url('../../images/star-icon-full.png');
  }
}
.ProjectDetailFeedbackStarValue {
  display: inline-block;
  vertical-align: middle;
  overflow: hidden;
  position: relative;
  @include RoobertMedium;
  font-size: 15px;
  line-height: 25px;
  color: black;
  opacity: 0.4;
  margin-left: 10px;
}

@media only screen and (min-width: 500px) {
  .ProjectDetailFooterAction {
    &.ProjectDetailFooterActionOther {
      font-size: 15px;
    }
    &.ProjectDetailFooterActionPrimary {
      font-size: 15px;
      margin-right: 10px;
    }
  }
}

@media only screen and (min-width: 1000px) {
  .ProjectDetailFooterAction {
    &.ProjectDetailFooterActionOther {
      font-size: 17px;
    }
    &.ProjectDetailFooterActionPrimary {
      font-size: 17px;
    }
  }
}
