@import '../Common';

.ExpertProfileView {
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
  overflow-x: hidden;
}

.ExpertProfileNav {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  z-index: 3;
}
.ExpertProfileNavHeader {
  position: relative;
  display: block;
  overflow: hidden;
  height: 50px;
  width: 100%;
}
.ExpertProfileNavHeaderBack {
  left: 10px;
  position: absolute;
  display: block;
  overflow: hidden;
  height: 30px;
  top: 10px;
  background-image: url('../../images/left-arrow-black.png');
  background-repeat: no-repeat;
  background-position: left 5px center;
  background-size: 12px 20px;
  transition: background-color 0.2s;
  border-radius: 3px;
  padding-left: 30px;
  padding-right: 5px;
  @include RoobertMedium;
  font-size: 22px;
  line-height: 30px;
  color: black;
  text-decoration: none;
  cursor: pointer;
  max-width: 100%;

  &:hover {
    background-color: rgba(255, 255, 255, 0.4);
  }
}
.ExpertProfileNavIntro {
  position: relative;
  display: none;
  overflow: hidden;
  text-align: left;
  color: black;
  height: 115px;
}
.ExpertProfileNavIntroHeader {
  @include FkDisplayRegular;
  position: relative;
  display: block;
  overflow: hidden;
  font-size: 28px;
  line-height: 30px;
  margin-bottom: 10px;
}
.ExpertProfileNavIntroBody {
  @include RoobertMedium;
  position: relative;
  display: block;
  overflow: hidden;
  font-size: 12px;
  line-height: 15px;
  padding-right: 20px;
}
.ExpertProfileNavLinks {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  text-align: left;
  padding: 0 10px;
}
.ExpertProfileNavLinkPointer {
  visibility: hidden;
  height: 0;
  display: none;
  overflow: hidden;
  position: absolute;
  top: 15px;
  left: 0px;
  border: 1px solid black;
  opacity: 0.5;
  width: 6px;
  border-top: none;
  border-right: none;
}
.ExpertProfileNavLink {
  position: relative;
  display: inline-block;
  overflow: hidden;
  vertical-align: middle;
  margin-right: 5px;
  padding: 5px;
  text-decoration: none;
  @include RoobertSemiBold;
  font-size: 14px;
  line-height: 20px;
  color: black;
  transition: opacity 0.2s;
  opacity: 0.29;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }

  &:last-child {
    margin-right: 0;
  }
}
.ExpertProfileNav.ExpertProfileNavActivePROFILE {
  .ExpertProfileNavLinkPointer {
    visibility: visible;
    height: 36px;
  }
  .ExpertProfileNavLink.ExpertProfileNavLinkPROFILE {
    opacity: 1;
  }
}
.ExpertProfileNav.ExpertProfileNavActiveMETRICS {
  .ExpertProfileNavLinkPointer {
    visibility: visible;
    height: 72px;
  }
  .ExpertProfileNavLink.ExpertProfileNavLinkMETRICS {
    opacity: 1;
  }
}
.ExpertProfileNav.ExpertProfileNavActiveCALENDAR {
  .ExpertProfileNavLinkPointer {
    visibility: visible;
    height: 108px;
  }
  .ExpertProfileNavLink.ExpertProfileNavLinkCALENDAR {
    opacity: 1;
  }
}
.ExpertProfileNav.ExpertProfileNavActiveSERVICES {
  .ExpertProfileNavLinkPointer {
    visibility: visible;
    height: 142px;
  }
  .ExpertProfileNavLink.ExpertProfileNavLinkSERVICES {
    opacity: 1;
  }
}
.ExpertProfileNav.ExpertProfileNavActivePAYMENTS {
  .ExpertProfileNavLinkPointer {
    visibility: visible;
    height: 178px;
  }
  .ExpertProfileNavLink.ExpertProfileNavLinkPAYMENTS {
    opacity: 1;
  }
}
.ExpertProfileNav.ExpertProfileNavActiveREVIEWS {
  .ExpertProfileNavLinkPointer {
    visibility: visible;
    height: 212px;
  }
  .ExpertProfileNavLink.ExpertProfileNavLinkREVIEWS {
    opacity: 1;
  }
}
.ExpertProfileNav.ExpertProfileNavActiveSKILLS {
  .ExpertProfileNavLinkPointer {
    visibility: visible;
    height: 248px;
  }
  .ExpertProfileNavLink.ExpertProfileNavLinkSKILLS {
    opacity: 1;
  }
}
.ExpertProfileNav.ExpertProfileNavActiveTEMPLATES {
  .ExpertProfileNavLinkPointer {
    visibility: visible;
    height: 282px;
  }
  .ExpertProfileNavLink.ExpertProfileNavLinkTEMPLATES {
    opacity: 1;
  }
}
.ExpertProfileMain {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  z-index: 4;
}
.ExpertProfileMainLogoLink {
  position: fixed;
  display: none;
  overflow: hidden;
  top: 15px;
  right: 25px;
}
.ExpertProfileMainLogo {
  position: relative;
  display: block;
  overflow: hidden;
  height: 18px;
}
.ExpertProfileMainHeader {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  min-height: 50px;
}
.ExpertProfileMainHeaderLeft {
  position: relative;
  display: none;
  overflow: hidden;
  width: 100%;
  @include RoobertSemiBold;
  font-size: 20px;
  line-height: 28px;
  padding: 10px 25px;
  padding-right: 190px;
  color: black;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ExpertProfileMainHeaderRight {
  position: absolute;
  display: block;
  overflow: hidden;
  top: 15px;
  right: 25px;
  left: 25px;
  margin: auto;
  height: 18px;
}
.ExpertProfilePanel {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  padding: 10px 15px 11vh;
  max-width: 830px;
}
.Dashboard.PartnerDashboard {
  .ExpertProfilePanel {
    margin: auto;
    padding-top: 7vh;
    padding-left: 15px;
    padding-right: 15px;
    max-width: 870px;
  }
}
.ExpertProfileSection {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  margin-bottom: 10px;

  &.ExpertProfileSectionExtraBottom {
    margin-bottom: 80px;
  }
}
.ExpertProfileSectionTitle {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include RoobertMedium;
  font-size: 24px;
  line-height: 30px;
  color: black;
  margin-bottom: 10px;

  span {
    opacity: 0.35;
    font-size: 18px;
    line-height: 30px;
    margin-left: 5px;
    display: none;
  }

  &.ExpertProfileSectionTitleWithAction {
    padding-right: 110px;
  }
}
.ExpertProfileSectionAction {
  position: absolute;
  display: block;
  overflow: hidden;
  top: 0px;
  right: 0px;
}
.ExpertProfileSectionActionLink {
  text-decoration: none;
  font-size: 14px;
  line-height: 30px;
  display: inline-block;
  overflow: hidden;
  position: relative;
  vertical-align: top;
  @include RoobertMedium;
  color: black;
  background-repeat: no-repeat;
  background-position: right 5px center;
  background-image: url('../../images/view-website-icon.png');
  background-size: 10px 10px;
  background-color: transparent;
  padding-right: 20px;
  padding-left: 5px;
  transition: background-color 0.2s;

  &:hover {
    background-color: rgba(255, 255, 255, 0.4);
  }
}
.ExpertProfileSectionActionInfo {
  font-size: 14px;
  line-height: 30px;
  display: inline-block;
  overflow: hidden;
  position: relative;
  vertical-align: top;
  @include RoobertMedium;
  color: black;
  opacity: 0.5;
}
.ExpertProfileSectionActionBtn {
  display: inline-block;
  position: relative;
  overflow: hidden;
  vertical-align: middle;
  line-height: 20px;
  font-size: 15px;
  @include RoobertSemiBold;
  padding: 5px 20px;
  transition: all 0.2s;
  background: #323232;
  color: white;
  border-radius: 3px;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    background-color: black;
  }

  &.ExpertProfileSectionActionBtnLoading {
    pointer-events: none;
    background: none;
    background-color: #a2f4c2;
    color: transparent;
    user-select: none;

    &::after {
      animation: spining 500ms infinite linear;
      border: 3px solid black;
      border-radius: 20px;
      border-right-color: transparent;
      border-top-color: transparent;
      content: '';
      display: block;
      height: 19px;
      width: 19px;
      left: 0;
      right: 0;
      margin: auto;
      top: 0;
      bottom: 0;
      position: absolute;
      text-align: center;
    }
  }
}
.ExpertProfileSectionActionOther {
  display: inline-block;
  position: relative;
  overflow: hidden;
  vertical-align: middle;
  line-height: 20px;
  font-size: 15px;
  @include RoobertSemiBold;
  padding: 5px 20px;
  transition: all 0.2s;
  color: black;
  cursor: pointer;
  text-decoration: none;
  opacity: 0.5;
  margin-right: 5px;

  &:hover {
    opacity: 1;
  }
}
.ExpertProfileSectionGoogleBtn {
  display: inline-block;
  position: relative;
  overflow: hidden;
  vertical-align: middle;
  height: 46px;
  width: 191px;
  cursor: pointer;
  text-decoration: none;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('../../images/btn_google_signin_dark_normal_web.png');
  background-size: 191px 46px;
  text-indent: -9999px;
}
.ExpertProfileSection.ExpertProfileSectionBelow {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  .ExpertProfileSectionTitle {
    margin-top: 25px;
  }
  .ExpertProfileSectionAction {
    top: 25px;
  }
}
.ExpertProfileInfo {
  position: relative;
  display: block;
  overflow: hidden;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.25);
  width: 100%;
  max-width: 550px;
  text-align: left;
  padding: 25px;
  margin-bottom: 25px;

  &.ExpertProfileInfoFull {
    max-width: unset;
  }
}
.ExpertProfileInfoTitle {
  position: relative;
  display: block;
  overflow: hidden;
  @include RoobertMedium;
  font-size: 20px;
  line-height: 25px;
  color: black;
  margin-bottom: 15px;
}
.ExpertProfileInfoDesc {
  @include RoobertMedium;
  font-size: 14px;
  line-height: 18px;
  color: black;
  opacity: 0.7;
  margin-bottom: 20px;

  a {
    color: black;
  }
}
.ExpertProfileInfoAction {
  display: inline-block;
  position: relative;
  overflow: hidden;
  vertical-align: middle;
  line-height: 20px;
  font-size: 15px;
  @include RoobertSemiBold;
  padding: 5px 20px;
  transition: all 0.2s;
  background: #323232;
  color: white;
  border-radius: 3px;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    background-color: black;
  }
}
.ExpertProfileMockup {
  min-height: 90px;
  padding: 10px;
  position: relative;
  display: inline-block;
  vertical-align: top;
  overflow: hidden;
  max-width: 100%;
  margin-bottom: 20px;
  // border: 1px solid black;
  background: #f7f6ef;
  border-radius: 5px;
}
.ExpertUserBubble.ExpertProfileMockupPhoto {
  display: none;
  position: absolute;
  overflow: hidden;
  top: 10px;
  left: 10px;
  width: 80px;
  height: 80px;
  border-radius: 50px;
  line-height: 80px;
  font-size: 32px;
  border: none;
}
.ExpertProfileMockupName {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include RoobertSemiBold;
  font-size: 22px;
  line-height: 30px;
  color: black;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ExpertProfileMockupDetail {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include RoobertMedium;
  font-size: 15px;
  line-height: 20px;
  color: black;
  white-space: nowrap;
  text-overflow: ellipsis;
  opacity: 0.8;

  &.ExpertProfileMockupDetailEmail {
  }
  &.ExpertProfileMockupDetailPhone {
  }
}
.ExpertProfileSectionMinis {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
}
.ExpertProfileSectionMini {
  position: relative;
  display: inline-block;
  vertical-align: top;
  overflow: hidden;
  width: 100%;
}
.ExpertProfileSectionMiniTitle {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include RoobertMedium;
  font-size: 16px;
  line-height: 20px;
  color: black;
  margin-bottom: 3px;
}
.ExpertProfileSectionMiniBody {
  text-decoration: none;
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include ApercuLight;
  font-size: 16px;
  line-height: 20px;
  color: black;
  margin-bottom: 20px;

  span {
    color: red;
  }
}

.ExpertProfileMetrics {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  text-align: center;
}
.ExpertProfileMetric {
  position: relative;
  overflow: hidden;
  display: inline-block;
  vertical-align: top;
  margin: 15px 0;
  width: 140px;
}

.ExpertProfileMetricNumber {
  position: relative;
  display: block;
  overflow: hidden;
  @include RoobertSemiBold;
  font-size: 32px;
  line-height: 40px;
  color: black;
  width: 100%;
}
.ExpertProfileMetricTitle {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include RoobertSemiBold;
  font-size: 14px;
  line-height: 20px;
  color: black;

  span {
    display: none;
  }
}
.ExpertProfileMetricDescription {
  position: relative;
  display: none;
  overflow: hidden;
  width: 100%;
  @include ApercuLight;
  font-size: 16px;
  line-height: 20px;
  color: black;
  margin-bottom: 20px;
}
.ExpertProfileMetric.ExpertProfileMetricBad {
  .ExpertProfileMetricNumber {
    color: red;
  }
}

.ExpertProfileSettings {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  background: white;
  padding: 15px 10px 40px;
  border-radius: 5px;
  margin-bottom: 25px;
}
.ExpertProfileSettingsField {
  position: relative;
  display: inline-block;
  vertical-align: bottom;
  overflow: hidden;
  width: 100%;
  margin-bottom: 10px;

  &.ExpertProfileSettingsFieldFull {
    display: block;
    vertical-align: unset;
  }
}
.ExpertProfileSettingsFieldTitle {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include RoobertSemiBold;
  font-size: 13px;
  line-height: 25px;
  color: black;
}
.ExpertProfileSettingsFieldHidden {
  position: absolute;
  display: block;
  overflow: hidden;
  top: 3px;
  right: 10px;
  @include RoobertSemiBold;
  font-size: 12px;
  line-height: 15px;
  color: black;
  padding: 2px 10px;
  background-color: #ded6ee;
  border-radius: 5px;
}
.ExpertProfileSettingsFieldInput {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include ApercuRegular;
  font-size: 15px;
  line-height: 20px;
  padding: 7px 5px;
  color: black;
  background: transparent;
  border-radius: 3px;
  outline: none;
  border: 1px solid #eaeaea;

  &.PhoneNumberInput {
    display: flex;
    line-height: 20px;
  }
}
select.ExpertProfileSettingsFieldInput {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-repeat: no-repeat;
  background-position: center right 10px;
  background-image: url('../../images/arrow-down-grey.png');
  background-size: 14px 8px;
}
.ExpertProfileSettingsField.ExpertProfileSettingsFieldInvalid {
  .ExpertProfileSettingsFieldInput {
    color: $red-error;
    border-color: $red-error;
    input {
      color: $red-error;
    }
  }
}
.ExpertProfileSettingsFieldTextarea {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include ApercuRegular;
  font-size: 15px;
  line-height: 20px;
  padding: 7px 5px;
  color: black;
  background: transparent;
  border-radius: 3px;
  outline: none;
  border: 1px solid #eaeaea;
  min-height: 80px;
}
.ExpertProfileSettingsExtra {
  position: absolute;
  bottom: 15px;
  left: 10px;
  display: inline-block;
  vertical-align: top;
  overflow: hidden;
  @include RoobertSemiBold;
  font-size: 13px;
  line-height: 22px;
  cursor: pointer;
  transition: opacity 0.2s;
  opacity: 0.58;
  color: black;
  background-repeat: no-repeat;
  background-position: left center;
  background-image: url('../../images/add-contact-black.png');
  background-size: 15px 15px;
  padding: 5px;
  padding-left: 20px;
  height: 30px;

  &:hover {
    opacity: 1;
  }
}
.ExpertProfileSettingsAction {
  display: inline-block;
  position: absolute;
  bottom: 10px;
  right: 10px;
  overflow: hidden;
  vertical-align: middle;
  line-height: 20px;
  font-size: 15px;
  @include RoobertSemiBold;
  padding: 5px 20px;
  transition: all 0.2s;
  background: black;
  color: white;
  border-radius: 3px;
  cursor: pointer;
  float: right;

  &:hover {
    background-color: #a2f4c2;
    color: black;
  }

  &.ExpertProfileSettingsActionLoading {
    pointer-events: none;
    background: none;
    background-color: #a2f4c2;
    color: transparent;
    user-select: none;

    &::after {
      animation: spining 500ms infinite linear;
      border: 3px solid black;
      border-radius: 20px;
      border-right-color: transparent;
      border-top-color: transparent;
      content: '';
      display: block;
      height: 19px;
      width: 19px;
      left: 0;
      right: 0;
      margin: auto;
      top: 0;
      bottom: 0;
      position: absolute;
      text-align: center;
    }
  }
}

.ExpertProfileServices {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  text-align: center;
  margin-top: 15px;
}
.ExpertProfileService {
  position: relative;
  display: inline-block;
  vertical-align: top;
  overflow: hidden;
  width: 100%;
  max-width: 380px;
  padding: 0;
  margin-bottom: 20px;
}
.ExpertProfileServiceCard {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  background: white;
  border-radius: 5px;
  padding: 15px;
  cursor: pointer;
  height: 140px;
}
.ExpertProfileServiceTitle {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include RoobertMedium;
  font-size: 18px;
  line-height: 22px;
  color: black;
  margin-bottom: 10px;
  max-height: 44px;
  min-height: 44px;
  text-align: left;

  &.ExpertProfileServiceTitleSmall {
    min-height: 22px;
  }
}
.ExpertProfileServiceSubTitle {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include FkDisplayRegular;
  font-size: 14px;
  line-height: 20px;
  color: black;
  margin-bottom: 10px;
  max-height: 20px;
  min-height: 20px;
  text-align: left;
}
.ExpertProfileServiceDays {
  position: absolute;
  display: block;
  overflow: hidden;
  bottom: 10px;
  left: 15px;
  color: black;
  @include RoobertMedium;
  font-size: 14px;
  line-height: 20px;
  opacity: 0.5;
}
.ExpertProfileServicePrice {
  position: absolute;
  display: block;
  overflow: hidden;
  bottom: 10px;
  right: 15px;
  text-align: right;
}
.ExpertProfileServicePriceTop {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include RoobertMedium;
  font-size: 12px;
  line-height: 20px;
  color: black;
}
.ExpertProfileServicePriceBottom {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include FkDisplayRegular;
  font-size: 16px;
  line-height: 20px;
  color: black;
}
.ExpertProfileServiceFooter {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  min-height: 25px;
}
.ExpertProfileServiceFooterLeft {
  position: absolute;
  display: block;
  overflow: hidden;
  top: 0px;
  left: 5px;
  @include RoobertSemiBold;
  font-size: 12px;
  line-height: 25px;
  color: black;
  opacity: 0.5;
  cursor: pointer;
  &:hover {
    opacity: 1;
  }
}
.ExpertProfileServiceFooterLeftNext {
  position: absolute;
  display: block;
  overflow: hidden;
  top: 0px;
  left: 45px;
  @include RoobertSemiBold;
  font-size: 12px;
  line-height: 25px;
  color: black;
  opacity: 0.5;
  cursor: pointer;
  &:hover {
    opacity: 1;
  }
}
.ExpertProfileServiceFooterRight {
  position: absolute;
  display: block;
  overflow: hidden;
  top: 0px;
  right: 5px;
  @include RoobertSemiBold;
  font-size: 12px;
  line-height: 25px;
  color: black;
  opacity: 0.5;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
}

.ExpertProfileServicesAdd {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
}
.ExpertProfileServicesAddBtn {
  display: inline-block;
  position: relative;
  overflow: hidden;
  vertical-align: middle;
  line-height: 20px;
  font-size: 15px;
  @include RoobertSemiBold;
  padding: 5px 20px;
  transition: all 0.2s;
  background: #323232;
  color: white;
  border-radius: 3px;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    background-color: black;
  }
}

.ExpertProfileBalances {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  text-align: center;
}
.ExpertProfileBalance {
  position: relative;
  display: block;
  overflow: hidden;
  position: relative;
  overflow: hidden;
  display: inline-block;
  vertical-align: top;
  margin: 15px 0;
  width: 140px;
}
.ExpertProfileBalanceNumber {
  position: relative;
  display: block;
  overflow: hidden;
  @include RoobertSemiBold;
  font-size: 34px;
  line-height: 40px;
  color: black;
  width: 100%;
}
.ExpertProfileBalanceTitle {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include RoobertSemiBold;
  font-size: 14px;
  line-height: 20px;
  color: black;
}
.ExpertProfileBalanceDescription {
  position: relative;
  display: none;
  overflow: hidden;
  width: 100%;
  @include ApercuLight;
  font-size: 16px;
  line-height: 20px;
  color: black;
  margin-bottom: 20px;
}
.ExpertProfileBalanceBtn {
  display: inline-block;
  position: relative;
  overflow: hidden;
  vertical-align: top;
  line-height: 20px;
  font-size: 15px;
  @include RoobertSemiBold;
  padding: 5px 20px;
  transition: all 0.2s;
  background: #323232;
  color: white;
  border-radius: 3px;
  cursor: pointer;
  margin-top: 5px;

  &:hover {
    background-color: black;
  }
}

.ExpertProfileCalendarOptions {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  background: white;
  border-radius: 5px;
  margin-bottom: 20px;
  padding: 25px;
}
.ExpertProfileCalendarOptionsTitle {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include RoobertSemiBold;
  font-size: 20px;
  line-height: 30px;
  color: black;
}
.ExpertProfileCalendarOptionsSubtitle {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include RoobertMedium;
  font-size: 16px;
  line-height: 20px;
  color: black;
  opacity: 0.5;
  padding-bottom: 10px;
}

.ExpertProfileCalendarOptionItem {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  padding: 10px 15px;
  @include FkDisplayRegular;
  font-size: 18px;
  line-height: 25px;
  color: black;
  cursor: pointer;
  transition: background 0.2s;

  &:nth-child(odd) {
    background: #f3f3f3;
  }

  &:hover {
    background: #e3e3e3;
  }
}

.ExpertProfileTransactions {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  background: white;
  border-radius: 5px;
  margin-bottom: 20px;
}
.ExpertProfileTransaction {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  border-top: 1px solid #eaeaea;
  padding: 15px;
  padding-right: 150px;

  &:first-child {
    border: none;
  }
}
.Dashboard.PartnerDashboard {
  .ExpertProfileTransaction {
    padding-right: 170px;
  }
}
.ExpertProfileTransactionTitle {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include RoobertMedium;
  font-size: 16px;
  line-height: 20px;
  color: black;
  min-height: 20px;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ExpertProfileTransactionSub {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include RoobertMedium;
  font-size: 14px;
  line-height: 20px;
  min-height: 20px;
  opacity: 0.5;
  color: black;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ExpertProfileTransactionAmount {
  position: absolute;
  display: block;
  overflow: hidden;
  top: 15px;
  right: 15px;
  text-align: right;
  @include FkDisplayRegular;
  font-size: 16px;
  line-height: 20px;
  color: black;
}
.ExpertProfileTransactionDate {
  position: absolute;
  display: block;
  overflow: hidden;
  bottom: 15px;
  right: 15px;
  text-align: right;
  @include RoobertMedium;
  font-size: 14px;
  line-height: 20px;
  min-height: 20px;
  opacity: 0.5;
  color: black;

  span {
    display: none;
  }
}
.ExpertProfileTransaction {
  &.ExpertProfileTransactionCredit {
    .ExpertProfileTransactionAmount {
      color: green;
    }
  }
  &.ExpertProfileTransactionDebit {
    .ExpertProfileTransactionAmount {
      color: red;
    }
  }
}
.ExpertProfileTransactionsLoader {
  display: block;
  overflow: hidden;
  position: relative;
  text-align: center;
  width: 100px;
  padding: 10px 0;
  @include RoobertSemiBold;
  font-size: 14px;
  line-height: 20px;
  color: white;
  background: #02071c;
  border-radius: 20px;
  margin: auto;
  cursor: pointer;
  opacity: 0.5;
  transition: all 0.2s;
  margin-bottom: 10px;

  &:hover {
    opacity: 1;
    color: white;
    background: #02071c;
  }
}

.ExpertProfileReviews {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
}
.ExpertProfileReview {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  padding: 15px 0;
  border-top: 1px solid #aaa;
}
.ExpertProfileReviewHeader {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  padding-right: 100px;
  text-decoration: none;
  cursor: pointer;
}
.ExpertProfileReviewTitle {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include RoobertSemiBold;
  font-size: 17px;
  line-height: 20px;
  color: black;
  white-space: nowrap;
  text-decoration: none;
  text-overflow: ellipsis;
}
.ExpertProfileReviewSub {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include RoobertMedium;
  font-size: 14px;
  line-height: 20px;
  color: black;
  margin-bottom: 5px;
  opacity: 0.5;
  white-space: nowrap;
  text-decoration: none;
  text-overflow: ellipsis;
}
.ExpertProfileReviewStars {
  position: absolute;
  display: block;
  overflow: hidden;
  top: 15px;
  right: 0;
}
.ExpertProfileReviewStar {
  position: relative;
  display: inline-block;
  overflow: hidden;
  vertical-align: top;
  width: 15px;
  height: 15px;
  margin-left: 2px;
  background-repeat: none;
  background-position: center center;
  background-image: url('../../images/review-star-black.png');
  background-size: 15px 15px;
  background-color: transparent;
  opacity: 0.2;
}
.ExpertProfileReviewStarActive {
  opacity: 1;
}
.ExpertProfileReviewBody {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include ApercuLight;
  font-size: 15px;
  line-height: 20px;
  color: black;
}
.ExpertProfileReviewsLoader {
  display: block;
  overflow: hidden;
  position: relative;
  text-align: center;
  width: 100px;
  padding: 10px 0;
  @include RoobertSemiBold;
  font-size: 14px;
  line-height: 20px;
  color: white;
  background: #02071c;
  border-radius: 20px;
  margin: auto;
  cursor: pointer;
  opacity: 0.5;
  transition: all 0.2s;
  margin-top: 10px;
  margin-bottom: 10px;

  &:hover {
    opacity: 1;
    color: white;
    background: #02071c;
  }
}

.ExpertProfileTags {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
}
.ExpertProfileTagsTable {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
}

.ExpertProfileTag {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding: 15px 185px 15px 15px;
}
.ExpertProfileTagName {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include RoobertMedium;
  font-size: 17px;
  line-height: 30px;
  color: black;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ExpertProfileTagType {
  position: absolute;
  display: block;
  overflow: hidden;
  top: 10px;
  right: 50px;
  width: 130px;
}
.ExpertProfileTagTypeSelect {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include ApercuRegular;
  font-size: 15px;
  line-height: 20px;
  padding: 10px;
  color: black;
  background: white;
  border-radius: 3px;
  outline: none;
  border: 1px solid #eaeaea;
  height: 40px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-repeat: no-repeat;
  background-position: center right 10px;
  background-image: url('../../images/arrow-down-grey.png');
  background-size: 14px 8px;
}
.ExpertProfileTagRemove {
  position: absolute;
  display: block;
  overflow: hidden;
  top: 15px;
  right: 10px;
  height: 30px;
  width: 30px;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url('../../images/search-close-black.png');
  background-size: 15px 15px;
  cursor: pointer;
  opacity: 0.5;
  transition: all 0.2s;

  &:hover {
    background-color: rgba(255, 255, 255, 0.5);
    opacity: 1;
  }
}

.ExpertProfileModal {
  position: fixed;
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;
  display: block;
  overflow: hidden;
  background: black;
  background: rgba(0, 0, 0, 0.5);
  z-index: 5;
  cursor: pointer;
}
.ExpertProfileModalCover {
  position: absolute;
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;
}
.ExpertProfileModalContainer {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  position: absolute;
  display: block;
  overflow: hidden;
  border-radius: 5px;
  background: #fffcf4;
  box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.11);
  max-width: 600px;
  margin: auto;
  cursor: auto;
}
.ExpertProfileModalContainerBody {
  position: relative;
  display: block;
  overflow-x: hidden;
  width: 100%;
  height: 100%;
  padding: 15px;
  padding-top: 50px;

  &.ExpertProfileModalContainerBodyEditPrefab {
  }
  &.ExpertProfileModalContainerBodyEditTemplate {
  }
}
.ExpertProfileModalContainerTitle {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include RoobertSemiBold;
  font-size: 24px;
  line-height: 30px;
  color: black;
  margin-bottom: 10px;
}
.ExpertProfileModalContainerLink {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include RoobertMedium;
  font-size: 14px;
  line-height: 20px;
  color: black;
  text-decoration: none;
  margin-bottom: 20px;
  opacity: 0.5;
}
.ExpertProfileModalForm {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
}
.ExpertProfileModalContainerBodyEditTemplate {
  .ExpertProfileModalForm {
    padding-bottom: 50px;
  }
}
.ExpertProfileModalFormField {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
}
.ExpertProfileModalFormFieldTitle {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include RoobertSemiBold;
  font-size: 13px;
  line-height: 25px;
  color: black;
}
.ExpertProfileModalFormFieldHidden {
  position: absolute;
  display: block;
  overflow: hidden;
  top: 3px;
  right: 0px;
  @include RoobertSemiBold;
  font-size: 12px;
  line-height: 15px;
  color: black;
  padding: 2px 10px;
  background-color: #ded6ee;
  border-radius: 5px;
}
.ExpertProfileModalFormFieldInput {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  margin-bottom: 15px;
  input {
    position: relative;
    display: block;
    overflow: hidden;
    width: 100%;
    @include ApercuRegular;
    font-size: 15px;
    line-height: 20px;
    padding: 7px 10px;
    color: black;
    background: transparent;
    outline: none;
    border-radius: 3px;
    border: 1px solid #eaeaea;
  }
}
.ExpertProfileModalFormFieldTagger {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  margin-bottom: 15px;

  .MultiSelectContainer {
    background-color: transparent;
    border: 1px solid #eaeaea;
  }
}
.ExpertProfileModalFormOptions {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  margin-bottom: 4px;
}
.ExpertProfileModalFormOption {
  display: inline-block;
  vertical-align: top;
  overflow: hidden;
  position: relative;
  max-width: 140px;
  width: 49%;
}
.ExpertProfileModalFormOptionTitle {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
}
.ExpertProfileModalFormOptionTitleOption {
  display: inline-block;
  vertical-align: top;
  overflow: hidden;
  position: relative;
  opacity: 0.2;
  cursor: pointer;
  transition: opacity 0.2s;
  text-align: left;
  @include RoobertSemiBold;
  font-size: 13px;
  line-height: 20px;
  color: black;

  &.ExpertProfileModalFormOptionTitleOptionActive {
    margin-right: 5px;
    opacity: 1;
  }
  &.ExpertProfileModalFormOptionTitleOptionOther {
    display: none;
  }
  &:hover {
    opacity: 1;
  }
}
.ExpertProfileModalFormOptionField {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
}
.ExpertProfileModalFormOptionFieldGrow {
  display: inline-block;
  overflow: hidden;
  position: relative;
  vertical-align: top;
}
.ExpertProfileModalFormOptionFieldGrowHidden {
  display: inline-block;
  vertical-align: top;
  overflow: hidden;
  position: relative;
  padding: 3px 0px;
  @include FkDisplayRegular;
  font-size: 16px;
  line-height: 20px;
  visibility: hidden;
  min-width: 35px;
  min-height: 26px;
}
.ExpertProfileModalFormOptionFieldGrowInput {
  display: block;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  outline: none;
  border: none;
  @include FkDisplayRegular;
  font-size: 16px;
  background-color: transparent;
  height: 26px;
  border-radius: 5px;
  color: black;
  padding: 3px 0px;
  transition: background-color 0.2s;

  &.ExpertProfileModalFormOptionFieldGrowInputInvalid {
    color: $red-error;
    background-color: #e3e3e3;
  }
  &:focus {
    background-color: #e3e3e3;
  }
}
.ExpertProfileModalFormOptionDays {
  span {
    display: inline-block;
    vertical-align: top;
    overflow: hidden;
    position: relative;
    padding: 3px 0px;
    @include FkDisplayRegular;
    font-size: 16px;
    line-height: 20px;
    margin-left: 5px;
  }
  .ExpertProfileModalFormOptionFieldGrowHidden {
    min-width: 15px;
  }
}
.ExpertProfileModalFormOption:hover {
  .ExpertProfileModalFormOptionTitleOption.ExpertProfileModalFormOptionTitleOptionActive {
    opacity: 1;
  }
  .ExpertProfileModalFormOptionTitleOption.ExpertProfileModalFormOptionTitleOptionOther {
    display: inline-block;
  }
  .ExpertProfileModalFormOptionFieldGrowInput {
    background-color: #e3e3e3;
  }
}
.ExpertProfileModalFormFieldRich {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  color: black;
  background: transparent;
  outline: none;
  border-radius: 3px;
  border: 1px solid #eaeaea;
  margin-bottom: 15px;

  .RichTextEditorWrap .DraftEditor-root {
    min-height: 25vh;
  }
}
.ExpertProfileModalFormAction {
  margin-top: 20px;
  margin-bottom: 15px;
  width: 100%;
  display: block;
  position: relative;
  text-align: center;
  padding: 10px;
  overflow: hidden;
  line-height: 20px;
  font-size: 15px;
  @include RoobertSemiBold;
  transition: all 0.2s;
  background: black;
  color: white;
  border-radius: 3px;
  cursor: pointer;

  &:hover {
    background-color: #a2f4c2;
    color: black;
  }

  &.ExpertProfileModalFormActionLoading {
    pointer-events: none;
    background: none;
    background-color: #a2f4c2;
    color: transparent;
    user-select: none;

    &::after {
      animation: spining 500ms infinite linear;
      border: 3px solid black;
      border-radius: 20px;
      border-right-color: transparent;
      border-top-color: transparent;
      content: '';
      display: block;
      height: 19px;
      width: 19px;
      left: 0;
      right: 0;
      margin: auto;
      top: 0;
      bottom: 0;
      position: absolute;
      text-align: center;
    }
  }
}
.ExpertProfileModalCashout {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
}
.ExpertProfileModalCashoutTitle {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include FkDisplayRegular;
  font-size: 50px;
  line-height: 70px;
  color: black;
  margin-top: 50px;
}
.ExpertProfileModalCashoutWarn {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include RoobertMedium;
  font-size: 16px;
  line-height: 20px;
  color: $red-error;
  margin-bottom: 15px;
}
.ExpertProfileModalCashoutAction {
  margin-bottom: 80px;
  display: inline-block;
  vertical-align: top;
  position: relative;
  text-align: center;
  padding: 10px 25px;
  overflow: hidden;
  line-height: 20px;
  font-size: 15px;
  @include RoobertSemiBold;
  transition: all 0.2s;
  background: black;
  color: white;
  border-radius: 3px;
  cursor: pointer;

  &:hover {
    background-color: #a2f4c2;
    color: black;
  }

  &.ExpertProfileModalCashoutActionLoading {
    pointer-events: none;
    background: none;
    background-color: #a2f4c2;
    color: transparent;
    user-select: none;

    &::after {
      animation: spining 500ms infinite linear;
      border: 3px solid black;
      border-radius: 20px;
      border-right-color: transparent;
      border-top-color: transparent;
      content: '';
      display: block;
      height: 19px;
      width: 19px;
      left: 0;
      right: 0;
      margin: auto;
      top: 0;
      bottom: 0;
      position: absolute;
      text-align: center;
    }
  }
}
.ExpertProfileInfoLoading {
  display: inline-block;
  vertical-align: top;
  position: relative;
  padding: 20px 25px;
  overflow: hidden;
  transition: all 0.2s;
  border-radius: 3px;
  cursor: pointer;
  pointer-events: none;
  user-select: none;

  &::after {
    animation: spining 500ms infinite linear;
    border: 3px solid black;
    border-radius: 30px;
    border-right-color: transparent;
    border-top-color: transparent;
    content: '';
    display: block;
    height: 29px;
    width: 29px;
    left: 0;
    right: 0;
    margin: auto;
    top: 0;
    bottom: 0;
    position: absolute;
    text-align: center;
  }
}
.ExpertProfileModalCashoutSub {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include RoobertMedium;
  color: black;
  font-size: 14px;
  line-height: 20px;
  opacity: 0.5;
}
.ExpertProfileModalCashoutLink {
  display: inline-block;
  vertical-align: top;
  overflow: hidden;
  position: relative;
  @include RoobertMedium;
  font-size: 15px;
  line-height: 20px;
  text-decoration: none;
  opacity: 0.5;
  color: black;
  background-repeat: no-repeat;
  background-position: right 5px center;
  background-image: url('../../images/view-website-icon.png');
  background-size: 10px 10px;
  background-color: transparent;
  padding-right: 25px;
  transition: opacity 0.2s;
  border-bottom: 1px dotted black;

  &:hover {
    opacity: 1;
  }
}
.ExpertProfileModalClose {
  position: absolute;
  display: block;
  overflow: hidden;
  @include RoobertSemiBold;
  font-size: 16px;
  color: black;
  background: #fffcf4;
  top: 10px;
  right: 10px;
  border-radius: 5px;
  padding: 5px 10px;
  line-height: 20px;
  transition: all 0.2s;
  cursor: pointer;
  z-index: 2;
  border: 1px solid black;

  &:hover {
    background: black;
    color: white;
  }
}

.ExpertProfileCalendarAvailabilityWeek {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  background: white;
  border-radius: 5px;
  margin-bottom: 20px;
  padding: 25px;
}
.ExpertProfileCalendarAvailabilityDay {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include RoobertMedium;
  font-size: 16px;
  line-height: 20px;
  color: #aaa;
  padding-bottom: 10px;

  span {
    @include RoobertSemiBold;
    color: black;
  }
}

@media only screen and (min-width: 400px) {
  // .ExpertProfileNavLink {
  //   font-size: 16px;
  // }
  .ExpertProfileSectionTitle span {
    display: unset;
  }
}
@media only screen and (min-width: 475px) {
  .ExpertProfileNavLink {
    margin-right: 15px;
    font-size: 18px;
  }
  .ExpertProfileMetrics {
    text-align: left;
    margin-top: 20px;
  }
  .ExpertProfileMetric {
    display: block;
    vertical-align: unset;
    margin: 0;
    margin-bottom: 15px;
    width: 100%;
    padding-left: 125px;
    max-width: 700px;
  }
  .ExpertProfileMetricNumber {
    position: absolute;
    width: unset;
    top: 0;
    left: 0;
    text-align: center;
    width: 125px;
  }
  .ExpertProfileMetricTitle {
    span {
      display: unset;
    }
  }
  .ExpertProfileMetricDescription {
    display: block;
  }
  .ExpertProfileBalances {
    text-align: left;
    margin-top: 20px;
  }
  .ExpertProfileBalance {
    display: block;
    vertical-align: unset;
    margin: 0;
    margin-bottom: 25px;
    width: 100%;
    padding-left: 130px;
    max-width: 700px;
  }
  .Dashboard.PartnerDashboard {
    .ExpertProfileBalance {
      max-width: 740px;
    }
  }
  .ExpertProfileBalanceNumber {
    position: absolute;
    width: unset;
    top: 0;
    left: 0;
    text-align: center;
    width: 130px;
  }
  .ExpertProfileBalanceDescription {
    display: block;
    margin-bottom: 5px;
  }
  .ExpertProfileBalanceBtn {
    margin-top: 0px;
  }
  .ExpertProfileModalFormAction {
    display: inline-block;
    position: absolute;
    bottom: 15px;
    right: 10px;
    vertical-align: middle;
    padding: 5px 20px;
    margin-top: 0px;
    margin-bottom: 0px;
    width: unset;
  }
}
@media only screen and (min-width: 500px) {
  .ExpertProfileMainHeaderLeft {
    display: block;
  }
  .ExpertProfileMainHeaderRight {
    left: unset;
    margin: 0;
  }
  .ExpertProfileMockup {
    padding: 20px;
    padding-left: 110px;
    min-height: 100px;
  }
  .ExpertUserBubble.ExpertProfileMockupPhoto {
    display: block;
  }
  .ExpertProfileSectionMini {
    padding-right: 60px;
    max-width: 420px;

    &.ExpertProfileSectionMiniSecond {
      max-width: 300px;
      padding-right: 0px;
    }
  }
  .ExpertProfileModalContainer {
    width: 90%;
    top: 5%;
    left: 5%;
    right: 5%;
    height: unset;
    max-height: 90%;
    overflow: unset;
    overflow-x: hidden;
  }
  .ExpertProfileModalContainerBody {
    overflow: hidden;
    height: unset;
  }
  .ExpertProfileTransactionDate {
    span {
      display: unset;
    }
  }
}
@media only screen and (min-width: 600px) {
  .ExpertProfileServices {
    text-align: left;
  }
  .ExpertProfileService {
    width: 50%;
    padding-right: 10px;
    padding-left: 0px;

    &:nth-child(even) {
      padding-left: 10px;
      padding-right: 0px;
    }
  }
  .ExpertProfileSettingsField {
    width: 50%;

    &:nth-child(odd) {
      padding-right: 10px;
    }

    &.ExpertProfileSettingsFieldFull {
      width: 100%;
      padding: 0;
    }
  }
}
@media only screen and (min-width: $base-sidebar-breakpoint) {
  .Dashboard:not(.PartnerDashboard) {
    .ExpertProfileView {
      overflow: hidden;
    }
  }
  .ExpertProfileNav {
    position: absolute;
    width: 250px;
    top: 0px;
    left: 0px;
    height: 100%;
    padding: 15vh 30px 55px;
  }
  .ExpertProfileNavIntro {
    display: block;
  }
  .ExpertProfileNavHeader {
    position: absolute;
    top: 0;
    left: 0;
  }
  .ExpertProfileNavLinks {
    padding: 0;
    padding-top: 35px;
  }
  .ExpertProfileNavLinkPointer {
    display: block;
  }
  .ExpertProfileNavLink {
    display: block;
    margin-bottom: 5px;
    vertical-align: unset;
    width: 100%;
    margin-right: 0;
    padding: 0;
    padding-top: 5px;
    padding-left: 12px;
    @include FkDisplayRegular;
    font-size: 20px;
    line-height: 25px;
  }
  .Dashboard:not(.PartnerDashboard) {
    .ExpertProfileMain {
      position: absolute;
      top: 0px;
      left: 250px;
      right: 0px;
      bottom: 0px;
      width: unset;
      overflow: unset;
      overflow-x: hidden;
    }
  }
  .ExpertProfilePanel {
    padding-top: 11vh;
  }
  .ExpertProfileModalContainerBody {
    padding: 30px;
    padding-bottom: 15px;
  }
  .ExpertProfileService {
    padding-right: 20px;

    &:nth-child(even) {
      padding-left: 20px;
    }
  }
}
@media only screen and (min-width: 1050px) {
  .ExpertProfilePanel {
    padding-left: 20px;
    padding-right: 50px;
  }
}

@media only screen and (min-width: 1200px) {
  .ExpertProfileMainLogoLink {
    display: block;
  }
}
