@import '../Common';

.RichTextEditor {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
}
.RichTextEditorWrap {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  padding: 15px;
  @include ApercuLight;
  font-size: 16px;
  line-height: 20px;
  text-align: left;
  color: black;

  .DraftEditor-root {
    position: relative;
    display: block;
    overflow-x: hidden;
    width: 100%;
    min-height: 20vh;
    max-height: 50vh;
  }

  .DraftEditor-editorContainer,
  .public-DraftEditor-content {
    height: 100%;

    .link-entity {
      color: #4073ff;
    }
  }

  &.RichTextEditorWrapHidePlaceholder .public-DraftEditorPlaceholder-root {
    display: none;
  }
}
.RichTextEditorToolbar {
  position: fixed;
  overflow: hidden;
  display: none;
  z-index: 20;
  width: 215px;
  height: 40px;
  border-radius: 5px;
  background: white;
  box-shadow: 0px 2px 10px rgba(31, 31, 31, 0.24);
  text-align: center;
}
.RichTextEditorToolbarInputWrapper {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  height: 100%;
  padding-right: 50px;
}
.RichTextEditorToolbarUrlFieldInput {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  height: 100%;
  @include ApercuRegular;
  font-size: 12px;
  color: black;
  background: white;
  border: none;
  padding: 0 10px;
  outline: none;

  &.RichTextEditorToolbarUrlFieldInputInvalid {
    color: $red-error;
  }
}
.RichTextEditorToolbarInputCover {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include ApercuRegular;
  font-size: 12px;
  color: black;
  background: white;
  padding: 5px 10px;
  line-height: 30px;
  text-align: left;
}
.RichTextEditorToolbarInputBtn {
  position: absolute;
  display: block;
  overflow: hidden;
  top: 0px;
  right: 0px;
  height: 40px;
  width: 50px;
  padding: 5px 0;
  background: transparent;
  transition: color 0.2s;
  line-height: 30px;
  text-align: center;
  font-size: 12px;
  @include RoobertSemiBold;
  cursor: pointer;
  color: #aaa;
  border-left: 1px solid #e3e3e3;

  &:hover {
    color: black;
  }
}
.RichTextEditorToolbarControl {
  position: relative;
  display: inline-block;
  overflow: hidden;
  height: 30px;
  vertical-align: middle;
  width: 30px;
  cursor: pointer;
  border-radius: 3px;
  margin: 5px;
  margin-right: 0;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 22px 22px;
  transition: all 0.2s;

  &:hover,
  &.RichTextEditorToolbarControlActive {
    background-color: #e3e3e3;
  }

  &.RichTextEditorToolbarControlBold {
    background-image: url('../../images/editor-bold-icon.png');
  }
  &.RichTextEditorToolbarControlItalic {
    background-image: url('../../images/editor-italic-icon.png');
  }
  &.RichTextEditorToolbarControlUnderline {
    background-image: url('../../images/editor-underline-icon.png');
  }
  &.RichTextEditorToolbarControlUnordered {
    background-image: url('../../images/editor-unordered-icon.png');
  }
  &.RichTextEditorToolbarControlOrdered {
    background-image: url('../../images/editor-ordered-icon.png');
  }
  &.RichTextEditorToolbarControlLink {
    background-image: url('../../images/editor-link-icon.png');
  }
}

@media only screen and (min-width: 375px) {
}
@media only screen and (min-width: 500px) {
}
@media only screen and (min-width: 640px) {
}
@media only screen and (min-width: 750px) {
}
@media only screen and (min-width: 1000px) {
}
@media only screen and (min-width: 1150px) {
}
