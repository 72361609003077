@import '../Common';

.LeadsScreen {
}
.LeadsScreenContent {
  padding: 0 10px;
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  max-width: 2000px;
  margin-bottom: 75px;
}
.LeadsScreenEmpty {
  position: relative;
  display: block;
  overflow: hidden;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.25);
  width: 100%;
  max-width: 420px;
  text-align: left;
  padding: 25px;
}
.LeadsScreenEmptyTitle {
  position: relative;
  display: block;
  overflow: hidden;
  @include RoobertMedium;
  font-size: 20px;
  line-height: 25px;
  color: black;
  margin-bottom: 15px;
}
.LeadsScreenEmptyBody {
  @include RoobertMedium;
  font-size: 14px;
  line-height: 18px;
  color: black;
  opacity: 0.7;
  margin-bottom: 20px;
}
.LeadsScreenTiles {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
}
.PotentialLeadRequest {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  text-align: left;
  margin-bottom: 50px;
  transition: opacity 0.2s;
  padding: 0 10px;

  &.PotentialLeadRequestOtherSkip {
    opacity: 0.3;
    pointer-events: none;
  }
}
.PotentialLeadRequestTop {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  padding-right: 65px;
  padding-left: 5px;
  height: 35px;
}
.PotentialLeadRequestTopText {
  @include RoobertSemiBold;
  font-size: 17px;
  line-height: 35px;
  color: black;
  display: block;
  overflow: hidden;
  position: relative;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.PotentialLeadRequestTopWhySkip {
  @include RoobertMedium;
  font-size: 16px;
  line-height: 35px;
  color: black;
  display: block;
  overflow: hidden;
  position: relative;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.PotentialLeadRequestUnreadTag {
  position: absolute;
  top: 25px;
  right: 20px;
  display: inline-block;
  vertical-align: top;
  overflow: hidden;
  line-height: 20px;
  padding: 0 10px;
  @include RoobertSemiBold;
  font-size: 14px;
  letter-spacing: 0.04em;
  color: black;
  background: #94f5f8;
  letter-spacing: 2px;
  z-index: 2;
  border-radius: 2px;

  &.PotentialLeadRequestUnreadTagSuggested {
    background: #c5ffa0;
  }
}
.PotentialLeadRequestBody {
  padding: 10px;
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  border-radius: 0px;
  background: white;
  text-align: left;
  margin-bottom: 5px;
  text-decoration: none;
  cursor: pointer;
  min-height: 50px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
}
.PotentialLeadRequestBodyTitle {
  position: relative;
  display: block;
  overflow: hidden;
  font-size: 16px;
  line-height: 20px;
  @include RoobertSemiBold;
  color: black;
  text-align: left;
  margin-bottom: 10px;
}
.PotentialLeadRequestBodyContent {
  position: relative;
  display: block;
  overflow: hidden;
  @include ApercuRegular;
  width: 100%;
  font-size: 14px;
  line-height: 23px;
  color: #070707;
}
.PotentialLeadRequestBodyPro {
  position: relative;
  display: inline-block;
  vertical-align: top;
  overflow: hidden;
  line-height: 25px;
  padding: 0 15px;
  @include RoobertSemiBold;
  font-size: 13px;
  letter-spacing: 0.04em;
  color: #0619ac;
  z-index: 2;
  border-radius: 6px;
  background: #ccd1fa;
  margin-top: 10px;
}
.PotentialLeadRequestBodyHqApprove {
  position: relative;
  display: inline-block;
  vertical-align: top;
  overflow: hidden;
  line-height: 25px;
  padding: 0 15px;
  @include RoobertSemiBold;
  font-size: 13px;
  letter-spacing: 0.04em;
  color: #193415;
  z-index: 2;
  border-radius: 6px;
  background: #a4efac;
  margin-top: 10px;
}
.PotentialLeadRequestBodyIsAgency {
  position: relative;
  display: inline-block;
  vertical-align: top;
  overflow: hidden;
  line-height: 25px;
  padding: 0 15px;
  @include RoobertSemiBold;
  font-size: 13px;
  letter-spacing: 0.04em;
  color: black;
  z-index: 2;
  border-radius: 6px;
  background: #ffde86;
  margin-top: 10px;
}
.PotentialLeadRequestBodyVIP {
  position: relative;
  display: inline-block;
  vertical-align: top;
  overflow: hidden;
  line-height: 25px;
  padding: 0 15px;
  @include RoobertSemiBold;
  font-size: 13px;
  letter-spacing: 0.04em;
  color: black;
  z-index: 2;
  border-radius: 6px;
  background: #d9cbff;
  margin-top: 10px;
}
.PotentialLeadRequestBodyNotify {
  position: absolute;
  display: block;
  overflow: hidden;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.6);
  color: black;
  z-index: 2;
}
.PotentialLeadRequestBodyNotifyText {
  @include RoobertSemiBold;
  font-size: 15px;
  line-height: 20px;
  bottom: 15px;
  right: 20px;
  position: absolute;
  display: block;
  overflow: hidden;
}
.PotentialLeadRequestBodySkipFlow {
  padding: 10px;
  position: absolute;
  display: block;
  overflow-x: hidden;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.45);
  z-index: 3;
  pointer-events: all;
}
.PotentialLeadRequestBodySkipOption {
  @include RoobertMedium;
  font-size: 14px;
  line-height: 20px;
  padding: 5px 10px;
  display: inline-block;
  vertical-align: top;
  position: relative;
  overflow: hidden;
  color: black;
  border-radius: 20px;
  background-color: white;
  margin-right: 5px;
  margin-bottom: 5px;
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    background-color: black;
    color: white;
  }
}
.PotentialLeadRequestFooter {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  text-align: left;
  height: 20px;
  padding: 0;
}
.PotentialLeadRequestFooterLink {
  text-decoration: none;
  font-size: 14px;
  line-height: 20px;
  display: inline-block;
  overflow: hidden;
  position: relative;
  vertical-align: top;
  @include RoobertMedium;
  color: black;
  background-repeat: no-repeat;
  background-position: right 5px center;
  background-image: url('../../images/view-website-icon.png');
  background-size: 10px 10px;
  background-color: transparent;
  padding-right: 20px;
  padding-left: 5px;
  transition: background-color 0.2s;

  &:hover {
    background-color: rgba(255, 255, 255, 0.4);
  }
}
.PotentialLeadRequestFooterSkip {
  position: absolute;
  top: 0px;
  right: 5px;
  height: 20px;
  font-size: 14px;
  line-height: 20px;
  @include RoobertMedium;
  color: black;
  cursor: pointer;
  padding-left: 5px;
  padding-right: 20px;
  background-repeat: no-repeat;
  background-position: right 5px center;
  background-image: url('../../images/leads-skip-icon.png');
  background-size: 10px 10px;
  background-color: transparent;
  transition: background-color 0.2s;

  &:hover {
    background-color: rgba(255, 255, 255, 0.4);
  }
}
.PotentialLeadRequest.PotentialLeadRequestClaimed {
  .PotentialLeadRequestTop {
    opacity: 0.5;
  }
  .PotentialLeadRequestUnreadTag {
    display: none;
  }
  &:not(.PotentialLeadRequestClaimedByYou) {
    .PotentialLeadRequestBody {
      pointer-events: none;
    }
  }
}
.PotentialLeadRequest.PotentialLeadRequestYourSkip {
  .PotentialLeadRequestUnreadTag {
    display: none;
  }
  .PotentialLeadRequestBody {
    min-height: 150px;
  }
}
@media only screen and (min-width: 500px) {
  .PotentialLeadRequestBody {
    padding: 15px;
  }
}
@media only screen and (min-width: 680px) {
  .LeadsScreenTiles {
    column-count: 2;
    column-gap: 0px;
  }
  .PotentialLeadRequest {
    -webkit-column-break-inside: avoid;
    break-inside: avoid;
    page-break-inside: avoid;
  }
}
@media only screen and (min-width: $base-sidebar-breakpoint) {
  .LeadsScreenContent {
    padding: 0 50px 0 0;
  }
  .LeadsScreenTiles {
    column-count: unset;
    column-gap: unset;
  }
  .PotentialLeadRequest {
    -webkit-column-break-inside: unset;
    break-inside: unset;
    page-break-inside: unset;
  }
  .PotentialLeadRequestBodySkipFlow {
    padding: 15px;
  }
}
@media only screen and (min-width: 950px) {
  .LeadsScreenTiles {
    column-count: 2;
    column-gap: 30px;
  }
  .PotentialLeadRequest {
    -webkit-column-break-inside: avoid;
    break-inside: avoid;
    page-break-inside: avoid;

    &:not(.PotentialLeadRequestYourSkip) {
      .PotentialLeadRequestFooterSkip {
        display: none;
      }
      .PotentialLeadRequestFooterLink {
        display: none;
      }
    }
  }
  .PotentialLeadRequest:hover {
    .PotentialLeadRequestFooterSkip {
      display: block;
    }
    .PotentialLeadRequestFooterLink {
      display: inline-block;
    }
  }
}
@media only screen and (min-width: 1300px) {
  .LeadsScreenTiles {
    column-count: 3;
  }
}
@media only screen and (min-width: 1650px) {
  .LeadsScreenTiles {
    column-count: 4;
  }
}
