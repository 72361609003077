@import '../Common';

.AdminDashboard {
}

.SelectAllText {
  user-select: all;
  white-space: nowrap;
}
.AdminDashboardForm {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  background: white;
  padding: 15px 10px 40px;
  border-radius: 5px;
  max-width: 600px;
  margin-bottom: 50px;
}
.AdminDashboardFormActionLink {
  text-decoration: none;
  font-size: 14px;
  line-height: 30px;
  overflow: hidden;
  position: absolute;
  top: 10px;
  right: 15px;
  @include RoobertMedium;
  color: black;
  background-repeat: no-repeat;
  background-position: right 5px center;
  background-image: url('../../images/view-website-icon.png');
  background-size: 10px 10px;
  background-color: transparent;
  padding-right: 20px;
  padding-left: 5px;
  transition: background-color 0.2s;
  z-index: 2;
  border-radius: 5px;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
}
.AdminDashboardFormTitle {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include RoobertSemiBold;
  font-size: 20px;
  line-height: 25px;
  color: black;
  margin-bottom: 10px;
}
.AdminDashboardFormSubtitle {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include RoobertMedium;
  font-size: 16px;
  line-height: 20px;
  color: black;
  margin-bottom: 10px;
}
.AdminDashboardFormField {
  position: relative;
  display: inline-block;
  vertical-align: bottom;
  overflow: hidden;
  width: 100%;
  margin-bottom: 10px;
}
.AdminDashboardFormFieldTitle {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include RoobertSemiBold;
  font-size: 13px;
  line-height: 20px;
  color: black;
}
.AdminDashboardFormFieldInput {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include ApercuRegular;
  font-size: 15px;
  line-height: 20px;
  padding: 7px 5px;
  color: black;
  background: transparent;
  border-radius: 3px;
  outline: none;
  border: 1px solid #eaeaea;

  &.PhoneNumberInput {
    display: flex;
    line-height: 20px;
  }
}
.AdminDashboardFormFieldDatePicker {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include ApercuRegular;
  font-size: 15px;
  line-height: 18px;
  color: black;
  background: transparent;
  border-radius: 3px;
  outline: none;
  border: 1px solid #eaeaea;
}
.AdminDashboardFormFieldRich {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  color: black;
  background: transparent;
  border-radius: 3px;
  outline: none;
  border: 1px solid #eaeaea;
}
.AdminDashboardFormField.AdminDashboardFormFieldInvalid {
  .AdminDashboardFormFieldInput {
    color: $red-error;
    border-color: $red-error;
    input {
      color: $red-error;
    }
  }
}
.AdminDashboardFormAction {
  display: inline-block;
  position: absolute;
  bottom: 10px;
  right: 10px;
  overflow: hidden;
  vertical-align: middle;
  line-height: 20px;
  font-size: 15px;
  @include RoobertSemiBold;
  padding: 5px 20px;
  transition: all 0.2s;
  background: black;
  color: white;
  border-radius: 3px;
  cursor: pointer;
  float: right;

  &:hover {
    background-color: #a2f4c2;
    color: black;
  }

  &.AdminDashboardFormActionLoading {
    pointer-events: none;
    background: none;
    background-color: #a2f4c2;
    color: transparent;
    user-select: none;

    &::after {
      animation: spining 500ms infinite linear;
      border: 3px solid black;
      border-radius: 20px;
      border-right-color: transparent;
      border-top-color: transparent;
      content: '';
      display: block;
      height: 19px;
      width: 19px;
      left: 0;
      right: 0;
      margin: auto;
      top: 0;
      bottom: 0;
      position: absolute;
      text-align: center;
    }
  }
}

.AdminLinkedRecord {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  background: white;
  padding: 10px 15px 25px;
  border-radius: 5px;
  max-width: 300px;
  text-decoration: none;
  text-align: left;
}
.AdminLinkedRecordTitle {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include RoobertSemiBold;
  font-size: 20px;
  line-height: 25px;
  color: black;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.AdminLinkedRecordSubtitle {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include RoobertMedium;
  font-size: 14px;
  line-height: 15px;
  color: black;
  white-space: nowrap;
  text-overflow: ellipsis;
  opacity: 0.8;

  &.AdminLinkedRecordSubtitleAlert {
    color: red;
  }
}
.AdminLinkedRecordTag {
  text-decoration: none;
  font-size: 14px;
  line-height: 20px;
  display: block;
  overflow: hidden;
  position: absolute;
  bottom: 5px;
  right: 5px;
  @include RoobertMedium;
  color: black;
  background-repeat: no-repeat;
  background-position: right 5px center;
  background-image: url('../../images/view-website-icon.png');
  background-size: 10px 10px;
  padding-right: 20px;
  padding-left: 5px;
}
.AdminLinkedRecordsTitle {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include RoobertSemiBold;
  font-size: 22px;
  line-height: 30px;
  color: black;
}
.AdminLinkedRecords {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  margin-bottom: 20px;
  text-align: center;

  &.AdminLinkedRecordsLeft {
    text-align: left;
    padding: 0 15px;
  }

  .AdminLinkedRecord {
    display: inline-block;
    vertical-align: top;
    margin: 5px;
  }
}

.ThreadDetailSidebarAdminProject {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  background: white;
  padding: 10px 15px;
  border-radius: 5px;
  max-width: 300px;
  text-decoration: none;
  text-align: left;
  margin-bottom: 5px;
}
.ThreadDetailSidebarAdminProjectSection {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  border-top: 1px solid #eee;
  padding: 10px 0;
  min-height: 80px;

  &:first-child {
    border: none;
  }
}
.ThreadDetailSidebarAdminProjectTitle {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include RoobertSemiBold;
  font-size: 20px;
  line-height: 25px;
  color: black;
}
.ThreadDetailSidebarAdminProjectDescription {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include RoobertMedium;
  font-size: 14px;
  line-height: 15px;
  color: black;
  opacity: 0.8;

  &.ThreadDetailSidebarAdminProjectDescriptionAlert {
    color: red;
  }
}
.ThreadDetailSidebarAdminProjectStatusLine {
  position: absolute;
  display: block;
  overflow: hidden;
  height: 3px;
  width: 40px;
  border-radius: 5px;
  bottom: 22px;
  right: 15px;
}
.ThreadDetailSidebarAdminProjectStatus {
  position: absolute;
  display: block;
  overflow: hidden;
  @include FkDisplayRegular;
  bottom: 0px;
  right: 15px;
  font-size: 13px;
  line-height: 20px;
  color: black;
}
.ThreadDetailSidebarAdminProjectPrice {
  @include FkDisplayRegular;
  font-size: 15px;
  line-height: 25px;
  position: relative;
  display: block;
  overflow: hidden;
}
.ThreadDetailSidebarAdminProjectFeedback {
  @include RoobertMedium;
  font-size: 15px;
  line-height: 20px;
  position: relative;
  display: block;
  overflow: hidden;
}
.ThreadDetailSidebarAdminProjectFeedbackHighlight {
  color: red;
}
.ThreadDetailSidebarAdminProjectAction {
  position: relative;
  display: block;
  overflow: hidden;
  width: 85%;
  border: 1px solid black;
  border-radius: 5px;
  text-align: center;
  padding: 10px 0;
  @include RoobertSemiBold;
  font-size: 16px;
  line-height: 20px;
  margin: auto;
  margin-top: 5px;
  background: transparent;
  color: black;
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    background: black;
    color: white;
  }

  &.ThreadDetailSidebarAdminProjectActionBad {
    color: red;
    border-color: red;

    &:hover {
      background: red;
      color: white;
    }
  }
}

.ThreadDetailSidebarAdminProjectLoading {
  position: relative;
  display: block;
  overflow: hidden;
  height: 32px;
  width: 32px;
  animation: spining 500ms infinite linear;
  border: 3px solid black;
  border-radius: 50px;
  border-right-color: transparent;
  border-top-color: transparent;
  content: '';
  left: 0;
  right: 0;
  margin: auto;
  top: 0;
  bottom: 0;
  position: absolute;
  text-align: center;
  pointer-events: none;
  user-select: none;
}

.ThreadDetailSidebarAdminBalanceLog {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  margin-top: 5px;

  .ExpertProfileTransactionTitle {
    white-space: unset;
    text-overflow: unset;
  }
  .ExpertProfileTransactionSub {
    white-space: unset;
    text-overflow: unset;
  }
}

.AdminLinkedRecordsEmpty {
  position: relative;
  display: block;
  overflow: hidden;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.25);
  width: 100%;
  max-width: 420px;
  // text-align: left;
  padding: 25px;
  @include RoobertMedium;
  font-size: 20px;
  line-height: 25px;
  color: black;
  margin-bottom: 15px;
}

.ThreadDetailSidebar {
  .AdminLinkedRecord {
    margin-top: 5px;
    margin-bottom: 10px;

    &.AdminLinkedRecordWithBtn {
      margin-bottom: 0px;
    }
  }
}

.AdminRequestDetailView .RequestDetailViewCard {
  max-width: 700px;
  margin: 0 auto;

  .RequestDetailViewCardBodyContent {
    border: 1px solid #eaeaea;
    width: 98%;
    margin: 0 auto;
    padding: 10px;
    margin-bottom: 10px;
  }
}

.AdminActionBtn {
  display: inline-block;
  position: relative;
  overflow: hidden;
  vertical-align: top;
  line-height: 20px;
  font-size: 15px;
  @include RoobertSemiBold;
  padding: 5px 20px;
  transition: all 0.2s;
  background: #323232;
  color: white;
  border-radius: 3px;
  cursor: pointer;
  margin-top: 5px;
  float: right;

  &:hover {
    background-color: black;
  }

  &.AdminActionBtnLoading {
    pointer-events: none;
    background: none;
    background-color: #a2f4c2;
    color: transparent;
    user-select: none;

    &::after {
      animation: spining 500ms infinite linear;
      border: 3px solid black;
      border-radius: 20px;
      border-right-color: transparent;
      border-top-color: transparent;
      content: '';
      display: block;
      height: 19px;
      width: 19px;
      left: 0;
      right: 0;
      margin: auto;
      top: 0;
      bottom: 0;
      position: absolute;
      text-align: center;
    }
  }

  &.AdminActionBtnDanger {
    background: $red-error;
    margin-right: 5px;
  }
}

.ThreadDetailSidebarSection {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.5);
  padding-top: 10px;
  margin-top: 50px;
}

.ThreadDetailSidebarSnoozePicker {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: block;
  overflow: hidden;
  opacity: 0;
  z-index: 3;
  cursor: pointer;
}

.ThreadDetailSidebarAdminSection {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  margin-bottom: 10px;

  &.ThreadDetailSidebarAdminSectionPaddingTop {
    padding-top: 5px;
  }

  &:last-child {
    border: none;
  }

  &.ThreadDetailSidebarAdminSectionMinHeight {
    min-height: 80px;
  }
}
.ThreadDetailSidebarAdminSectionLoading {
  position: relative;
  display: block;
  overflow: hidden;
  height: 32px;
  width: 32px;
  animation: spining 500ms infinite linear;
  border: 3px solid black;
  border-radius: 50px;
  border-right-color: transparent;
  border-top-color: transparent;
  content: '';
  left: 0;
  right: 0;
  margin: auto;
  top: 0;
  bottom: 0;
  position: absolute;
  text-align: center;
  pointer-events: none;
  user-select: none;
}

.ThreadDetailSidebarAdminSectionTopRightBtn {
  position: absolute;
  top: 0px;
  right: 0px;
  display: inline-block;
  vertical-align: top;
  overflow: hidden;
  @include RoobertSemiBold;
  font-size: 14px;
  line-height: 15px;
  cursor: pointer;
  transition: all 0.2s;
  color: black;
  border: 1px solid black;
  padding: 5px 10px;
  border-radius: 3px;
  z-index: 3;

  &:hover {
    background: black;
    color: white;
  }
}
.ThreadDetailSidebarAdminSectionBig {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include RoobertSemiBold;
  color: black;
  font-size: 18px;
  line-height: 25px;
  margin-bottom: 0px;
}
.ThreadDetailSidebarAdminSectionImage {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  max-width: 120px;
}
.ThreadDetailSidebarAdminSectionDescription {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include RoobertMedium;
  color: black;
  font-size: 14px;
  line-height: 20px;
  opacity: 0.7;
  margin-bottom: 0px;

  &.ThreadDetailSidebarAdminSectionDescriptionBad {
    color: red;
  }
}
.ThreadDetailSidebarAdminSectionParagraph {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include ApercuLight;
  color: black;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 5px;
}
.ThreadDetailSidebarAdminSectionRowBtn {
  position: relative;
  display: block;
  overflow: hidden;
  border-radius: 5px;
  padding: 5px 15px;
  @include RoobertSemiBold;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 10px;
  color: black;
  cursor: pointer;
  transition: all 0.2s;
  float: right;
  opacity: 0.5;

  &.ThreadDetailSidebarAdminSectionRowBtnLeft {
    float: left;
  }

  &:hover {
    opacity: 1;
  }
}
.ThreadDetailSidebarAdminAction {
  position: relative;
  display: block;
  overflow: hidden;
  width: 85%;
  border: 1px solid black;
  border-radius: 5px;
  text-align: center;
  padding: 10px 0;
  @include RoobertSemiBold;
  font-size: 16px;
  line-height: 20px;
  margin: auto;
  margin-top: 10px;
  margin-bottom: 10px;
  background: transparent;
  color: black;
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    background: black;
    color: white;
  }

  &.ThreadDetailSidebarAdminActionLoading {
    pointer-events: none;
    background: none;
    background-color: #a2f4c2;
    color: transparent;
    user-select: none;

    &::after {
      animation: spining 500ms infinite linear;
      border: 3px solid black;
      border-radius: 20px;
      border-right-color: transparent;
      border-top-color: transparent;
      content: '';
      display: block;
      height: 19px;
      width: 19px;
      left: 0;
      right: 0;
      margin: auto;
      top: 0;
      bottom: 0;
      position: absolute;
      text-align: center;
    }
  }

  &.ThreadDetailSidebarAdminActionGOOD {
    color: green;
    border-color: green;

    &:hover {
      background: green;
      color: white;
    }
  }
  &.ThreadDetailSidebarAdminActionBAD {
    color: red;
    border-color: red;

    &:hover {
      background: red;
      color: white;
    }
  }
}
.ThreadDetailSidebarAdminSupportCase {
  position: relative;
  display: block;
  overflow: hidden;
  width: 96%;
  border: 1px solid black;
  padding: 10px;
  margin: 10px 2%;

  .ThreadDetailSidebarAdminAction {
  }
}
.ThreadDetailSidebarAdminSupportCaseEditor {
}
.ThreadDetailSidebarAdminSupportCaseText {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  background: white;
  outline: none;
  border: none;
  @include RoobertMedium;
  font-size: 13px;
  line-height: 15px;
  background: white;
  color: black;
  padding: 15px;
  white-space: pre-wrap;
}
.ThreadDetailSidebarAdminSupportCaseEditorTextarea {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  background: white;
  outline: none;
  border: none;
  @include RoobertMedium;
  font-size: 13px;
  line-height: 15px;
  background: white;
  color: black;
  padding: 15px;
}
.ThreadDetailSidebarAdminSupportCaseEditorFooter {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  text-align: center;
}
.ThreadDetailSidebarAdminSupportCaseEditorFooterBtn {
  position: relative;
  display: inline-block;
  overflow: hidden;
  width: 40%;
  border: 1px solid black;
  border-radius: 5px;
  text-align: center;
  padding: 10px 0;
  @include RoobertSemiBold;
  font-size: 16px;
  line-height: 20px;
  margin: 4%;
  margin-top: 10px;
  margin-bottom: 10px;
  background: transparent;
  color: black;
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    background: black;
    color: white;
  }
}

.ThreadDetailSidebarAdminSectionTitle {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include RoobertMedium;
  color: black;
  font-size: 14px;
  line-height: 20px;
  opacity: 0.5;
  margin-bottom: 0px;
}
.ThreadDetailSidebarAdminSectionRows {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  margin-top: 5px;
}
.ThreadDetailSidebarAdminSectionRow {
  position: relative;
  display: block;
  overflow: hidden;
  width: 98%;
  background: white;
  padding: 10px 10px 20px;
  margin-bottom: 10px;
  text-decoration: none;
  border-radius: 3px;
}

.ThreadDetailSidebarAdminSectionRowText {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include RoobertSemiBold;
  font-size: 16px;
  line-height: 20px;
  color: black;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ThreadDetailSidebarAdminSectionRowReason {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include RoobertMedium;
  font-size: 12px;
  line-height: 15px;
  color: black;
  white-space: nowrap;
  text-overflow: ellipsis;
  opacity: 0.8;

  &.ThreadDetailSidebarAdminSectionRowReasonOverflow {
    white-space: unset;
    text-overflow: unset;
  }
}
.ThreadDetailSidebarAdminSectionRow.ThreadDetailSidebarAdminSectionRowRemove {
  cursor: pointer;
}

.ThreadDetailSidebarAdminSectionRowRemovable {
  display: none;
  position: absolute;
  overflow: hidden;
  top: 10px;
  right: 10px;
  @include RoobertSemiBold;
  width: 30px;
  font-size: 30px;
  line-height: 30px;
  color: red;
}
.ThreadDetailSidebarAdminSectionRow.ThreadDetailSidebarAdminSectionRowRemove:hover {
  cursor: pointer;

  .ThreadDetailSidebarAdminSectionRowReason {
    color: red;
  }
  .ThreadDetailSidebarAdminSectionRowRemovable {
    display: block;
  }
}

.ThreadDetailSidebarAdminSectionRowTag {
  text-decoration: none;
  font-size: 14px;
  line-height: 20px;
  display: block;
  overflow: hidden;
  position: absolute;
  bottom: 0px;
  right: 5px;
  @include RoobertMedium;
  color: black;
  background-repeat: no-repeat;
  background-position: right 5px center;
  background-image: url('../../images/view-website-icon.png');
  background-size: 10px 10px;
  padding-right: 20px;
  padding-left: 5px;
}
.ThreadDetailSidebarAdminSearch {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
}
.ThreadDetailSidebarAdminSearchBar {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  margin-top: 5px;
}
.ThreadDetailSidebarAdminSearchBarInput {
  position: relative;
  display: block;
  overflow: hidden;
  width: 98%;
  @include ApercuRegular;
  font-size: 15px;
  line-height: 20px;
  padding: 7px 5px;
  color: black;
  background: white;
  border-radius: 3px;
  outline: none;
  border: 1px solid rgba(0, 0, 0, 0.5);
}
.ThreadDetailSidebarAdminSearchResults {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  cursor: pointer;
  margin-top: 10px;
}
.ThreadDetailSidebarAdminEmpty {
  position: relative;
  display: block;
  overflow: hidden;
  width: 98%;
  padding: 20px;
  background: rgba(255, 255, 255, 0.4);
  border-radius: 5px;
  @include RoobertMedium;
  font-size: 14px;
  line-height: 20px;
  color: black;
}
.ThreadDetailSidebarAdminSearchResult {
  position: relative;
  display: block;
  overflow: hidden;
  width: 90%;
  background: white;
  padding: 10px;
  margin: auto;
  margin-bottom: 10px;
  text-decoration: none;
  border-radius: 3px;
}
.ThreadDetailSidebarAdminForm {
  .ThreadDetailSidebarAdminSearchResult {
    border: 1px solid black;
  }
}
.ThreadDetailSidebarAdminSearchResultTitle {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include RoobertSemiBold;
  font-size: 16px;
  line-height: 20px;
  color: black;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ThreadDetailSidebarAdminSearchResultSubtitle {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include RoobertMedium;
  font-size: 12px;
  line-height: 15px;
  color: black;
  white-space: nowrap;
  text-overflow: ellipsis;
  opacity: 0.8;
}

.ThreadDetailSidebarUpNextItems {
  margin-bottom: 10px;
}

.AdminPrefabDetailViewDescription {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  margin: 20px auto;
  background: white;
  padding: 10px;
  border-radius: 5px;
  max-width: 600px;
  color: black;
}

.AdminListItemLoader {
  display: block;
  overflow: hidden;
  position: relative;
  text-align: center;
  width: 180px;
  padding: 10px 0;
  @include RoobertSemiBold;
  font-size: 14px;
  line-height: 20px;
  color: black;
  border: 1px solid black;
  // background: white;
  border-radius: 20px;
  margin: auto;
  cursor: pointer;
  opacity: 0.8;
  transition: all 0.2s;

  &:hover {
    opacity: 1;
    color: white;
    background: black;
  }
}

.ThreadDetailSidebarAdminForm {
  position: relative;
  display: block;
  overflow: hidden;
  width: 98%;
  background: white;
  padding: 15px 10px 40px;
  border-radius: 5px;
}
.ThreadDetailSidebarAdminFormTitle {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include RoobertSemiBold;
  font-size: 13px;
  line-height: 20px;
  color: black;
}
.ThreadDetailSidebarAdminFormTitleCounter {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include RoobertSemiBold;
  font-size: 13px;
  line-height: 20px;
  color: black;
  padding-right: 40px;
}
.ThreadDetailSidebarAdminFormTitleCounterAmount {
  position: absolute;
  display: block;
  overflow: hidden;
  top: 0;
  right: 0;
  @include ApercuRegular;
  font-size: 13px;
  line-height: 20px;
  color: black;
  opacity: 0.55;
}
.ThreadDetailSidebarAdminFormInput {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include ApercuRegular;
  font-size: 15px;
  line-height: 20px;
  padding: 7px 5px;
  color: black;
  background: transparent;
  border-radius: 3px;
  outline: none;
  border: 1px solid #eaeaea;
  margin-bottom: 10px;

  &.PhoneNumberInput {
    display: flex;
    line-height: 20px;
  }

  &.ThreadDetailSidebarAdminFormInputInvalid input {
    color: $red-error;
  }

  &.ThreadDetailSidebarAdminFormInputNoBottom {
    margin-bottom: 5px;
  }

  &.ThreadDetailSidebarAdminFormInputMultiple {
    overflow: unset;
    overflow-x: hidden;
  }
}
.ThreadDetailSidebarAdminFormDatePicker {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include ApercuRegular;
  font-size: 15px;
  line-height: 20px;
  color: black;
  background: transparent;
  border-radius: 3px;
  outline: none;
  border: 1px solid #eaeaea;
  margin-bottom: 10px;
}
.ThreadDetailSidebarAdminFormRich {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  color: black;
  background: transparent;
  border-radius: 3px;
  outline: none;
  border: 1px solid #eaeaea;
  margin-bottom: 10px;
}
.ThreadDetailSidebarAdminFormExtra {
  position: relative;
  display: inline-block;
  vertical-align: top;
  overflow: hidden;
  @include RoobertSemiBold;
  font-size: 13px;
  line-height: 22px;
  cursor: pointer;
  transition: opacity 0.2s;
  opacity: 0.58;
  color: black;
  background-repeat: no-repeat;
  background-position: left center;
  background-image: url('../../images/add-contact-black.png');
  background-size: 15px 15px;
  padding: 5px;
  padding-left: 20px;
  height: 30px;
  margin-bottom: 10px;

  &:hover {
    opacity: 1;
  }
}
.ThreadDetailSidebarAdminFormUpload {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  overflow: hidden;
  cursor: pointer;
  padding: 5px 15px;
  line-height: 20px;
  font-size: 14px;
  color: black;
  @include RoobertSemiBold;
  opacity: 0.6;
  transition: all 0.2s;

  &:hover {
    opacity: 1;
  }
}
.ThreadDetailSidebarAdminFormAction {
  display: inline-block;
  position: absolute;
  bottom: 10px;
  right: 10px;
  overflow: hidden;
  vertical-align: middle;
  line-height: 20px;
  font-size: 15px;
  @include RoobertSemiBold;
  padding: 5px 20px;
  transition: all 0.2s;
  background: black;
  color: white;
  border-radius: 3px;
  cursor: pointer;
  float: right;

  &:hover {
    background-color: #a2f4c2;
    color: black;
  }

  &.ThreadDetailSidebarAdminFormActionLoading {
    pointer-events: none;
    background: none;
    background-color: #a2f4c2;
    color: transparent;
    user-select: none;

    &::after {
      animation: spining 500ms infinite linear;
      border: 3px solid black;
      border-radius: 20px;
      border-right-color: transparent;
      border-top-color: transparent;
      content: '';
      display: block;
      height: 19px;
      width: 19px;
      left: 0;
      right: 0;
      margin: auto;
      top: 0;
      bottom: 0;
      position: absolute;
      text-align: center;
    }
  }
}

.AdminDangerZoneEvent {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  background: white;
  padding: 10px 15px;
  border-radius: 5px;
  max-width: 300px;
  text-decoration: none;
  text-align: left;
  margin-bottom: 5px;
}

.AdminDangerZoneEventDescription {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include RoobertMedium;
  font-size: 14px;
  line-height: 15px;
  color: black;
  opacity: 0.8;
}

@media only screen and (min-width: 600px) {
  .AdminDashboardFormField:not(.AdminDashboardFormFieldFull) {
    width: 50%;

    &:nth-child(even) {
      padding-right: 10px;
    }
  }
}
