@import '../Common';

.PhoneNumberInput {
  display: flex;

  .PhoneInputCountrySelect {
    height: 100% !important;
  }
  .PhoneInputCountryIconUnicode {
    font-size: 20px;
  }
  .PhoneInputCountrySelectArrow {
    width: 0.4em;
    height: 0.4em;
    margin-top: -0.2em;
    margin-right: 0.2em;
    margin-left: 0.35em;
    border-style: solid;
    border-color: inherit;
    border-top-width: 0;
    border-bottom-width: 1px;
    border-left-width: 0;
    border-right-width: 1px;
    transform: rotate(45deg);
    opacity: 0.45;
  }
  input {
    position: relative;
    display: block;
    overflow: hidden;
    width: 100%;
    @include ApercuRegular;
    font-size: 15px;
    line-height: 20px;
    color: black;
    background: transparent;
    outline: none;
    border: none;
    padding: 0;
  }
}
