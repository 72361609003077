@import '../Common';

.HumanRequestDetail {
}
.RequestDetailArrowDown {
  width: 100%;
  position: relative;
  display: block;
  overflow: hidden;
  padding: 10px 0;
  margin: 15px 0;
}
.RequestDetailWaitExpectation {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  @include RoobertMedium;
  font-size: 15px;
  line-height: 20px;
  color: black;

  span {
    padding: 0 2px;
    text-decoration: underline dashed 1px green;
  }
}
.RequestDetailWhatNext {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;

  &.RequestDetailWhatNextExtraTop {
    margin-top: 30px;
  }
  &.RequestDetailWhatNextExtraBottom {
    margin-bottom: 30px;
  }
  &.RequestDetailWhatNextSection {
    padding-bottom: 30px;
    border-bottom: 1px solid black;
    margin-bottom: 30px;
  }
}
.RequestDetailWhatNextTitle {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  @include RoobertSemiBold;
  font-size: 20px;
  line-height: 30px;
  color: black;

  &.RequestDetailWhatNextTitleBigBottom {
    margin-bottom: 30px;
  }
  &.RequestDetailWhatNextTitleExtraBottom {
    margin-bottom: 10px;
  }
}
.RequestDetailWhatNextSubTitle {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  @include FkDisplayRegular;
  font-size: 16px;
  line-height: 20px;
  color: black;
  margin-bottom: 30px;
  opacity: 0.7;
}
.RequestDetailWhatNextBox {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  border-radius: 5px;
  background: #fff;
  border: 1px solid #d9d9d9;
  @include RoobertRegular;
  font-size: 17px;
  line-height: 25px;
  color: black;
  padding: 10px;
  margin-top: 5px;
}
.RequestDetailWhatNextBoxLink {
  text-decoration: underline;
  cursor: pointer;
  color: black;
}
.RequestDetailWhatNextBoxItem {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  padding: 15px;

  &.RequestDetailWhatNextBoxItemUpsell {
    border-radius: 5px;
    background: #ebeeff;

    span {
      color: #1419ac;
      text-decoration: underline;
      cursor: pointer;
      @include RoobertSemiBold;
    }
  }
}
.RequestDetailWhatNextBtns {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  margin-top: 30px;
}
.RequestDetailWhatNextBtn {
  display: inline-block;
  vertical-align: top;
  overflow: hidden;
  position: relative;
  margin-right: 10px;
  text-decoration: none;
  @include RoobertSemiBold;
  font-size: 15px;
  line-height: 20px;
  cursor: pointer;
  border-radius: 5px;
  padding: 10px 15px;
  border: 1px solid #a8a8a8;
  margin-bottom: 10px;
  transition: background-color 0.2s, color 0.2s;

  &:hover {
    background-color: #eee;
    // color: white;
  }

  &.RequestDetailWhatNextBtnBold {
    background-color: black;
    color: white;

    &:hover {
      background-color: #a2f4c2;
      color: black;
    }
  }
}
.RequestDetailWaitSteps {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  margin-bottom: 10px;
  margin-top: 35px;
}
.RequestDetailWaitStepsTimeline {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
}
.RequestDetailWaitStepsBall {
  display: inline-block;
  vertical-align: middle;
  overflow: hidden;
  position: relative;
  width: 10px;
  height: 10px;
  margin-right: 5px;
  background: black;
  border-radius: 100%;
}
.RequestDetailWaitStepsLine {
  display: inline-block;
  vertical-align: middle;
  overflow: hidden;
  position: relative;
  width: 25%;
  height: 2px;
  margin-right: 5px;
  background: black;
  max-width: 110px;
}
.RequestDetailWaitStepsFade {
  opacity: 0.4;
}
.RequestDetailWaitStepsText {
  font-size: 13px;
  display: inline-block;
  vertical-align: top;
  overflow: hidden;
  position: relative;
  width: 25%;
  margin-right: 20px;
  @include FkDisplayRegular;
  line-height: 20px;
  max-width: 110px;
  color: black;

  &:last-child {
    margin: 0;
    width: 30%;
  }
}
.RequestDetailUpsell {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  margin-top: 40px;
}
.RequestDetailUpsellTitle {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  @include RoobertSemiBold;
  font-size: 20px;
  line-height: 30px;
  color: black;
  padding: 5px 0;
  margin-bottom: 30px;
}

.RequestDetailUpsellItem {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  margin-bottom: 60px;

  &:last-child {
    margin-bottom: 20px;
  }
}
.RequestDetailUpsellItemTop {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
}
.RequestDetailUpsellItemTopBtn {
  display: inline-block;
  vertical-align: middle;
  background: black;
  @include RoobertSemiBold;
  font-size: 15px;
  line-height: 20px;
  color: white;
  border-radius: 5px;
  padding: 10px 25px;
  transition: all 0.2s;
  margin-right: 15px;
  text-decoration: none;
  margin-bottom: 10px;

  &:hover {
    background: #444;
  }
}
.RequestDetailUpsellItemTopText {
  display: inline-block;
  vertical-align: middle;
  overflow: hidden;
  position: relative;
  min-width: 250px;
  @include RoobertRegular;
  font-size: 14px;
  line-height: 20px;
  color: black;
  margin-bottom: 10px;
}
.RequestDetailUpsellItemRow {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 120px;
  background-repeat: no-repeat;
  background-position: top left;
  background-size: cover;
  margin-top: 10px;

  &.RequestDetailUpsellItemRowDeveloper {
    background-image: url('../../images/upsell-developers.png');
  }
  &.RequestDetailUpsellItemRowDesigner {
    background-image: url('../../images/upsell-designers.png');
  }
  &.RequestDetailUpsellItemRowMarketer {
    background-image: url('../../images/upsell-marketers.png');
  }

  &:hover {
    opacity: 0.9;
  }
}
.RequestDetailUpsellItemLinks {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  margin-top: 15px;
}
.RequestDetailUpsellItemLink {
  position: relative;
  display: inline-block;
  vertical-align: top;
  overflow: hidden;
  margin-right: 20px;
  margin-bottom: 10px;
  line-height: 20px;
  font-size: 15px;
  @include ApercuRegular;
  text-align: left;
  color: #000;
  opacity: 0.46;
  text-decoration: underline;

  &:hover {
    opacity: 1;
  }
}

.RequestDetailContent {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  margin-top: 40px;
  margin-bottom: 60px;
}
.RequestDetailContentHeader {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  padding: 5px 0;
  padding-right: 100px;
}
.RequestDetailContentHeaderTitle {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  @include RoobertSemiBold;
  font-size: 20px;
  line-height: 30px;
  color: black;
}
.RequestDetailContentHeaderEdit {
  display: block;
  overflow: hidden;
  position: absolute;
  bottom: 5px;
  right: 0px;
  @include RoobertSemiBold;
  font-size: 13px;
  line-height: 20px;
  color: black;
  cursor: pointer;
  padding: 5px 10px;
  opacity: 0.5;
  transition: all 0.2s;

  &:hover {
    opacity: 1;
  }
}
.RequestDetailContentBody {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  padding: 20px;
  background: #f8f8f8;
  border: 1px solid #cdcdcd;
  border-radius: 3px;
}
.RequestDetailContentText {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include ApercuLight;
  font-size: 16px;
  line-height: 20px;
  color: black;
}
.RequestDetailContentFiles {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  margin-top: 10px;
}
.RequestDetailContentFileLink {
  position: relative;
  display: inline-block;
  overflow: hidden;
  vertical-align: top;
  max-width: 100%;
  border-radius: 2px;
  color: #535353;
  background-color: #e3e3e3;
  @include RoobertMedium;
  font-size: 12px;
  line-height: 15px;
  padding: 5px 10px;
  margin-right: 5px;
  margin-bottom: 5px;
  text-decoration: none;
  white-space: nowrap;
  text-overflow: ellipsis;
  transition: all 0.2s;

  &:hover {
    background-color: black;
    color: white;
  }
}
.RequestDetailContentExtra {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  border-top: 1px solid #cdcdcd;
  margin-top: 10px;
  padding-top: 10px;
}
.RequestDetailContentExtraTitle {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  @include RoobertSemiBold;
  font-size: 16px;
  line-height: 20px;
  color: black;
  margin-bottom: 5px;
}
.RequestDetailContentExtraValue {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include ApercuLight;
  font-size: 16px;
  line-height: 20px;
  color: black;
}
.RequestDetailMatchWrap {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  margin-top: 20px;
}
.RequestDetailMatch {
  position: relative;
  display: inline-block;
  overflow: hidden;
  text-decoration: none;
  color: black;
  padding: 20px;
  padding-left: 100px;
  min-height: 80px;
  max-width: 100%;
}
.UserBubble.RequestDetailMatchPic {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  overflow: hidden;
  width: 80px;
  height: 80px;
  font-size: 30px;
  line-height: 80px;
  border-radius: 80px;
}
.RequestDetailMatchName {
  position: relative;
  display: block;
  overflow: hidden;
  max-width: 100%;
}
.RequestDetailMatchNameTag {
  position: relative;
  display: block;
  overflow: hidden;
  @include RoobertSemiBold;
  text-transform: uppercase;
  opacity: 0.5;
  color: black;
  font-size: 13px;
  letter-spacing: 0.02em;
  line-height: 15px;
}
.RequestDetailMatchNameText {
  display: inline-block;
  vertical-align: top;
  overflow: hidden;
  position: relative;
  @include RoobertSemiBold;
  font-size: 20px;
  line-height: 25px;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: black;
  padding-right: 20px;
  background-repeat: no-repeat;
  background-position: center right;
  background-image: url('../../images/view-website-icon.png');
  background-size: 10px 10px;
  max-width: 100%;
}
.RequestDetailPrevMatch {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
}
.RequestDetailPrevMatchTitle {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  @include RoobertSemiBold;
  font-size: 16px;
  line-height: 20px;
  color: black;
  margin-top: 60px;
}
.RequestDetailPrevMatchItems {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;

  > :last-child {
    margin-right: 0;
  }
}
.RequestDetailPrevMatchItem {
  position: relative;
  display: inline-block;
  overflow: hidden;
  margin-right: 10px;
  width: 125px;
  text-align: center;
  vertical-align: top;
  white-space: normal;
  cursor: pointer;
  margin-top: 15px;
  text-decoration: none;
}
.UserBubble.RequestDetailPrevMatchItemPic {
  position: relative;
  display: block;
  overflow: hidden;
  margin: auto;
  width: 90px;
  height: 90px;
  font-size: 24px;
  line-height: 90px;
  border-radius: 90px;
}
.RequestDetailPrevMatchItemName {
  display: block;
  position: relative;
  overflow: hidden;
  width: 100%;
  @include RoobertMedium;
  font-size: 16px;
  line-height: 20px;
  color: black;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 5px 0;
}

.HumanBrandSidebarEditorCardPro {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;

  &.HumanBrandSidebarEditorCardProBlue {
    padding: 10px;
    border-radius: 5px;
    background: #ebeeff;

    .MultiSelectContainer {
      background: transparent;
    }
  }
}
.HumanBrandSidebarEditorCardProTitle {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  text-align: left;
  @include RoobertRegular;
  font-size: 14px;
  line-height: 20px;
  color: black;
  padding: 10px 0 15px;

  span {
    color: #1419ac;
    @include RoobertSemiBold;
  }
}
.HumanBrandSidebarEditorCardProCover {
  display: block;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
  z-index: 2;
}

.RequestDetailPhoneCapture {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
}
.RequestDetailPhoneCaptureField {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
}
.RequestDetailPhoneCaptureInput {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  border-radius: 3px;
  outline: none;
  border: 1px solid black;
  padding: 15px;
  width: 100%;
  max-width: 200px;

  &.PhoneNumberInput {
    display: flex;
    line-height: 20px;
  }

  &.RequestDetailPhoneCaptureInputInvalid {
    color: $red-error;
    border-color: $red-error;
    input {
      color: $red-error;
    }
  }
}
.RequestDetailPhoneCaptureBtn {
  overflow: hidden;
  position: absolute;
  display: block;
  top: 10px;
  right: 20px;
  background: white;
  border: 1px solid black;
  @include RoobertSemiBold;
  font-size: 13px;
  line-height: 20px;
  color: black;
  cursor: pointer;
  border-radius: 5px;
  padding: 5px 15px;
  transition: all 0.2s;

  &:hover {
    color: white;
    background: black;
  }
}

@media only screen and (min-width: 375px) {
  .RequestDetailWaitStepsText {
    font-size: 14px;

    &:last-child {
      width: 25%;
      margin-right: 20px;
    }
  }
}
@media only screen and (min-width: 500px) {
}
@media only screen and (min-width: 800px) {
  .RequestDetailWaitSteps {
    padding-left: 10px;
  }
  .RequestDetailArrowDown {
    padding-left: 60px;
    background-repeat: no-repeat;
    background-position: center left;
    background-image: url('../../images/big-arrow-down.png');
    background-size: 32px 75px;
    min-height: 80px;
    width: 85%;
  }
  .RequestDetailUpsellItemRow {
    height: 160px;
  }
}
@media only screen and (min-width: 1100px) {
  .RequestDetailWaitSteps {
    padding-left: 25px;
  }
  .RequestDetailArrowDown {
    width: 75%;
    padding-left: 80px;
    background-position: center left 15px;
    margin: 25px 0;
  }
}
