@import '../Common';

.OrphanClientEditor {
}
.OrphanClientEditor .ThreadEditorCard {
  border-radius: 5px;
}
.OrphanClientEditor .ThreadEditorCardWrapper {
  padding-left: 0px;
}

.ThreadEditorOrphan {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  padding: 15px 10px;
  min-height: 100px;
}
.ThreadEditorOrphanLoading {
  position: relative;
  display: block;
  overflow: hidden;
  height: 32px;
  width: 32px;
  animation: spining 500ms infinite linear;
  border: 3px solid black;
  border-radius: 50px;
  border-right-color: transparent;
  border-top-color: transparent;
  content: '';
  left: 0;
  right: 0;
  margin: auto;
  top: 0;
  bottom: 0;
  position: absolute;
  text-align: center;
  pointer-events: none;
  user-select: none;
}
.ThreadEditorOrphanForm {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  padding: 0 5px;
}
.ThreadEditorOrphanFormField {
  position: relative;
  display: block;
  overflow: hidden;
  // float: left;
  width: 100%;
  margin-bottom: 10px;
}
.ThreadEditorOrphanFormFieldTitle {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include RoobertSemiBold;
  font-size: 13px;
  line-height: 20px;
  color: black;
}
.ThreadEditorOrphanFormFieldInput {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include ApercuRegular;
  font-size: 15px;
  line-height: 20px;
  padding: 7px 5px;
  color: black;
  background: transparent;
  border-radius: 3px;
  outline: none;
  border: 1px solid #eaeaea;
}
.ThreadEditorOrphanFormAction {
  display: block;
  position: relative;
  overflow: hidden;
  width: 100%;
  line-height: 20px;
  font-size: 16px;
  @include RoobertSemiBold;
  padding: 10px 0;
  transition: all 0.2s;
  background: #323232;
  color: white;
  text-align: center;
  border-radius: 3px;
  cursor: pointer;
  margin: auto;
  margin-top: 15px;
  max-width: 250px;

  &:hover {
    background-color: black;
  }
}
.ThreadEditorOrphanSection {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
    border-top: 1px solid #eee;
    padding-top: 10px;
  }

  &:first-child {
    border: none;
  }
}
.ThreadEditorOrphanSectionTitle {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include RoobertSemiBold;
  color: black;
  font-size: 13px;
  line-height: 20px;
  padding: 0 5px;
}
.ThreadEditorOrphanSectionSearch {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  padding: 0 5px;
}
.ThreadEditorOrphanSectionSearchInput {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include ApercuRegular;
  font-size: 15px;
  line-height: 20px;
  padding: 7px 5px;
  color: black;
  background: transparent;
  border-radius: 3px;
  outline: none;
  border: 1px solid #eaeaea;
}
.ThreadEditorOrphanSectionResults {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  margin-top: 10px;
}
.ThreadEditorOrphanSectionResultsItem {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  padding: 10px 5px;
  margin-bottom: 5px;
  cursor: pointer;
  border-radius: 3px;
}
.ThreadEditorOrphanSectionResultsItemTitle {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include RoobertSemiBold;
  color: black;
  font-size: 16px;
  line-height: 20px;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ThreadEditorOrphanSectionResultsItemSub {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include RoobertMedium;
  color: black;
  font-size: 13px;
  line-height: 15px;
  opacity: 0.7;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ThreadEditorOrphanSectionResultsItem:hover {
  background: black;

  .ThreadEditorOrphanSectionResultsItemTitle {
    color: white;
  }
  .ThreadEditorOrphanSectionResultsItemSub {
    color: white;
  }
}
.ThreadEditorOrphanSectionResultsEmpty {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  @include RoobertMedium;
  color: black;
  font-size: 14px;
  line-height: 20px;
  opacity: 0.7;
  padding: 5px;
}
.ThreadEditorOrphanSectionAction {
  display: inline-block;
  position: relative;
  overflow: hidden;
  vertical-align: middle;
  line-height: 20px;
  font-size: 13px;
  @include RoobertSemiBold;
  padding: 5px 10px;
  transition: all 0.2s;
  background: #323232;
  color: white;
  margin-top: 3px;
  border-radius: 3px;
  cursor: pointer;

  &:hover {
    background-color: black;
  }
}
